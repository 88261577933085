import React from 'react';
import { RouteObject } from 'react-router-dom';

import { AuthenticatedSection } from 'components/AuthenticatedSection';
import { getBatteryTestResultBreadcrumb, getDeviceBreadcrumb } from 'lib/breadcrumb';
import { isFeatureEnabled } from 'lib/featureFlag';
import { RouteExtraData } from 'lib/route-helpers';

import { TaskManagement } from './TaskManagement';
import { loadBatteryTasksQuery } from './battery-health/overview/BatteryHealthTaskOverviewContent';
import { BatteryHealthTaskList } from './battery-health/task-list/BatteryHealthTaskList';
import { ViewBatteryHealthTask, getTaskViewBreadcrumb } from './battery-health/task-view';
import { loadCompareBatteryTestResultsPageData } from './battery-health/test-compare/CompareBatteryTestResultsContent';
import { TestResultList } from './battery-health/test-result-list/TestResultList';
import { ViewBatteryTestResultByTask } from './battery-health/test-result-view/ViewBatteryTestResultByTask';
import { ViewBatteryTestResultDirect } from './battery-health/test-result-view/ViewBatteryTestResultDirect';
import { loadGeneratorRunTasksQuery } from './generator-run/overview/GeneratorRunTaskOverviewContent';
import { loadGeneratorRunReportPageData } from './generator-run/run-report/GeneratorRunReportContent';

const Routes: RouteObject[] = [
    {
        path: 'tasks',
        element: <AuthenticatedSection tasksRead />,
        handle: {
            title: 'Tasks',
        },
        children: [
            {
                index: true,
                element: <TaskManagement />,
            },
            {
                path: 'batteryhealth',
                handle: {
                    title: 'Battery Health Tests',
                },
                children: [
                    {
                        index: true,
                        loader: () => loadBatteryTasksQuery(),
                        lazy: async () => {
                            const { BatteryHealthTaskOverview } = await import(
                                './battery-health/overview/BatteryHealthTaskOverview'
                            );
                            return { Component: BatteryHealthTaskOverview };
                        },
                        handle: {
                            title: 'Battery Health Tests',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'schedule',
                        element: <AuthenticatedSection tasksWrite />,
                        handle: {
                            title: 'Schedule Battery Health Test',
                        },
                        children: [
                            {
                                index: true,
                                lazy: async () => {
                                    const { Schedule } = await import('./battery-health/schedule/Schedule');
                                    return { Component: Schedule };
                                },
                            },
                        ],
                    },
                    {
                        path: 'cancelled',
                        element: <BatteryHealthTaskList pageType='cancelled' />,
                        handle: {
                            title: 'Cancelled Tests',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'completed',
                        element: <BatteryHealthTaskList pageType='completed' />,
                        handle: {
                            title: 'Completed Tests',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'scheduled',
                        element: <BatteryHealthTaskList pageType='scheduled' />,
                        handle: {
                            title: 'Scheduled Tests',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'external',
                        element: <TestResultList />,
                        handle: {
                            title: 'External Discharges',
                            fullWidth: true,
                        } as RouteExtraData,
                        children: [],
                    },
                    {
                        path: 'external/:id',
                        element: <ViewBatteryTestResultDirect />,
                        handle: {
                            // FIXME: Better to live under "External Discharges" item
                            title: params => getBatteryTestResultBreadcrumb(params.id!),
                        } as RouteExtraData,
                    },
                    {
                        path: 'view/:taskId',
                        handle: {
                            title: params => getTaskViewBreadcrumb(params.taskId!),
                        } as RouteExtraData,
                        children: [
                            {
                                index: true,
                                element: <ViewBatteryHealthTask />,
                            },
                            {
                                path: ':deviceId',
                                element: <ViewBatteryTestResultByTask />,
                                handle: {
                                    title: params => getDeviceBreadcrumb(params.deviceId!),
                                } as RouteExtraData,
                            },
                        ],
                    },
                    {
                        path: 'compare',
                        loader: ({ request }) => loadCompareBatteryTestResultsPageData(new URL(request.url)),
                        lazy: async () => {
                            const { CompareBatteryTestResults } = await import(
                                './battery-health/test-compare/CompareBatteryTestResults'
                            );
                            return { Component: CompareBatteryTestResults };
                        },
                        handle: {
                            title: 'Compare Tests',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                ],
            },
        ],
    },
];

if (isFeatureEnabled('generator_run')) {
    Routes[0].children?.push({
        path: 'generators',
        handle: {
            title: 'Generator Runs',
        },
        children: [
            {
                index: true,
                loader: () => loadGeneratorRunTasksQuery(),
                lazy: async () => {
                    const { GeneratorRunTaskOverview } = await import(
                        './generator-run/overview/GeneratorRunTaskOverview'
                    );
                    return { Component: GeneratorRunTaskOverview };
                },
                handle: {
                    title: 'Generator Runs',
                    fullWidth: true,
                } as RouteExtraData,
            },
            {
                path: 'all',
                lazy: async () => {
                    const { GeneratorRunList } = await import('./generator-run/report-list/GeneratorRunList');
                    return { Component: GeneratorRunList };
                },
                handle: {
                    title: 'All Reports',
                    fullWidth: true,
                } as RouteExtraData,
            },
            {
                path: 'view/:id',
                loader: ({ params }) => loadGeneratorRunReportPageData(params.id!),
                lazy: async () => {
                    const { GeneratorRunReport } = await import('./generator-run/run-report/GeneratorRunReport');
                    return { Component: GeneratorRunReport };
                },
                handle: {
                    title: 'Generator Run Report',
                    fullWidth: false,
                } as RouteExtraData,
            },
        ],
    });
}

export default Routes;
