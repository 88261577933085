import React, { FC, ReactNode, useCallback } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import graphql from 'babel-plugin-relay/macro';
import { UserTableColumn, useUserFilter } from 'filters/user';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { SearchResultWithGroups } from 'layouts/TableLayout/components/Search';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';

import { ManageUsersSearchQuery, ManageUsersSearchQuery$data } from './__generated__/ManageUsersSearchQuery.graphql';
import {
    ManageUsersTableQuery,
    ManageUsersTableQuery$data,
    ManageUsersTableQuery$variables,
    UserOrdering,
    UserSortField,
} from './__generated__/ManageUsersTableQuery.graphql';
import { AllTableColumns, DefaultTableColumns } from './settings';

type User = ManageUsersTableQuery$data['users']['data'][number];
type UserSearchResult = ManageUsersSearchQuery$data['users']['data'][number];

export const ManageUsers: FC = () => {
    const environment = useRelayEnvironment();
    const [tableState, dispatchTableState] = useTableReducer<UserTableColumn>({
        defaultSortColumn: UserTableColumn.Name,
        allColumns: AllTableColumns,
        defaultVisibleColumns: DefaultTableColumns.map(column => column.id),
        storageKeyPrefix: 'manage-users-table',
    });

    const [filters, dispatchFilters] = useUserFilter();

    const sortObject: Partial<UserOrdering> = {
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    switch (tableState.sortColumn) {
        case UserTableColumn.Email:
        case UserTableColumn.Name:
            sortObject.field = tableState.sortColumn as UserSortField;
            break;
        default:
            sortObject.field = 'Name';
            break;
    }

    const variables: ManageUsersTableQuery$variables = {
        page: tableState.page,
        search: tableState.search,
        orderBy: sortObject as UserOrdering,
    };

    const { data, error, retry, isFetching } = useQuery<ManageUsersTableQuery>(
        graphql`
            query ManageUsersTableQuery($page: Int = 1, $search: String = "", $orderBy: UserOrdering) {
                users(page: $page, search: $search, orderBy: $orderBy) {
                    total
                    data {
                        username
                        status
                        name
                        email
                        roles
                        type
                        createdAt
                        updatedAt
                        enabled
                        lastLogin
                    }
                    pageInfo {
                        page
                        size
                        total
                        hasNext
                        hasPrevious
                    }
                }
                overallUsers: users {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const handleSearch = useCallback(
        (input: string): Promise<SearchResultWithGroups<UserSearchResult>> => {
            return fetchQuery<ManageUsersSearchQuery>(
                environment,
                graphql`
                    query ManageUsersSearchQuery($search: String = "", $pageSize: Int!) {
                        users(search: $search, pageSize: $pageSize) {
                            data {
                                username
                                name
                                email
                            }
                        }
                    }
                `,
                { search: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => {
                    const results = (result?.users.data as UserSearchResult[]) ?? [];
                    return { results, groups: [] };
                });
        },
        [environment]
    );

    return (
        <>
            <TableLayout
                title='User Management'
                columns={AllTableColumns}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={data?.users.data ?? null}
                getRowId={row => row.username}
                isProcessing={!!data && isFetching}
                page={data?.users.pageInfo.page}
                pageCount={data?.users.pageInfo.total}
                overallCount={data?.overallUsers.total}
                resultCount={data?.users.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by name or email'
                onSearch={handleSearch}
                renderSearchResult={renderSearchResult}
                renderSearchResultAsString={(item: UserSearchResult) => item.name ?? 'Unknown'}
                emptyMessage='There are no users present'
                unit='User'
                getItemLink={getUserLink}
                primaryAction='Add new user'
                primaryActionLink={Paths.SettingsUsersAdd}
            />
        </>
    );
};

function getUserLink(user: User): string {
    return generatePath(Paths.SettingsUsersEdit, { id: user.username });
}

function renderSearchResult(result: UserSearchResult): ReactNode {
    return (
        <div className='flex flex-row items-baseline space-x-2'>
            <span>{result.name}</span>
            <span className='font-light text-xs'>{result.email}</span>
        </div>
    );
}
