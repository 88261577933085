import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFragment } from 'react-relay';

import { BarChart, CartesianChartDataType, Theme, ThemedBarSeriesType, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterValueMap } from 'filters/common/types';
import { IncidentExtraFilterColumnMap, StaticIncidentFilterDefinitions } from 'filters/incidents/settings';
import { IncidentExtraFilters, IncidentTableColumn } from 'filters/incidents/types';
import { Paths } from 'lib/routes';
import { encodeFilterParameters } from 'lib/table-filter';
import { DateTime } from 'luxon';

import { intervalToHuman } from '../../common';
import { TitleWithSubtitle } from '../../components';
import { TimeRange } from '../../settings';
import { IncidentCountsChart$data, IncidentCountsChart$key } from './__generated__/IncidentCountsChart.graphql';

interface IncidentCountsChartProps {
    fragmentRef: IncidentCountsChart$key;
    interval: TimeRange;
}

export const IncidentCountsChart: FC<IncidentCountsChartProps> = ({ fragmentRef, interval }) => {
    const data = useFragment(Fragment, fragmentRef);
    const navigate = useExtendedNavigate();

    const chartData: IncidentCountsChart$data['siteAcPowerIncidentDistributionOverTime'] = useMemo(
        () =>
            data.siteAcPowerIncidentDistributionOverTime
                ? data.siteAcPowerIncidentDistributionOverTime.map(period => ({
                      begin: DateTime.fromISO(period.begin).toISO(),
                      end: DateTime.fromISO(period.end).toISO(),
                      distribution: [
                          {
                              status: 'Outage',
                              count:
                                  period.distribution.find(distributionEntry => distributionEntry?.status === 'Outage')
                                      ?.count ?? 0,
                          },
                          {
                              status: 'OverVoltage',
                              count:
                                  period.distribution.find(
                                      distributionEntry => distributionEntry?.status === 'OverVoltage'
                                  )?.count ?? 0,
                          },
                          {
                              status: 'UnderVoltage',
                              count:
                                  period.distribution.find(
                                      distributionEntry => distributionEntry?.status === 'UnderVoltage'
                                  )?.count ?? 0,
                          },
                      ],
                  }))
                : [],
        [data.siteAcPowerIncidentDistributionOverTime]
    );

    let alertType: 'empty-good' | undefined;
    let alertMessage;
    let alertTitle;

    if (data.siteAcPowerIncidentDistributionOverTime?.length === 0) {
        alertType = 'empty-good';
        alertMessage = '';
        alertTitle = 'No incidents found';
    }

    const [series, setSeries] = useState(convertChartData(chartData, interval));
    const lookupData = useMemo(() => getLookupData(chartData, interval), [chartData, interval]);

    useEffect(() => {
        setSeries(convertChartData(chartData, interval));
    }, [chartData, interval]);

    const onLegendClickHandler = (name: string) => {
        setSeries(prevSeries => {
            const newSeries = prevSeries.map(series => {
                if (series.name === name) {
                    return {
                        ...series,
                        hidden: !series.hidden,
                    };
                }

                return series;
            });

            return newSeries;
        });
    };

    return (
        <div className='col-span-12 lg:col-span-8'>
            <TitleWithSubtitle
                title='Incident counts'
                subtitle={intervalToHuman(interval as TimeRange, {
                    lifetimeOverride: 'for all time',
                    prefix: 'for the last ',
                })}
            />
            <BarChart
                series={series}
                aspectRatio={2.2}
                formatTooltipValue={(value, _data, _unit, name) => `${value} ${name}`}
                formatTooltipLabel={(_label, data) =>
                    `${DateTime.fromISO(lookupData[data.key].begin).toFormat('dd-MMM-yy')} - ${DateTime.fromISO(
                        lookupData[data.key].end
                    ).toFormat('dd-MMM-yy')}`
                }
                alertType={alertType}
                alertMessage={alertMessage}
                alertTitle={alertTitle}
                legend
                legendSeparate
                legendOnClick={onLegendClickHandler}
                showLegendInTooltip={false}
                allStacked
                onBarClick={payload => {
                    navigate({
                        pathname: Paths.ReportACPowerIncidentList,
                        search: {
                            ...encodeFilterParameters<
                                IncidentTableColumn,
                                FilterValueMap<IncidentExtraFilterColumnMap>
                            >(
                                {
                                    [IncidentExtraFilters.ActiveWithin]: {
                                        type: 'custom',
                                        range: [
                                            DateTime.fromISO(lookupData[payload.key].begin).toJSDate(),
                                            DateTime.fromISO(lookupData[payload.key].end).toJSDate(),
                                        ],
                                    },
                                },
                                StaticIncidentFilterDefinitions
                            ),
                        },
                    });
                }}
            />
        </div>
    );
};

const Fragment = graphql`
    fragment IncidentCountsChart on Query {
        siteAcPowerIncidentDistributionOverTime(begin: $incidentBegin, end: $end, interval: $interval) {
            begin
            end
            distribution {
                status
                count
            }
        }
    }
`;

function getTimeRangeFormat(timeRange: TimeRange) {
    if (timeRange === TimeRange.LastMonth || timeRange === TimeRange.LastQuarter) {
        return 'dd-MMM';
    } else if (timeRange === TimeRange.LastYear || timeRange === TimeRange.Lifetime) {
        return 'MMM-yy';
    }

    return 'MMM';
}

function getLookupData(
    data: IncidentCountsChart$data['siteAcPowerIncidentDistributionOverTime'],
    timeRange: TimeRange
) {
    const dataOrEmpty = data ?? [];

    const toFormat = getTimeRangeFormat(timeRange);

    return dataOrEmpty.reduce(
        (dict, el) => {
            dict[DateTime.fromISO(el.begin).toFormat(toFormat)] = { begin: el.begin, end: el.end };
            return dict;
        },
        {} as Record<string, { begin: string; end: string }>
    );
}

function convertChartData(
    data: IncidentCountsChart$data['siteAcPowerIncidentDistributionOverTime'],
    timeRange: TimeRange
): ThemedBarSeriesType<string>[] {
    const dataOrEmpty = data ?? [];

    const toFormat = getTimeRangeFormat(timeRange);

    // Reformat to per-status data
    const underVoltageData = dataOrEmpty.map<CartesianChartDataType<string>>(item => ({
        key: DateTime.fromISO(item!.begin).toFormat(toFormat),
        value: item!.distribution.find(distributionEntry => distributionEntry?.status === 'UnderVoltage')?.count ?? 0,
    }));

    const overVoltageData = dataOrEmpty.map<CartesianChartDataType<string>>(item => ({
        key: DateTime.fromISO(item!.begin).toFormat(toFormat),
        value: item!.distribution.find(distributionEntry => distributionEntry?.status === 'OverVoltage')?.count ?? 0,
    }));

    const outageData = dataOrEmpty.map<CartesianChartDataType<string>>(item => ({
        key: DateTime.fromISO(item!.begin).toFormat(toFormat),
        value: item!.distribution.find(distributionEntry => distributionEntry?.status === 'Outage')?.count ?? 0,
    }));

    return [
        {
            id: 'out',
            name: 'Outages',
            color: Theme.coralRegular,
            data: outageData,
        },
        {
            id: 'ov',
            name: 'Overvoltages',
            color: Theme.mustardRegular,
            data: overVoltageData,
        },
        {
            id: 'uv',
            name: 'Undervoltages',
            color: Theme.mauveRegular,
            data: underVoltageData,
        },
    ];
}
