import React, { FC } from 'react';

import { DeviceIcon, SiteIcon, Tooltip } from '@accesstel/pcm-ui';

import { HighlightContext, highlight, isRelevant } from 'lib/search-results';
import { formatSiteName } from 'lib/textFormatters';

import style from './style.module.css';

export interface ReportSearchResults {
    site: string;
    siteName: string;
    id: string;
    name: string;
    state: string | null;
    type: 'site' | 'device';
}

export const SearchGroups = [
    {
        key: 'device',
        title: <span className='text-mauveRegular'>DEVICE</span>,
    },
    {
        key: 'site',
        title: <span className='text-eggplantRegular'>SITE</span>,
    },
];

export function renderSearchResult(result: ReportSearchResults, context: HighlightContext): React.ReactNode {
    if (result.type === 'site') {
        return <SiteSearchResult result={result} context={context} />;
    } else {
        return <DeviceSearchResult result={result} context={context} />;
    }
}

interface SearchResultProps {
    result: ReportSearchResults;
    context: HighlightContext;
}

const SiteSearchResult: FC<SearchResultProps> = ({ result, context }) => {
    const siteRepresentation = formatSiteName(result.siteName, result.state);
    const highlightedSiteName = highlight(siteRepresentation, context);

    return (
        <div className='flex flex-row items-center space-x-2'>
            <div className={style.icon_container}>
                <SiteIcon />
            </div>
            <Tooltip content={highlightedSiteName} overflowOnly>
                <div className='font-normal text-base truncate'>{highlightedSiteName}</div>
            </Tooltip>
        </div>
    );
};

const DeviceSearchResult: FC<SearchResultProps> = ({ result, context }) => {
    const siteRepresentation = formatSiteName(result.siteName, result.state);

    const deviceInfo = isRelevant(result.name, context) ? highlight(result.name, context) : result.name;
    const siteInfo = isRelevant(siteRepresentation, context)
        ? highlight(siteRepresentation, context)
        : siteRepresentation;

    return (
        <div className='flex flex-row items-center space-x-2 text-mauveRegular'>
            <div className={style.icon_container}>
                <DeviceIcon />
            </div>
            <Tooltip content={deviceInfo} overflowOnly>
                <div className='font-normal text-base truncate'>{deviceInfo}</div>
            </Tooltip>
            <Tooltip content={siteInfo} overflowOnly>
                <div className='font-light text-xs truncate'>{siteInfo}</div>
            </Tooltip>
        </div>
    );
};
