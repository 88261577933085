import React, { FC, Suspense } from 'react';
import { useFragment } from 'react-relay';

import { DateLineChart, LineChartSeries, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { SkeletonChart } from 'components/Skeleton/SkeletonChart';
import { numberToLocaleString } from 'lib/numberFormatters';
import { DateTime } from 'luxon';

import { VoltageChart_metrics$key } from './__generated__/VoltageChart_metrics.graphql';

interface VoltageChartProps {
    fragmentRef: VoltageChart_metrics$key;
}

export const VoltageChart: FC<VoltageChartProps> = ({ fragmentRef }) => {
    const data = useFragment<VoltageChart_metrics$key>(Fragment, fragmentRef);

    const chartData: LineChartSeries<Date>[] = [
        {
            name: 'Voltage',
            color: Theme.coralRegular,
            data:
                data.aggregatedVoltage?.map(dataPoint => ({
                    key: DateTime.fromISO(dataPoint.timestamp).toJSDate(),
                    value: dataPoint.average,
                })) ?? [],
        },
    ];

    return (
        <Suspense fallback={<SkeletonChart aspectRatio='1.7' stretch hideText />}>
            <DateLineChart
                series={chartData}
                hideTooltip={true}
                sizeVariant='small'
                aspectRatio={1.7}
                formatYTick={(value, index, tickCount) => {
                    if (index === 0) {
                        return numberToLocaleString(Number(value), 0);
                    }

                    if (index === tickCount - 1) {
                        return numberToLocaleString(Number(value), 0);
                    }

                    return '';
                }}
            />
        </Suspense>
    );
};

const Fragment = graphql`
    fragment VoltageChart_metrics on DeviceBatteryTestResults {
        aggregatedVoltage(points: 20) {
            timestamp
            average
        }
    }
`;
