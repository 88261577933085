import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { MetricDisplay } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { numberToLocaleStringFixed } from 'lib/numberFormatters';
import { formatValueWithUnit } from 'lib/units';

import { ResultView } from './ResultView';
import { MetricsView_test$key } from './__generated__/MetricsView_test.graphql';

interface MetricsViewProps {
    test: MetricsView_test$key | null;
}

export const MetricsView: FC<MetricsViewProps> = ({ test }) => {
    const userPreferences = useCurrentUserUnitsPref();

    const result = useFragment(
        graphql`
            fragment MetricsView_test on DeviceBatteryTestResults
            @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
                averageCurrent(unit: Amps)
                averageTemperature(unit: $unitTemperature)
                discharged(unit: AmpHour)
                finalVoltage(unit: Volts)

                ...ResultView_test
            }
        `,
        test
    );

    let endVoltage;
    if (result && result.finalVoltage != null) {
        endVoltage = `${numberToLocaleStringFixed(result.finalVoltage, 1)}V`;
    } else {
        endVoltage = '-';
    }

    let avgTemperature;
    if (result && result.averageTemperature != null) {
        avgTemperature = formatValueWithUnit(
            numberToLocaleStringFixed(result?.averageTemperature, 1),
            userPreferences.temperature
        );
    } else {
        avgTemperature = '-';
    }

    let avgCurrent;
    if (result && result.averageCurrent != null) {
        avgCurrent = `${numberToLocaleStringFixed(result.averageCurrent, 1)}A`;
    } else {
        avgCurrent = '-';
    }

    let discharged;
    if (result && result.discharged != null) {
        discharged = `${numberToLocaleStringFixed(result.discharged, 0)}Ah`;
    } else {
        discharged = '-';
    }

    return (
        <>
            <div className='mb-4'>
                <ResultView testResult={result} />
            </div>
            <div className='grid grid-cols-4'>
                <MetricDisplay label='End voltage' value={endVoltage} />
                <MetricDisplay label='Average current' value={avgCurrent} />
                <MetricDisplay label='Average temperature' value={avgTemperature} />
                <MetricDisplay label='Total discharged' value={discharged} />
            </div>
        </>
    );
};
