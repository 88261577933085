import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';

import { SiteViewNavigation } from '@accesstel/pcm-ui';

import { SkeletonCard, SkeletonText } from 'components/Skeleton';

import { getNavigationOptions } from './lib';

export const SiteViewLayoutSkeleton: FC = () => {
    const location = useLocation();

    return (
        <div className='space-y-6 mb-32'>
            <div className='flex flex-row justify-between'>
                <SkeletonText characters={30} size='heading' />
            </div>
            <div>
                <div className='font-light text-sm'>
                    <SkeletonText characters={40} />
                </div>
                <SkeletonText characters={17} />
            </div>
            <div className='w-full border-t border-eggplantExtraLight' />
            <SiteViewNavigation
                devices={[
                    {
                        device: {
                            id: '1',
                            name: ' '.repeat(35),
                            category: 'PowerController',
                        },
                        to: location.pathname,
                    },
                ]}
                site={{
                    to: location.pathname,
                    title: 'Site',
                    site: {
                        id: '1',
                        name: ' '.repeat(35),
                        state: ' '.repeat(15),
                    },
                }}
                items={getNavigationOptions('', false)}
            />
            <div className='grid grid-cols-2 gap-8'>
                <div>
                    <SkeletonText characters={10} size='heading' />
                    <SkeletonCard height='40rem' />
                </div>
                <div>
                    <SkeletonText characters={10} size='heading' />
                    <SkeletonCard height='40rem' />
                </div>
            </div>
        </div>
    );
};
