import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { BarDataType, LabelFormatterType, Tooltip, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import {
    BatteryTechnology,
    BatteryTypeTableColumn,
    BatteryTypeTableColumnId,
    batteryTypeToFilterObject,
    useBatteryTypeFilter,
} from 'filters/battery-type';
import { FilterActionType } from 'filters/common';
import { TableActionType, TableLayout, useTableReducer } from 'layouts';
import { SearchResultWithGroups } from 'layouts/TableLayout/components/Search';
import { useUserPermissions } from 'lib/auth';
import { BatteryTechnologyType } from 'lib/provision';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { HighlightContext, highlight, isRelevant } from 'lib/search-results';
import { formatBatteryType } from 'lib/textFormatters';
import { IEnvironment } from 'relay-runtime';

import { useConfirmationModal } from '../../../lib/confirmation';
import {
    BatteryType,
    useBatteryTypeColumnDefinitions,
    useBatteryTypeTableQuery,
} from '../../../lib/tables/battery-type';
import { colorPalette } from '../AssetManagement';
import { queries_GetAssetsDistributionQuery } from '../__generated__/queries_GetAssetsDistributionQuery.graphql';
import { getAssetsDistribution } from '../queries';
import { BatteryTypesAllIdsQuery } from './__generated__/BatteryTypesAllIdsQuery.graphql';
import { BatteryTypesSearchQuery, BatteryTypesSearchQuery$data } from './__generated__/BatteryTypesSearchQuery.graphql';
import { deleteBatteryTypes } from './lib/delete';
import { BaseTableColumns } from './settings';

type BatteryTypeSearchResult = BatteryTypesSearchQuery$data['batteryTypes']['data'][number];

const TableStorageKeyPrefix = 'battery-type-table';

export const ManageBatteryTypes: FC = () => {
    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();

    const [showModal, modalComponent] = useConfirmationModal();
    const { show: showToast } = useToast();

    const columnDefinitions = useBatteryTypeColumnDefinitions();

    const [tableState, dispatchTableState] = useTableReducer<BatteryTypeTableColumnId>({
        defaultSortColumn: BatteryTypeTableColumn.Manufacturer,
        allColumns: columnDefinitions,
        defaultVisibleColumns: BaseTableColumns,
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const [filters, dispatchFilters] = useBatteryTypeFilter();
    const filterObject = useMemo(() => batteryTypeToFilterObject(filters), [filters]);

    useDocumentTitle('Battery Type Management');

    const { data: props, error, retry, isFetching, fetchTable } = useBatteryTypeTableQuery(tableState, filters);

    const { data: distributionProps } = useQuery<queries_GetAssetsDistributionQuery>(
        getAssetsDistribution,
        { type: 'BatteryTypeTechnology' },
        { fetchPolicy: 'network-only' }
    );

    const handleSearch = useCallback(
        (input: string): Promise<SearchResultWithGroups<BatteryTypeSearchResult>> => {
            return fetchQuery<BatteryTypesSearchQuery>(
                environment,
                graphql`
                    query BatteryTypesSearchQuery($search: String = "", $pageSize: Int!) {
                        batteryTypes(search: $search, pageSize: $pageSize) {
                            data {
                                id
                                model
                                manufacturer
                                technology
                            }
                        }
                    }
                `,
                { search: input, pageSize: 10 }
            )
                .toPromise()
                .then(result => {
                    const results = (result?.batteryTypes.data as BatteryTypeSearchResult[]) ?? [];
                    const groups = results.map(searchResult => ({
                        key: searchResult.manufacturer,
                        title: <span className='text-eggplantRegular'>{searchResult.manufacturer}</span>,
                    }));

                    return { results, groups };
                });
        },
        [environment]
    );

    const distributionData: BarDataType[] =
        distributionProps?.assetDistribution?.distribution.map((group, i) => {
            let bgColor = undefined;
            if (colorPalette.length > i) {
                bgColor = colorPalette[i];
            }

            return {
                id: group.key,
                label: group.displayName ?? group.key,
                value: group.value,
                bgClass: bgColor,
            };
        }) ?? [];

    const labelFormatter: LabelFormatterType<BarDataType> = useCallback((label, data) => {
        if (label === null) {
            return '';
        }
        return `${data.value} ${formatBatteryType(label as BatteryTechnologyType)}`;
    }, []);

    const onSegmentClick = (id: string) => {
        dispatchFilters({
            type: FilterActionType.Apply,
            column: BatteryTypeTableColumn.Technology,
            value: [{ name: formatBatteryType(id as BatteryTechnologyType), id: id as BatteryTechnology }],
        });
    };

    const handleDelete = useCallback(() => {
        let title: string;
        let content: string;
        let successMessage: string;
        if (tableState.selectedItems.length === 1) {
            title = 'Delete Battery Type?';
            content = 'Are you sure you want to delete the selected battery type?';
            successMessage = 'Deleted battery type';
        } else {
            title = 'Delete Battery Types?';
            content = 'Are you sure you want to delete the selected battery types?';
            successMessage = `Deleted ${tableState.selectedItems.length} battery types`;
        }

        showModal({
            title,
            content,
            buttons: [
                {
                    id: 'confirm',
                    label: 'Delete',
                    variant: 'primary',
                },
                {
                    id: 'cancel',
                    label: 'Cancel',
                    variant: 'white',
                },
            ],
            onResult: async result => {
                if (result === 'cancel') {
                    return;
                }

                let ok = false;
                try {
                    const result = await deleteBatteryTypes(tableState.selectedItems, environment);
                    switch (result) {
                        case 'Success':
                            showToast({
                                text: successMessage,
                                variant: 'info',
                            });
                            ok = true;
                            break;
                        case 'PartialSuccess':
                            showToast({
                                text: `Unable to delete some battery types`,
                                variant: 'error',
                            });
                            ok = true;
                            break;
                        default:
                        case 'UnknownBatteryType':
                            showToast({
                                text: `Unknown battery type`,
                                variant: 'error',
                            });
                            break;
                    }
                } catch (error) {
                    captureException(error, scope => {
                        scope.setTag('Function', 'Delete battery types');
                        scope.setExtra('Battery type IDs', tableState.selectedItems);
                        return scope;
                    });
                    showToast({
                        text: `Error deleting battery types`,
                        variant: 'error',
                    });
                }

                if (ok) {
                    dispatchTableState({ type: TableActionType.SetSelection, selection: [] });
                }

                // Refresh the table
                setTimeout(() => {
                    // FIXME: this delay is present as elasticsearch isn't waiting for the refresh on delete
                    retry();
                }, 1000);
            },
        });
    }, [dispatchTableState, environment, retry, showModal, showToast, tableState.selectedItems]);

    return (
        <>
            <TableLayout
                title='Battery Type Management'
                columns={columnDefinitions}
                allowEditingColumns
                filterState={filters}
                dispatchFilterState={dispatchFilters}
                tableState={tableState}
                dispatchTableState={dispatchTableState}
                data={(props?.batteryTypes.data ?? null) as BatteryType[]}
                getRowId={row => row.id}
                isProcessing={!!props && isFetching}
                page={props?.batteryTypes.pageInfo.page}
                pageCount={props?.batteryTypes.pageInfo.total}
                overallCount={props?.overallBatteryTypes.total}
                resultCount={props?.batteryTypes.total}
                hasError={!!error}
                onRetry={retry}
                searchPlaceholder='Search by Battery Model/Manufacturer'
                onSearch={handleSearch}
                searchBoxProperties={{
                    groupKey: 'manufacturer',
                }}
                renderSearchResult={renderSearchResult}
                renderSearchResultAsString={(item: BatteryTypeSearchResult) => item.model}
                emptyMessage='There are no battery types present'
                unit='Battery Type'
                primaryAction={hasAssetsWrite ? 'Add new battery type' : undefined}
                primaryActionLink={hasAssetsWrite ? Paths.AddBatteryType : undefined}
                selection={hasAssetsWrite}
                onRequestAllIds={() => getAllBatteryTypeIds(environment, filterObject)}
                getItemLink={getBatteryTypeLink}
                selectionFooterActions={[
                    {
                        buttonText: `Delete selected ${
                            tableState.selectedItems.length > 1 ? 'battery types' : 'battery type'
                        }`,
                        onClick: handleDelete,
                    },
                ]}
                barChartData={{
                    data: distributionData,
                    emptyLabel: 'No battery types added',
                    labelFormatter,
                    onSegmentClick,
                }}
                exportEnabled
                exportFilename='battery-types'
                exportFetchData={options =>
                    fetchTable(options).then(results => results.flatMap(result => result.batteryTypes.data))
                }
            />
            {modalComponent}
        </>
    );
};

function getAllBatteryTypeIds(environment: IEnvironment, filters: Record<string, unknown>): Promise<string[]> {
    const getAllBatteryTypeIdsQuery = graphql`
        query BatteryTypesAllIdsQuery($filters: BatteryTypeFilter) {
            batteryTypes(onlyProvisioningStatuses: Active, pageSize: 10000, filters: $filters) {
                data {
                    id
                }
            }
        }
    `;

    return fetchQuery<BatteryTypesAllIdsQuery>(environment, getAllBatteryTypeIdsQuery, { filters })
        .toPromise()
        .then(data => data?.batteryTypes.data.map(batteryType => batteryType.id) ?? []);
}

function getBatteryTypeLink(batteryType: BatteryType): string {
    return generatePath(Paths.EditBatteryType, { id: batteryType.id });
}

function renderSearchResult(result: BatteryTypeSearchResult, context: HighlightContext): ReactNode {
    const title = isRelevant(result.model, context) ? highlight(result.model, context) : result.model;

    const batteryTechnology = formatBatteryType(result.technology);
    const content = `${result.model} ${batteryTechnology}`;

    return (
        <div className='flex items-center'>
            <Tooltip content={content} overflowOnly>
                <div className='truncate flex flex-row gap-2 items-baseline'>
                    <span>{title}</span>
                    <span className='font-light text-xs'>{formatBatteryType(result.technology)}</span>
                </div>
            </Tooltip>
        </div>
    );
}
