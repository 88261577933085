import { captureMessage } from '@sentry/react';

import {
    UnitFlowRate,
    UnitPressure,
    UnitTemperature,
    UnitVolume,
} from './__generated__/authUserPreferencesQuery.graphql';

export type MetricsLineType = 'current' | 'voltage' | 'power' | 'temperature' | 'load';

export function getUnit(type: MetricsLineType): string {
    switch (type) {
        case 'current':
            return 'A';
        case 'voltage':
            return 'V';
        case 'power':
            return 'W';
        case 'temperature':
            return '°C';
        case 'load':
            return 'A';
        default:
            return '';
    }
}

type Units = UnitPressure | UnitTemperature | UnitVolume | UnitFlowRate;

/**
 * If formatting a value with a unit, use `formatValueWithUnit` instead.
 */
export function getUserPrefBaseUnit(unit: Units | undefined): string {
    switch (unit) {
        // UnitPressure
        case 'Bar':
            return 'Bar';
        case 'KiloPascal':
            return 'kPa';
        case 'PSI':
            return 'PSI';
        case 'Pascal':
            return 'Pa';
        // UnitTemperature
        case 'Celsius':
            return '°C';
        case 'Fahrenheit':
            return '°F';
        case 'Kelvin':
            return 'K';
        // UnitVolume
        case 'Liter':
            return 'L';
        case 'USGallon':
            return 'gal';
        // UnitFlowRate
        case 'LiterPerHour':
            return 'L/h';
        case 'USGallonPerHour':
            return 'gal/h';
        case '%future added value':
        case undefined:
            captureMessage('Unhandled unit', context => {
                context.setTag('Function', 'getUserPrefBaseUnit');
                context.setExtra('unit', unit);

                return context;
            });
            return '';
    }
}

/**
 * If formatting a value with a unit, use `formatValueWithDisplayName` instead.
 */
export function getUserPrefDisplayName(unit: Units | undefined): string {
    switch (unit) {
        case 'Bar':
            return 'Bar';
        case 'KiloPascal':
            return 'Kilopascals (kPa)';
        case 'PSI':
            return 'Pounds per square inch (PSI)';
        case 'Pascal':
            return 'Pascals (Pa)';
        case 'Celsius':
            return 'Celsius (°C)';
        case 'Fahrenheit':
            return 'Fahrenheit (°F)';
        case 'Kelvin':
            return 'Kelvin (K)';
        case 'Liter':
            return 'Litres (L)';
        case 'USGallon':
            return 'US Gallons (gal)';
        case 'LiterPerHour':
            return 'Litres per Hour (L/h)';
        case 'USGallonPerHour':
            return 'US Gallons per Hour (gal/h)';
        case '%future added value':
        case undefined:
            captureMessage('Unhandled unit', context => {
                context.setTag('Function', 'getUserPrefDisplayName');
                context.setExtra('unit', unit);

                return context;
            });
            return '';
    }
}

/**
 * Note: When the unit contains a symbol, there is a space between the value and the unit.
 * Otherwise, there is no space.
 */
export function formatValueWithUnit(value: number | string, unit: Units | undefined): string {
    switch (unit) {
        case 'Fahrenheit':
        case 'Celsius':
            return `${value}${getUserPrefBaseUnit(unit)}`;
        default:
            return `${value} ${getUserPrefBaseUnit(unit)}`;
    }
}

export function formatValueWithDisplayName(value: number | string, unit: Units | undefined): string {
    return `${value} ${getUserPrefDisplayName(unit)}`;
}

export function formatRangeWithUnit(min: number | string, max: number | string, unit: Units | undefined): string {
    return `${formatValueWithUnit(min, unit)} - ${formatValueWithUnit(max, unit)}`;
}
