import React, { FC } from 'react';

import { SkeletonText } from '../../../components/Skeleton';

export const PersonalSettingsSkeleton: FC = () => {
    return (
        <div className='space-y-6 mb-32 text-eggplantRegular'>
            <SkeletonText characters={30} size='heading' />

            <div className='space-y-6'>
                <div className='bg-white' style={{ height: '30rem' }}></div>
                <div className='bg-white' style={{ height: '30rem' }}></div>
            </div>
        </div>
    );
};
