import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import {
    ArrowDownCircleIcon,
    CircleAlertIcon,
    CircleCheckIcon,
    CircleCrossIcon,
    CircleHelpIcon,
    Link,
    ListRow,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { wattToKillowattString } from 'lib/numberFormatters';
import { getUserPrefBaseUnit } from 'lib/units';

import { makeLinkToMetric } from '../../../../../explore/lib/link';
import { Operation } from '../../../../../explore/types';
import { DeviceHealth, ModuleRow_data$key } from './__generated__/ModuleRow_data.graphql';
import style from './style.module.css';

export interface ModuleRowProps {
    module: ModuleRow_data$key;
    deviceId: string;
    deviceOffline: boolean;
}

export const ModuleRow: FC<ModuleRowProps> = ({ module, deviceId, deviceOffline }) => {
    const data = useFragment(Fragment, module);
    const unitPreferences = useCurrentUserUnitsPref();

    let latestOutputPower: number | null = null;
    if (data.metrics?.latestOutputPower && !deviceOffline) {
        latestOutputPower = Math.round(data.metrics?.latestOutputPower);
    }

    let maximumPower: number | null = null;
    if (data.specs?.output?.maximumPower) {
        maximumPower = Math.round(data.specs?.output?.maximumPower);
    }

    let latestOutputCurrent: number | null = null;
    if (data.metrics?.latestOutputCurrent && !deviceOffline) {
        latestOutputCurrent = Math.round(data.metrics?.latestOutputCurrent);
    }

    let latestTemperature: number | null = null;
    if (data.metrics?.latestTemperature && !deviceOffline) {
        latestTemperature = Math.round(data.metrics?.latestTemperature);
    }

    let manufacturerModel: string;
    if (!data.specs?.manufacturer && !data.specs?.model) {
        manufacturerModel = 'Unknown module';
    } else {
        manufacturerModel = `${data.specs?.manufacturer} ${data.specs?.model}`.trim();
    }

    return (
        <div>
            <ListRow
                label={data.label}
                content={
                    <div className={style.grid_row}>
                        <div
                            className={classNames(
                                style.row_icon,
                                data.metrics.status === 'Healthy' && !deviceOffline
                                    ? 'text-pineRegular'
                                    : 'text-coralRegular'
                            )}
                        >
                            {getTileIcon(data.metrics.status, deviceOffline)}
                        </div>
                        <div
                            className={classNames(
                                'flex flex-col justify-start items-stretch leading-none min-w-0',
                                data.metrics.status !== 'Healthy' ? 'text-coralRegular' : ''
                            )}
                        >
                            <div className='text-xl font-bold truncate'>{manufacturerModel}</div>
                            <div className='text-sm font-light'>
                                {getPowerUsageText(latestOutputPower, maximumPower)}
                            </div>
                            <div>
                                <span className='text-sm font-light'>Serial: </span>
                                <span className='text-sm font-normal'>{data.serialNumber ?? '-'}</span>
                            </div>
                        </div>
                        <div
                            className={classNames(
                                'flex flex-col justify-start items-start leading-none',
                                data.metrics.status !== 'Healthy' ? 'text-coralRegular' : ''
                            )}
                        >
                            <Link
                                className='hover:underline'
                                to={makeLinkToMetric(deviceId, {
                                    metric: `RectifierModuleOutputCurrent.${data.label}`,
                                    op: Operation.Average,
                                })}
                            >
                                <span className='text-xl font-bold'>{latestOutputCurrent ?? '-'}</span>
                                <span className='text-sm font-light'>A Output</span>
                            </Link>
                            <Link
                                className='hover:underline'
                                to={makeLinkToMetric(deviceId, {
                                    metric: `RectifierModuleTemperature.${data.label}`,
                                    op: Operation.Average,
                                })}
                            >
                                <span className='text-sm font-normal'>{latestTemperature ?? '-'}</span>
                                <span className='text-sm font-light'>
                                    {getUserPrefBaseUnit(unitPreferences.temperature)}
                                </span>
                            </Link>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

function getPowerUsageText(latestOutputPower: number | null, maximumPower: number | null): string {
    if (latestOutputPower && maximumPower) {
        return `${wattToKillowattString(latestOutputPower)} of ${wattToKillowattString(maximumPower)} capacity used`;
    } else if (latestOutputPower && !maximumPower) {
        return `${wattToKillowattString(latestOutputPower)} capacity used`;
    } else if (!latestOutputPower && maximumPower) {
        return `${wattToKillowattString(maximumPower)} total capacity`;
    } else {
        return '-W Used';
    }
}

function getTileIcon(status: DeviceHealth, deviceOffline: boolean): React.ReactNode {
    if (deviceOffline) {
        return <CircleCrossIcon />;
    }

    switch (status) {
        case 'Healthy':
            return <CircleCheckIcon />;
        case 'Degraded':
            return <ArrowDownCircleIcon />;
        case 'Critical':
            return <CircleAlertIcon />;
        case 'Offline':
            return <CircleCrossIcon />;
        case 'Unknown':
            return <CircleHelpIcon />;
    }
}

const Fragment = graphql`
    fragment ModuleRow_data on RectifierModule @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
        id
        label
        specs {
            manufacturer
            model
            output {
                maximumPower(unit: Watt)
            }
        }
        serialNumber
        metrics {
            status
            latestTemperature(unit: $unitTemperature)
            latestOutputCurrent
            latestOutputPower(unit: Watt)
        }
    }
`;
