import React, { FC, useCallback } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';
import { generatePath } from 'react-router-dom';

import { BarDataType } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { FilterActionType } from 'filters/common';
import {
    DeviceHealthStatus,
    DeviceHealthStatusNames,
    SiteExtraFilters,
    SiteTableColumn,
    SiteTableColumnId,
    useSiteFilter,
} from 'filters/site';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { Paths } from 'lib/routes';

import { useSiteColumnDefinitions } from '../../lib/tables/site/columns';
import { useSiteTableQuery } from '../../lib/tables/site/request';
import { SiteDevice, SiteWithDevices } from '../../lib/tables/site/type';
import { DeviceHealth } from '../manage/site/__generated__/SitesAllIdsQuery.graphql';
import { DefaultTimeRange, TimeRange } from '../reports/ac-power/settings';
import { SiteListSearchQuery, SiteListSearchQuery$data } from './__generated__/SiteListSearchQuery.graphql';

export type SearchResult = SiteListSearchQuery$data['sites']['data'][number];

export const SiteList: FC = () => {
    const environment = useRelayEnvironment();

    const columnDefinitions = useSiteColumnDefinitions();

    const [tableState, dispatchTableState] = useTableReducer<SiteTableColumnId>({
        defaultSortColumn: SiteTableColumn.Name,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: columnDefinitions,
        defaultVisibleColumns: [
            SiteTableColumn.Name,
            SiteTableColumn.State,
            SiteTableColumn.DeviceCount,
            SiteTableColumn.DeviceStatus,
        ],
        storageKeyPrefix: 'powercontroller-table',
    });

    const [filters, dispatchFilters] = useSiteFilter();

    const acReliabilityTimeRange: TimeRange =
        (filters.extraFilters[SiteExtraFilters.ACReliabilityTimeRange] as TimeRange | undefined) ?? DefaultTimeRange;

    const { data: props, error, retry, isFetching, fetchTable } = useSiteTableQuery(tableState, filters);

    const handleSearch = useCallback(
        (input: string) => {
            return fetchQuery<SiteListSearchQuery>(
                environment,
                graphql`
                    query SiteListSearchQuery($name: String = "", $pageSize: Int!, $acTimeRange: ACTimeRange!) {
                        sites(
                            filters: { name: { value: $name } }
                            pageSize: $pageSize
                            onlyProvisioningStatuses: Active
                            acReliabilityTimeRange: $acTimeRange
                        ) {
                            data {
                                id
                                name
                            }
                        }
                    }
                `,
                { name: input, pageSize: 10, acTimeRange: acReliabilityTimeRange }
            )
                .toPromise()
                .then(result => (result?.sites.data as SearchResult[]) ?? []);
        },
        [acReliabilityTimeRange, environment]
    );

    const barChartData: BarDataType[] = [];
    if (props) {
        let unknownTotal = 0;

        props.globalDeviceHealth.forEach((status: { count: number; status: DeviceHealth | null }) => {
            if (status.status !== 'Unknown') {
                barChartData.push({
                    id: status.status ?? '',
                    label: status.status ?? '',
                    value: status.count,
                    bgClass: healthStringToColour(status.status ?? ''),
                });
            } else {
                unknownTotal = status.count;
            }
        });

        if (unknownTotal >= 1) {
            barChartData.push({
                label: 'Unknown',
                value: unknownTotal,
                bgClass: healthStringToColour('Unknown'),
            });
        }
    }

    const onSegmentClick = (state: string) => {
        dispatchFilters({
            type: FilterActionType.Apply,
            column: SiteTableColumn.DeviceStatus,
            value: [
                { id: state as DeviceHealthStatus, displayText: DeviceHealthStatusNames[state as DeviceHealthStatus] },
            ],
        });
    };

    return (
        <TableLayout
            title='Sites'
            columns={columnDefinitions}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={props?.sites.data ?? null}
            isProcessing={!!props && isFetching}
            getRowId={(row: SiteWithDevices | SiteDevice) => row.id}
            page={props?.sites.pageInfo.page}
            pageCount={props?.sites.pageInfo.total}
            overallCount={props?.overallSites.total}
            resultCount={props?.sites.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder='Search by Site name'
            onSearch={handleSearch}
            renderSearchResultAsString={(item: SearchResult) => item.name}
            emptyMessage='There are no sites present'
            unit='Site'
            barChartData={{
                data: barChartData,
                emptyLabel: 'No devices found',
                labelFormatter,
                valueFormatter: barChartValueFormatter,
                onSegmentClick,
            }}
            getItemLink={getSiteLink}
            exportEnabled
            exportFilename='sites'
            exportFetchData={options =>
                fetchTable(options).then(results => results.flatMap(result => result.sites.data))
            }
        />
    );
};

function labelFormatter(label: string | null, data: unknown, unit: unknown): string {
    if (label === null) {
        return '';
    }

    return label.toUpperCase();
}

function barChartValueFormatter(value: number | null): string {
    return `${value} ${value === 1 ? 'Device' : 'Devices'}`;
}

function healthStringToColour(status: string): string {
    switch (status) {
        case 'Healthy':
            return 'bg-mauveRegular';
        case 'Degraded':
            return 'bg-coralLight';
        case 'Critical':
            return 'bg-mustardRegular';
        case 'Offline':
            return 'bg-coralRegular';
        case 'Unknown':
            return 'bg-eggplantExtraLight';
        default:
            return '';
    }
}

function getSiteLink(site: SiteWithDevices | SiteDevice): string {
    return generatePath(Paths.SiteViewViewSiteOverview, { siteId: site.id });
}
