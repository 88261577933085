import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchQuery, useFragment, useRelayEnvironment } from 'react-relay';
import { generatePath, useLocation } from 'react-router-dom';

import { Button, DualPlaneIcon, PageHeading, Tab, Tabs, useExtendedNavigate } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { UnitTemperature } from 'lib/__generated__/authUserPreferencesQuery.graphql';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { Paths } from 'lib/routes';
import { formatSiteName } from 'lib/textFormatters';
import { DateTime } from 'luxon';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { ViewBatteryTestResultCard } from './ViewBatteryTestResultCard';
import {
    ViewBatteryTestResultContent_test$data,
    ViewBatteryTestResultContent_test$key,
} from './__generated__/ViewBatteryTestResultContent_test.graphql';
import { PreviousTests } from './common/previous-tests/PreviousTests';

// Note this is a non breaking space
const Blank = ' ';

export interface RouteParams {
    id: string;
}

const CombinedTabId = 'combined';

const CombinedTab: Tab = {
    id: CombinedTabId,
    label: 'Both planes',
    icon: <DualPlaneIcon />,
    tooltip: 'Both planes',
    style: 'icon',
};

export interface ViewBatteryTestResultContentProps {
    test: ViewBatteryTestResultContent_test$key;
    testId: string;
    view: 'external' | 'task' | 'reports';
}

export const ViewBatteryTestResultContent: FC<ViewBatteryTestResultContentProps> = ({ test, testId, view }) => {
    const navigate = useExtendedNavigate();
    const location = useLocation();
    const userPreferences = useCurrentUserUnitsPref();

    const showDirectView = location.search.includes('direct');

    const [activeTestTab, setActiveTestTab] = useState(showDirectView ? testId : CombinedTabId);

    const props = useFragment(ViewBatteryTestResultContentFragment, test);
    useLiveData(props, userPreferences.temperature);

    interface Device {
        id: string;
        name: string;
        type: string;
        test: string;
    }

    const devicesInTest = useMemo<Device[]>(() => {
        const devicesInTest: Device[] = [];
        if (props.device) {
            devicesInTest.push({
                id: props.device.id,
                name: props.device.name,
                type: props.device.type.displayName,
                test: props.id,
            });

            if (props.companion?.device) {
                devicesInTest.push({
                    id: props.companion.device.id,
                    name: props.companion.device.name,
                    type: props.companion.device.type.displayName,
                    test: props.companion.test.id,
                });
            }

            devicesInTest.sort((a, b) => a.name.localeCompare(b.name));
        }

        return devicesInTest;
    }, [props]);

    const tabs: Tab[] = [];
    if (devicesInTest.length === 2) {
        tabs.push(CombinedTab);

        tabs.push({
            id: devicesInTest[0].test,
            label: devicesInTest[0].name,
            subtitle: devicesInTest[0].type,
        });

        tabs.push({
            id: devicesInTest[1].test,
            label: devicesInTest[1].name,
            subtitle: devicesInTest[0].type,
        });
    }

    // Switch tabs when the URL changes
    useEffect(() => {
        if (showDirectView) {
            setActiveTestTab(testId);
        } else {
            setActiveTestTab(CombinedTabId);
        }
    }, [showDirectView, testId]);

    const handleTabChange = useCallback(
        (tab: Tab) => {
            setActiveTestTab(tab.id);

            if (tab.id === CombinedTabId) {
                let url: string;

                if (view === 'external') {
                    url = generatePath(Paths.ViewExternalTestResults, { id: testId });
                } else if (view === 'reports') {
                    url = generatePath(Paths.ViewTestResultFromReport, { id: testId });
                } else {
                    url = generatePath(Paths.ViewTaskDeviceResults, {
                        taskId: props.task!.id,
                        deviceId: props.device!.id,
                    });
                }

                navigate(url, { replace: true });
                return;
            }

            const device = devicesInTest.find(device => device.test === tab.id);

            if (!device) {
                // not possible
                return;
            }

            let url: string;
            if (view === 'external') {
                url = generatePath(Paths.ViewExternalTestResults, { id: device.test });
            } else if (view === 'reports') {
                url = generatePath(Paths.ViewTestResultFromReport, { id: device.test });
            } else {
                url = generatePath(Paths.ViewTaskDeviceResults, { taskId: props.task!.id, deviceId: device.id });
            }

            navigate(`${url}?direct`, { replace: true });
        },
        [devicesInTest, navigate, props.device, props.task, testId, view]
    );

    const isDualPlaneTest = props.companion?.test?.id !== undefined;

    let title: string;
    let subtitle: string | undefined;

    if (props) {
        if (isDualPlaneTest) {
            if (activeTestTab === 'combined') {
                title = devicesInTest.map(device => device.name).join(' & ');
            } else {
                const device = devicesInTest.find(device => device.test === activeTestTab);
                if (device) {
                    title = device.name;
                    subtitle = device.type;
                } else {
                    title = '';
                }
            }
        } else {
            title = props.device.name;
            subtitle = props.device.type.displayName;
        }
    } else {
        title = 'Battery test result';
    }

    useDocumentTitle(title);

    if (!props) {
        return <ErrorNotFound />;
    }

    let siteLocation: string;
    if (props.device.site.address.address) {
        siteLocation = formatSiteName(props.device.site.address.address, props.device.site.address.state);
    } else {
        siteLocation = props.device.site.address.state;
    }

    let batteryReportLink = generatePath(Paths.ReportBatteriesViewSite, { siteId: props.device.site.id });
    if (!isDualPlaneTest || activeTestTab !== CombinedTabId) {
        batteryReportLink = generatePath(Paths.ReportBatteriesViewSiteDevice, {
            siteId: props.device.site.id,
            deviceId: props.device.id,
        });
    }

    return (
        <div className='space-y-6 mb-32 text-eggplantRegular'>
            <div className='flex flex-row items-baseline'>
                <PageHeading value={title} />
                <div className='font-light text-sm pl-2'>{subtitle}</div>
            </div>
            <div>
                <PageHeading value={props.device.site.name ?? Blank} secondary subheading />
                <div className='font-light text-sm'>
                    <span className='text-coralRegular'>Site Location: </span>
                    <span>{siteLocation}</span>
                </div>
            </div>
            <div>
                {isDualPlaneTest && (
                    <Tabs
                        activeItem={tabs.find(tab => tab.id === activeTestTab) ?? tabs[0]}
                        setActiveItem={handleTabChange}
                        tabs={tabs}
                        variant='card'
                    />
                )}
                <ViewBatteryTestResultCard
                    test={props}
                    isCombinedView={isDualPlaneTest && activeTestTab === CombinedTabId}
                />
            </div>
            <PreviousTests
                deviceIds={
                    activeTestTab === CombinedTabId
                        ? devicesInTest.map(device => device.id)
                        : [devicesInTest.find(device => device.test === activeTestTab)?.id ?? '']
                }
                commencedTime={props.commencedTime}
            />
            <div className='flex gap-4'>
                <Button variant='primary' size='small' buttonText='View Full Battery Report' to={batteryReportLink} />
            </div>
        </div>
    );
};

const ViewBatteryTestResultContentFragment = graphql`
    fragment ViewBatteryTestResultContent_test on DeviceBatteryTestResults
    @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
        id
        state
        completedTime
        commencedTime
        abortedTime

        task {
            id
            name
        }

        companion {
            device {
                id
                name
                type {
                    displayName
                }
            }

            test {
                id
                ...DualPlaneResult_test @arguments(unitTemperature: $unitTemperature)
            }
        }

        device {
            id
            name
            type {
                displayName
            }
            site {
                id
                name
                address {
                    address
                    state
                }
            }
        }

        ...ViewBatteryTestResultCard_test @arguments(unitTemperature: $unitTemperature)
    }
`;

const ReloadDataQuery = graphql`
    query ViewBatteryTestResultContentQuery($id: ID!, $unitTemperature: UnitTemperature) {
        batteryTestResult(id: $id) {
            ...ViewBatteryTestResultContent_test @arguments(unitTemperature: $unitTemperature)
        }
    }
`;

function useLiveData(test: ViewBatteryTestResultContent_test$data, unitTemperature: UnitTemperature) {
    const environment = useRelayEnvironment();

    const testState = test.state;
    const completedTime = test.completedTime as string;
    const abortedTime = test.abortedTime as string;

    let shouldBeLive = false;
    if (
        testState === 'InProgress' ||
        testState === 'Scheduled' ||
        testState === 'Waiting' ||
        testState === 'Finalizing' ||
        testState === 'Analyzing'
    ) {
        shouldBeLive = true;
    } else if (completedTime || abortedTime) {
        let finishedTime: string;

        if (completedTime) {
            finishedTime = completedTime;
        } else {
            finishedTime = abortedTime;
        }

        shouldBeLive = DateTime.now().diff(DateTime.fromISO(finishedTime), 'minutes').as('minutes') < 5;
    }

    useEffect(() => {
        if (!shouldBeLive) {
            return;
        }

        const observable = fetchQuery(environment, ReloadDataQuery, { id: test.id, unitTemperature });

        const subscription = observable.poll(5000).subscribe({});

        return () => {
            subscription.unsubscribe();
        };
    }, [environment, shouldBeLive, test.id, unitTemperature]);
}
