import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { loadMetricsExplorePageData } from './MetricsExploreContent';

const Routes: RouteObject[] = [
    {
        path: 'explore',
        handle: {
            title: 'Explore',
        },
        children: [
            {
                index: true,
                element: <Navigate to='metrics' replace />,
            },
            {
                path: 'metrics',
                loader: ({ request }) => loadMetricsExplorePageData(new URL(request.url)),
                lazy: async () => {
                    const { MetricsExplore } = await import('./MetricsExplore');
                    return { Component: MetricsExplore };
                },
                handle: {
                    title: 'Metrics',
                },
            },
        ],
    },
];

export default Routes;
