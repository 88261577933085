import React, { FC, ReactNode } from 'react';

import { SkeletonText } from 'components/Skeleton';

export interface DevicePaneSkeletonProps {
    combinedView?: boolean;
    children: ReactNode;
}

export const DevicePaneSkeleton: FC<DevicePaneSkeletonProps> = ({ combinedView = false, children }) => {
    return (
        <div className='flex flex-col items-stretch'>
            <div className='px-4 pb-1'>
                <div className='flex flex-row justify-between items-center'>
                    <div className='font-bold text-3xl truncate'>
                        <SkeletonText characters={10} />
                    </div>
                    {!combinedView && <SkeletonText characters={10} />}
                </div>

                {combinedView ? (
                    <div className='pb-4'></div>
                ) : (
                    <div className='flex flex-row justify-between items-center'>
                        <div className='font-light text-sm whitespace-nowrap'>
                            <SkeletonText characters={20} />
                        </div>
                        <div className='flex flex-row space-x-2 items-center'>
                            <div className='text-xs text-eggplantLight'>
                                <SkeletonText characters={25} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className='flex-grow bg-white p-5'>{children}</div>
        </div>
    );
};
