import React, { FC } from 'react';

import { SkeletonText } from 'components/Skeleton';
import { SkeletonChart } from 'components/Skeleton/SkeletonChart';

import style from '../style.module.css';

export const SingleSiteSkeleton: FC = () => {
    return (
        <div className={`${style.container} space-y-4 text-eggplantRegular`}>
            {/* FIXME: Remove the eggplant colour class from here once postcss and tailwind are working properly with css modules */}

            <div className='flex flex-row justify-between items-center'>
                <SkeletonText characters={20} size='heading' />
            </div>
            <div className={style.site_info}>
                <SkeletonText characters={30} />
                <SkeletonText characters={20} />
            </div>
            <div className={style.divider}></div>
            <div className='space-y-12'>
                <div className='space-y-2'>
                    <div className='flex flex-row justify-between'>
                        <SkeletonText characters={25} className='font-bold text-3xl text-eggplantRegular' />
                    </div>
                    <div className='grid grid-cols-2'>
                        <SkeletonText characters={20} />
                        <SkeletonText characters={20} />
                        <SkeletonText characters={20} />
                        <SkeletonText characters={20} />
                        <SkeletonText characters={20} />
                        <SkeletonText characters={20} />
                        <SkeletonText characters={20} />
                        <SkeletonText characters={20} />
                    </div>
                </div>
                <div className='space-y-2'>
                    <SkeletonText characters={11} className='font-bold text-coralRegular' />
                    <div className='flex flex-row w-full gap-8 justify-between items-stretch'>
                        <div className='bg-white flex-grow opacity-50' style={{ aspectRatio: '264 / 178' }}></div>
                        <div className='bg-white flex-grow opacity-50' style={{ aspectRatio: '264 / 178' }}></div>
                        <div className='bg-white flex-grow opacity-50' style={{ aspectRatio: '264 / 178' }}></div>
                        <div className='bg-white flex-grow opacity-50' style={{ aspectRatio: '264 / 178' }}></div>
                    </div>
                </div>
                <div>
                    <SkeletonText characters={15} />
                </div>
                <div className='grid grid-cols-2 gap-8'>
                    <SkeletonChart aspectRatio='35 / 22' stretch />
                    <SkeletonChart aspectRatio='35 / 22' stretch />
                    <SkeletonChart aspectRatio='35 / 22' stretch />
                    <SkeletonChart aspectRatio='35 / 22' stretch />
                    <SkeletonChart aspectRatio='35 / 22' stretch />
                    <SkeletonChart aspectRatio='35 / 22' stretch />
                    <SkeletonChart aspectRatio='35 / 22' stretch />
                </div>
                <div>
                    <SkeletonText characters={20} className='font-bold pb-4' />
                </div>
            </div>
            <div className={style.footer}></div>
        </div>
    );
};
