import React from 'react';
import { useFragment } from 'react-relay';

import { Change, SmallSparkline, TimeseriesPoint } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { getUserPrefBaseUnit } from 'lib/units';

import { CommonPropsWithParentQuery, asChange } from '../common';
import { TemperatureSparkline_battery$key } from './__generated__/TemperatureSparkline_battery.graphql';

export function TemperatureSparkline({ metrics }: CommonPropsWithParentQuery): JSX.Element {
    const unitPreferences = useCurrentUserUnitsPref();

    const result = useFragment<TemperatureSparkline_battery$key>(
        graphql`
            fragment TemperatureSparkline_battery on DeviceBatteryMetrics
            @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
                latestTemperature(unit: $unitTemperature)
                temperature(unit: $unitTemperature) {
                    values {
                        timestamp
                        value
                    }
                    trend {
                        state
                    }
                }
            }
        `,
        metrics
    );

    const data = result?.temperature ?? {
        trend: {
            state: 'Flat',
        },
        values: [],
    };

    const history: TimeseriesPoint[] = data.values.map(value => ({
        date: new Date(value.timestamp as string),
        value: value.value,
    }));

    return (
        <SmallSparkline
            background='dark'
            history={history}
            value={result?.latestTemperature ?? null}
            unit={getUserPrefBaseUnit(unitPreferences.temperature)}
            title='Battery temperature'
            change={data.trend ? asChange(data.trend.state) : Change.Flat}
        />
    );
}
