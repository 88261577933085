import { DateRangeFilter } from 'components/FilterDateSelect/common';
import { formatDateFilter, formatDurationFilter } from 'filters/common/util';
import { DeviceNameFilterUI, decodeDeviceNameFilter, encodeDeviceNameFilter } from 'filters/device';
import {
    ACPowerIncidentIsSignificantOnlyFilterUI,
    IncidentDateTimeFilter,
    decodeIncidentAffectedFeedFilter,
    decodeIncidentIsSignificantOnlyFilter,
    decodeIncidentTypeFilter,
    encodeIncidentAffectedFeedFilter,
    encodeIncidentIsSignificantOnlyFilter,
    encodeIncidentTypeFilter,
} from 'filters/incidents';
import { decodeBatteryTestRuntimeFilter, encodeBatteryTestRuntimeFilter } from 'filters/task';

import { FilterDefinition, FilterValueMap, decodeDateRangeFilter, encodeDateRangeFilter } from '../common';
import {
    SiteACPowerDeviceAffectedFeedFilterUI,
    SiteACPowerIncidentDurationFilterUI,
    SiteACPowerIncidentTypeFilterUI,
} from './components';
import { SiteACPowerIncidentStartTimeFilterUI } from './components/SiteACPowerIncidentStartTimeFilterUI';
import {
    SiteDeviceAffectedFeedFilter,
    SiteIncidentDeviceNameFilter,
    SiteIncidentDurationFilter,
    SiteIncidentExtraFilters,
    SiteIncidentIsSignificantOnlyFilter,
    SiteIncidentTableColumn,
    SiteIncidentTypeFilter,
} from './types';

export type SiteIncidentColumnFilterMap = {
    [SiteIncidentTableColumn.DeviceName]: SiteIncidentDeviceNameFilter[];
    [SiteIncidentTableColumn.IncidentType]: SiteIncidentTypeFilter[];
    [SiteIncidentTableColumn.AffectedFeeds]: SiteDeviceAffectedFeedFilter[];
    [SiteIncidentTableColumn.IncidentDuration]: SiteIncidentDurationFilter | null;
    [SiteIncidentTableColumn.IncidentStartTime]: DateRangeFilter | null;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type SiteIncidentExtraFilterColumnMap = {
    [SiteIncidentExtraFilters.IsSignificant]: SiteIncidentIsSignificantOnlyFilter | null;
};

export type SiteIncidentAllFilterMap = SiteIncidentColumnFilterMap & SiteIncidentExtraFilterColumnMap;

export const DefaultValues: FilterValueMap<SiteIncidentColumnFilterMap> = {
    [SiteIncidentTableColumn.DeviceName]: [],
    [SiteIncidentTableColumn.IncidentType]: [],
    [SiteIncidentTableColumn.AffectedFeeds]: [],
    [SiteIncidentTableColumn.IncidentDuration]: null,
    [SiteIncidentTableColumn.IncidentStartTime]: null,
};

export const DefaultExtraFilters: FilterValueMap<SiteIncidentExtraFilterColumnMap> = {
    [SiteIncidentExtraFilters.IsSignificant]: null,
};

export const StaticSiteIncidentFilterDefinitions: FilterDefinition<SiteIncidentTableColumn>[] = [
    {
        id: SiteIncidentTableColumn.DeviceName,
        type: 'multi',
        name: 'Device Name',
        category: 'Device Name',
        column: SiteIncidentTableColumn.DeviceName,
        component: () => DeviceNameFilterUI,
        describeValue: (value: SiteIncidentDeviceNameFilter) => value.name,
        encodeValue: encodeDeviceNameFilter,
        decodeValue: decodeDeviceNameFilter,
    },
    {
        id: SiteIncidentTableColumn.IncidentStartTime,
        type: 'single',
        name: 'Incident Date',
        category: 'Incident Date',
        column: SiteIncidentTableColumn.IncidentStartTime,
        component: () => SiteACPowerIncidentStartTimeFilterUI,
        describeValue: (value: IncidentDateTimeFilter) => formatDateFilter(value),
        encodeValue: encodeDateRangeFilter,
        decodeValue: decodeDateRangeFilter,
    },
    {
        id: SiteIncidentTableColumn.IncidentType,
        type: 'multi',
        name: 'Incident Type',
        category: 'Incident Type',
        column: SiteIncidentTableColumn.IncidentType,
        component: () => SiteACPowerIncidentTypeFilterUI,
        describeValue: (value: SiteIncidentTypeFilter) => value.name,
        encodeValue: encodeIncidentTypeFilter,
        decodeValue: decodeIncidentTypeFilter,
    },
    {
        id: SiteIncidentTableColumn.IncidentDuration,
        type: 'single',
        name: 'Incident Duration',
        category: 'Incident Duration',
        column: SiteIncidentTableColumn.IncidentDuration,
        component: () => SiteACPowerIncidentDurationFilterUI,
        describeValue: (value: SiteIncidentDurationFilter) => formatDurationFilter(value),
        encodeValue: encodeBatteryTestRuntimeFilter,
        decodeValue: decodeBatteryTestRuntimeFilter,
    },
    {
        id: SiteIncidentTableColumn.AffectedFeeds,
        type: 'multi',
        name: 'Affected Device Feeds',
        category: 'Affected Device Feeds',
        column: SiteIncidentTableColumn.AffectedFeeds,
        component: () => SiteACPowerDeviceAffectedFeedFilterUI,
        describeValue: (value: SiteDeviceAffectedFeedFilter) => value.label,
        encodeValue: encodeIncidentAffectedFeedFilter,
        decodeValue: decodeIncidentAffectedFeedFilter,
    },
    {
        id: SiteIncidentExtraFilters.IsSignificant,
        type: 'single',
        name: 'Is Significant',
        category: 'Is Significant',
        component: () => ACPowerIncidentIsSignificantOnlyFilterUI,
        describeValue: (value: SiteIncidentIsSignificantOnlyFilter) => value.label,
        encodeValue: encodeIncidentIsSignificantOnlyFilter,
        decodeValue: decodeIncidentIsSignificantOnlyFilter,
    },
];
