import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { BarChart, CartesianChartDataType, ChartArea, Domain, LoadableContentArea, Theme } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { numberToLocaleString } from 'lib/numberFormatters';

import { ChartAspectRatio } from '../../settings';
import { BatteryCapacityHisto_remainingCapacityHistogram$key } from './__generated__/BatteryCapacityHisto_remainingCapacityHistogram.graphql';
import style from './common.module.css';

export interface BatteryCapacityHistoProps {
    fragmentRef: BatteryCapacityHisto_remainingCapacityHistogram$key | null;
}

export const BatteryCapacityHisto: FC<BatteryCapacityHistoProps> = ({ fragmentRef }) => {
    const data = useFragment(Fragment, fragmentRef);

    return (
        <ChartArea title='Battery capacity across network' className={style.chart}>
            <LoadableContentArea
                data={data}
                className={style.doughnut}
                render={result => {
                    const chartData: CartesianChartDataType<string>[] = result.buckets.map(bucket => ({
                        key: `${numberToLocaleString(bucket.rangeLow, 0)} Ah`,
                        tooltip: `${numberToLocaleString(bucket.rangeLow, 1)} - ${numberToLocaleString(
                            bucket.rangeHigh,
                            1
                        )} Ah`,
                        value: bucket.count,
                    }));

                    let maxIndexWithValue = 0;
                    chartData.forEach((data, index) => {
                        if (data.value !== 0) {
                            maxIndexWithValue = index;
                        }
                    });

                    const filteredChartData = maxIndexWithValue === 0 ? [] : chartData.slice(0, maxIndexWithValue + 1);

                    const maxValue =
                        filteredChartData.length > 0
                            ? filteredChartData.reduce((prev, curr) => {
                                  if (curr.value != null) {
                                      return prev.value != null && prev.value > curr.value ? prev : curr;
                                  } else {
                                      return prev;
                                  }
                              })?.value
                            : 10;
                    const yDomain: Domain<number> | undefined = maxValue && maxValue < 11 ? [0, 10] : undefined;

                    return (
                        <BarChart
                            series={
                                filteredChartData.length > 0
                                    ? [
                                          {
                                              id: 'bc',
                                              name: 'Battery capacity',
                                              color: Theme.eggplantExtraLight,
                                              data: filteredChartData,
                                          },
                                      ]
                                    : []
                            }
                            yTicks={11}
                            yDomain={yDomain}
                            formatTooltipValue={value => `${value} devices`}
                            aspectRatio={ChartAspectRatio}
                        />
                    );
                }}
            />
        </ChartArea>
    );
};

const Fragment = graphql`
    fragment BatteryCapacityHisto_remainingCapacityHistogram on Histogram {
        buckets {
            rangeLow
            rangeHigh
            count
        }
        countMin
        countMax
        rangeLow
        rangeHigh
    }
`;
