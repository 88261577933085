import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import { BatteryEmptyIcon, DescriptionIcon } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNamesBind from 'classnames/bind';
import { formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { formatAsCauseSentence } from 'lib/textFormatters';

import { BorderedSection } from '../../../../../components/BorderedSection';
import { formatAPIDate } from '../common';
import {
    TestParameterViewCombined_test$data,
    TestParameterViewCombined_test$key,
} from './__generated__/TestParameterViewCombined_test.graphql';
import style from './style.module.css';

const classNames = classNamesBind.bind(style);

interface TestParameterViewCombinedProps {
    planeA: TestParameterViewCombined_test$key;
    planeB: TestParameterViewCombined_test$key;
}

export const TestParameterViewCombined: FC<TestParameterViewCombinedProps> = ({ planeA, planeB }) => {
    const planeAResult = useFragment(TestParametersFragment, planeA);
    const planeBResult = useFragment(TestParametersFragment, planeB);

    return (
        <div className={classNames('test_parameter_view')}>
            <TestDetailsSection planeA={planeAResult} planeB={planeBResult} />
            <BatteryDetailsSection planeA={planeAResult} planeB={planeBResult} />
        </div>
    );
};

interface TestDetailsSectionProps {
    planeA: TestParameterViewCombined_test$data;
    planeB: TestParameterViewCombined_test$data;
}

const TestDetailsSection: FC<TestDetailsSectionProps> = ({ planeA, planeB }) => {
    const schedule = planeA.task?.schedule;

    let repeatText: string;
    if (schedule && schedule.repeat !== 'Never') {
        repeatText = `Repeats ${schedule.repeat.toLocaleLowerCase()}`;
    } else {
        repeatText = 'Does not repeat';
    }

    let testDescription: ReactNode = '';
    if (planeA.task) {
        if (planeA.task.type === 'Capacity') {
            let minimumVoltage: number | undefined;
            planeA.task.settings.targets!.forEach(target => {
                if (minimumVoltage === undefined || target.endOfDischargeVoltage < minimumVoltage) {
                    minimumVoltage = target.endOfDischargeVoltage;
                }
            });

            testDescription = `Capacity test to ${minimumVoltage}Vpc.`;
        } else if (planeA.task.type === 'Quick') {
            let reserveTimeThresholdDescription: string;
            if (planeA.device.battery.reserveTime != null) {
                const thresholdMinutes = (planeA.task.settings.reserveTime! / 100) * planeA.device.battery.reserveTime;
                reserveTimeThresholdDescription = `${thresholdMinutes} minutes`;
            } else {
                reserveTimeThresholdDescription = `${planeA.task.settings.reserveTime}% of reserve time`;
            }

            testDescription = `Quick test running for ${reserveTimeThresholdDescription} with a ${planeA.task.settings.threshold}V cutoff.`;
        } else if (planeA.task.type === 'Custom') {
            const settingsDescriptions: string[] = [];

            if (planeA.task.settings.maxDuration != null) {
                settingsDescriptions.push(`Up to ${planeA.task.settings.maxDuration} minutes runtime`);
            }

            if (planeA.task.settings.maxDischarged != null) {
                settingsDescriptions.push(`At most ${planeA.task.settings.maxDischarged}Ah discharged`);
            }

            if (planeA.task.settings.endOfDischargeVoltage != null) {
                settingsDescriptions.push(`Ending at ${planeA.task.settings.endOfDischargeVoltage}V`);
            }

            testDescription = (
                <>
                    Configured as a custom test:
                    <ul className={classNames('bullet_list')}>
                        {settingsDescriptions.map((description, i) => (
                            <li key={i}>{description}</li>
                        ))}
                    </ul>
                </>
            );
        }
    }

    let isInFuture = false;
    if (planeA.task && planeA.task.schedule.time) {
        isInFuture = new Date(planeA.task.schedule.time) > new Date();
    }

    let externalDischargeDescription: string | undefined;
    if (!planeA.task) {
        if (planeA.cause === planeB.cause) {
            externalDischargeDescription = `It was caused by ${formatAsCauseSentence(planeA.cause)}.`;
        } else if (planeA.cause === 'CompanionDischarge') {
            const isRunning = planeB.state === 'InProgress';
            externalDischargeDescription = `It was caused by ${formatAsCauseSentence(planeB.cause)}. ${
                planeA.device.name
            } ${isRunning ? 'is' : 'was'} not discharging.`;
        } else if (planeB.cause === 'CompanionDischarge') {
            const isRunning = planeA.state === 'InProgress';
            externalDischargeDescription = `It was caused by ${formatAsCauseSentence(planeA.cause)}. ${
                planeB.device.name
            } ${isRunning ? 'is' : 'was'} not discharging.`;
        } else {
            // NOTE: This shouldnt happen, but just in case
            externalDischargeDescription = `It was caused by ${formatAsCauseSentence(planeA.cause)}.`;
        }
    }

    return (
        <BorderedSection title='Description' icon={<DescriptionIcon />}>
            {planeA.task && (
                <>
                    <div>{testDescription}</div>
                    <div>
                        {isInFuture ? 'Starting ' : 'It was scheduled for '}
                        {formatAPIDate(planeA.task.schedule.time)}.
                    </div>
                    <div>{repeatText}.</div>
                </>
            )}
            {!planeA.task && (
                <>
                    <p>A discharge that occurred outside of accata.</p>
                    <p>{externalDischargeDescription}</p>
                </>
            )}
        </BorderedSection>
    );
};

interface BatteryDetailsSectionProps {
    planeA: TestParameterViewCombined_test$data;
    planeB: TestParameterViewCombined_test$data;
}

const BatteryDetailsSection: FC<BatteryDetailsSectionProps> = ({ planeA, planeB }) => {
    function summarizeBatteries(test: TestParameterViewCombined_test$data): ReactNode {
        const summaryLines: string[] = [];
        if (test.batteryStrings.length > 1) {
            summaryLines.push(`${test.batteryStrings.length} battery strings.`);
        } else {
            summaryLines.push(`${test.batteryStrings.length} battery string.`);
        }

        if (test.originalCapacity != null) {
            summaryLines.push(`${test.originalCapacity}Ah total capacity.`);
        }

        if (test.device.battery.reserveTime != null) {
            summaryLines.push(`${formatMinutesAsHoursAndMinutes(test.device.battery.reserveTime)} reserve time.`);
        }

        return (
            <div>
                <div className='font-bold'>{test.device.name}</div>
                <ul>
                    {summaryLines.map((line, i) => (
                        <li key={i}>{line}</li>
                    ))}
                </ul>
            </div>
        );
    }

    // Always keep the planes in alphabetical order
    const firstPlane = planeA.device.name < planeB.device.name ? planeA : planeB;
    const secondPlane = planeA.device.name < planeB.device.name ? planeB : planeA;

    return (
        <BorderedSection title='Battery configuration' icon={<BatteryEmptyIcon />}>
            <div className='flex justify-start gap-6'>
                {summarizeBatteries(firstPlane)}
                {summarizeBatteries(secondPlane)}
            </div>
        </BorderedSection>
    );
};

const TestParametersFragment = graphql`
    fragment TestParameterViewCombined_test on DeviceBatteryTestResults {
        state
        task {
            creator {
                name
                email
            }
            schedule {
                repeat
                time
            }
            type
            createdTime
            completedTime
            settings {
                ... on BatteryTestTypeCapacity {
                    targets {
                        endOfDischargeVoltage
                        batteryType {
                            id
                            manufacturer
                            model
                        }
                    }
                }
                ... on BatteryTestTypeQuick {
                    reserveTime
                    threshold
                }
                ... on BatteryTestTypeCustom {
                    endOfDischargeVoltage
                    maxDuration
                    maxDischarged
                }
            }
        }
        commencedTime
        completedTime
        abortedTime
        abortedBy {
            name
            email
        }
        batteryStrings {
            type {
                manufacturer
                model
                technology
            }
            string
            originalCapacity
        }
        batteryTypes {
            manufacturer
            model
            technology
        }
        cause
        device {
            name
            battery {
                reserveTime(unit: Minutes)
            }
        }
        originalCapacity(unit: AmpHour)
    }
`;
