import React from 'react';
import { RouteObject } from 'react-router-dom';

import { getDeviceBreadcrumb, getSiteBreadcrumb } from 'lib/breadcrumb';
import { RouteExtraData } from 'lib/route-helpers';
import { ViewBatteryTestResultDirect } from 'views/tasks/battery-health/test-result-view/ViewBatteryTestResultDirect';

import { Reports } from './Reports';
import ACPowerRoutes from './ac-power/route';
import { AlertList } from './alerts';
import { BatteryOverview, BatterySiteList, SingleDeviceTests } from './batteries';
import { NavigateToFirstDevice, loadSingleSitePageData } from './batteries/site-view/SingleSiteContent';

const Routes: RouteObject[] = [
    {
        path: 'reports',
        handle: {
            title: 'Reports',
        },
        children: [
            {
                index: true,
                element: <Reports />,
            },
            {
                path: 'alerts',
                handle: {
                    title: 'Insights',
                },
                children: [
                    {
                        index: true,
                        element: <AlertList />,
                        handle: {
                            title: 'Insights',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                ],
            },
            ...ACPowerRoutes,
            {
                path: 'batteries',
                handle: {
                    title: 'Batteries',
                },
                children: [
                    {
                        index: true,
                        element: <BatteryOverview />,
                    },
                    {
                        path: 'sites',
                        handle: {
                            title: 'Batteries by Site',
                        },
                        children: [
                            {
                                index: true,
                                element: <BatterySiteList />,
                                handle: {
                                    title: 'Batteries by Site',
                                    fullWidth: true,
                                } as RouteExtraData,
                            },
                            {
                                path: ':siteId',
                                handle: {
                                    title: params => {
                                        return getSiteBreadcrumb(params.siteId!);
                                    },
                                } as RouteExtraData,
                                children: [
                                    {
                                        index: true,
                                        element: <NavigateToFirstDevice />,
                                        loader: ({ params }) => loadSingleSitePageData(params.siteId!),
                                    },
                                    {
                                        path: ':deviceId',
                                        loader: ({ params }) => loadSingleSitePageData(params.siteId!),
                                        lazy: async () => {
                                            const { BatterySingleSiteView } = await import(
                                                './batteries/site-view/SingleSite'
                                            );
                                            return { Component: BatterySingleSiteView };
                                        },
                                        handle: {
                                            title: params => getDeviceBreadcrumb(params.deviceId!),
                                        } as RouteExtraData,
                                    },
                                    {
                                        path: 'tests/:deviceId',
                                        element: <SingleDeviceTests />,
                                        handle: {
                                            title: 'Battery tests',
                                            fullWidth: true,
                                        } as RouteExtraData,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: 'test/:id',
                        element: <ViewBatteryTestResultDirect fromReports />,
                        handle: {
                            // FIXME: Set to a generic title, because it lives under Batteries.
                            title: 'Test Result',
                        } as RouteExtraData,
                    },
                ],
            },
        ],
    },
];

export default Routes;
