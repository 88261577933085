import React, { FC } from 'react';
import { useLazyLoadQuery } from 'react-relay';

import { CircleAlertIcon, ListTile, ListTileProps, ListView } from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { round } from 'lib/number';
import { DateTime } from 'luxon';

import { LoadHistoryTilesQuery } from './__generated__/LoadHistoryTilesQuery.graphql';

interface Tile {
    label: string;
    value: number | null;
    change: number | null;
}

export interface LoadHistoryTilesProps {
    deviceId: string;
}

export const LoadHistoryTiles: FC<LoadHistoryTilesProps> = ({ deviceId }) => {
    const now = DateTime.local();

    const data = useLazyLoadQuery<LoadHistoryTilesQuery>(
        LoadLifetimeQuery,
        {
            deviceId,
            recentStart: now.minus({ months: 6 }).startOf('month').toISO(),
            recentEnd: now.endOf('month').toISO(),
            // TODO: This should ideally be unbounded, but aligned to years
            lifetimeStart: DateTime.fromISO('2020-01-01T00:00:00.000Z').toISO(),
            lifetimeEnd: now.endOf('month').toISO(),
        },
        {
            networkCacheConfig: {
                metadata: {
                    // This is a long running query, it takes quite a while to load
                    timeout: 20000,
                },
            },
        }
    );

    if (!data.device) {
        // This should never happen as the DeviceLayout validates devices
        captureMessage('Assertion failed: device is null in LoadHistoryTiles', scope => {
            scope.setExtra('data', data);
            scope.setTag('Component', 'LoadHistoryTiles');
            return scope;
        });
        return null;
    }

    const recentTiles: Tile[] = [];
    const lifetimeTiles: Tile[] = [];

    if (data.device.load?.metrics?.recentPower?.values) {
        let recentPowerValues = data.device.load.metrics.recentPower.values;

        let previousValue: number | null = null;

        // while the extra month is not visible, we still use its value to calculate the change
        if (recentPowerValues.length >= 7) {
            previousValue = recentPowerValues[recentPowerValues.length - 7].value;
        }

        // limit to the last 6 months
        if (recentPowerValues.length > 6) {
            recentPowerValues = recentPowerValues.slice(recentPowerValues.length - 6);
        }
        // We want to see this month, which is why we skip that first month
        const earliestDate = DateTime.local().minus({ months: 6 }).endOf('month');
        recentPowerValues = recentPowerValues.filter(value => DateTime.fromISO(value.timestamp) > earliestDate);

        for (const value of recentPowerValues) {
            let change: number | null = null;
            if (previousValue != null && value.value != null && previousValue > 0) {
                // percentage change of current value compare to previous value
                change = ((value.value - previousValue) / previousValue) * 100;
                change = Math.round(change);
            }

            if (value.value != null) {
                previousValue = value.value;
            }

            const startOfBucketDate = DateTime.fromISO(value.timestamp);

            // Use a date in the middle of the bucket to deal with timezone offset changes
            const midDate = startOfBucketDate.plus({ days: 15 });

            let label: string;
            if (midDate.hasSame(DateTime.local(), 'month')) {
                label = 'Current';
            } else {
                label = midDate.toFormat('LLLL');
            }

            recentTiles.push({
                label,
                value: value.value,
                change,
            });
        }
    }

    if (data.device.load?.metrics?.lifetimePower?.values) {
        const lifetimePowerValues = data.device.load.metrics.lifetimePower.values;

        let hasSeenValue = false;

        for (let i = 0; i < lifetimePowerValues.length; ++i) {
            const value = lifetimePowerValues[i];

            // Hide initial empty tiles
            if (value.value == null && !hasSeenValue) {
                continue;
            }

            hasSeenValue = true;

            let change: number | null = null;
            if (i > 0) {
                const previousValue = lifetimePowerValues[i - 1];
                if (value.value != null && previousValue.value != null && previousValue.value > 0) {
                    // percentage change of current value compare to previous value
                    change = ((value.value - previousValue.value) / previousValue.value) * 100;
                    change = Math.round(change);
                }
            }

            const date = DateTime.fromISO(value.timestamp);
            let label: string;
            if (date.hasSame(DateTime.local(), 'year')) {
                label = 'This year';
            } else {
                label = date.toFormat('yyyy');
            }

            lifetimeTiles.push({
                label,
                value: value.value,
                change,
            });
        }
    }

    return (
        <>
            <ListView title='Last 6 months' view='grid'>
                {recentTiles.map(renderTile)}
                {recentTiles.length === 0 && (
                    <>
                        {/* This keeps the size consistent with its normal size */}
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        <div className='col-span-4 grid place-items-center'>
                            <div className=''>
                                <span>No load history available</span>
                            </div>
                        </div>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    </>
                )}
            </ListView>
            <ListView title='Lifetime' view='grid'>
                {lifetimeTiles.map(renderTile)}
                {lifetimeTiles.length === 0 && (
                    <>
                        {/* This keeps the size consistent with its normal size */}
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                        <div className='col-span-4 grid place-items-center'>
                            <div className=''>
                                <span>No load history available</span>
                            </div>
                        </div>
                        <div className='invisible' style={{ aspectRatio: '1/1' }}></div>
                    </>
                )}
            </ListView>
        </>
    );
};

function renderTile(tile: Tile, index: number) {
    const props: ListTileProps = {
        label: tile.label,
        primaryValue: null,
        primaryValueUnit: 'W',
        secondaryValueTooltip: 'Change from previous timeframe',
    };

    if (tile.value != null) {
        if (tile.value >= 1000) {
            props.primaryValue = round(tile.value / 1000, 1);
            props.primaryValueUnit = 'kW';
        } else {
            props.primaryValue = Math.round(tile.value);
        }
    }

    if (tile.change != null) {
        props.secondaryValue = tile.change;
        props.secondaryValueUnit = '%';

        if (tile.change > 30 || tile.change < -30) {
            props.error = true;
            props.statusIcon = <CircleAlertIcon />;
            props.statusIconTooltip = 'Load significantly changed';
        }
    } else {
        props.secondaryValueUnit = ' ';
    }

    return <ListTile key={index} {...props} />;
}

export const LoadLifetimeQuery = graphql`
    query LoadHistoryTilesQuery(
        $deviceId: ID!
        $recentStart: Timestamp!
        $recentEnd: Timestamp!
        $lifetimeStart: Timestamp!
        $lifetimeEnd: Timestamp!
    ) {
        device(id: $deviceId) {
            load {
                metrics {
                    recentPower: power(begin: $recentStart, end: $recentEnd, calendarInterval: Month) {
                        values {
                            timestamp
                            value
                        }
                    }
                    lifetimePower: power(begin: $lifetimeStart, end: $lifetimeEnd, calendarInterval: Year) {
                        values {
                            timestamp
                            value
                        }
                    }
                }
            }
        }
    }
`;
