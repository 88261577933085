import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useFragment } from 'react-relay';

import {
    AmmeterIcon,
    BarDataType,
    ListIcon,
    ListView,
    Menu,
    MetricDisplay,
    RectifierIcon,
    StackedHorizontalBar,
    ThermometerIcon,
    Tooltip,
    VoltmeterIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import humanizeDuration, { Unit } from 'humanize-duration';
import { useCurrentUserUnitsPref, useUserPermissions } from 'lib/auth';
import { MenuItemGroup } from 'lib/menu';
import { numberToLocaleString, wattToKillowattString } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { formatValueWithUnit } from 'lib/units';
import { Duration } from 'luxon';

import { makeLinkToMetric } from '../../../../explore/lib/link';
import { Operation } from '../../../../explore/types';
import { DeviceHealth, DeviceRectifiers_device$key } from './__generated__/DeviceRectifiers_device.graphql';
import { RectifierModuleDisplay } from './components/RectifierModuleDisplay';

export enum Mode {
    Tiles = 'tiles',
    List = 'list',
}

export interface DeviceRectifiersProps {
    device: DeviceRectifiers_device$key;
    deviceId: string;
    timeRange: Duration;
}

export const DeviceRectifiers: FC<DeviceRectifiersProps> = ({ device, deviceId, timeRange }) => {
    const { hasAssetsWrite, hasAssetsRead } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(Fragment, device);
    const [displayMode, setDisplayMode] = useState(Mode.Tiles);
    const unitPreferences = useCurrentUserUnitsPref();

    let durationUnits: Unit[];
    if (timeRange.as('days') >= 1) {
        durationUnits = ['h', 'm'];
    } else {
        durationUnits = ['d', 'h', 'm'];
    }

    const humanizeOpts = { largest: 1, round: true, units: durationUnits };

    const uptime = data.rectifier?.uptime?.percentage ? Math.round(data.rectifier.uptime.percentage) : 0;

    const uptimeBarData: BarDataType[] = [];

    if (uptime > 0) {
        uptimeBarData.push({
            label: 'Online',
            value: uptime,
            bgClass: 'bg-eggplantRegular',
        });
    }

    if (uptime < 100) {
        uptimeBarData.push({
            label: 'Offline',
            value: 100 - uptime,
            bgClass: 'bg-coralRegular',
        });
    }

    let voltageMetric: string;
    let currentMetric: string;
    let temperatureMetric: string;

    if (data.rectifier?.metrics?.latestOutputVoltage && data.health !== 'Offline') {
        voltageMetric = `${numberToLocaleString(data.rectifier.metrics.latestOutputVoltage)}V`;
    } else {
        voltageMetric = '-V';
    }

    if (data.rectifier?.metrics?.latestOutputCurrent && data.health !== 'Offline') {
        currentMetric = `${numberToLocaleString(data.rectifier.metrics.latestOutputCurrent)}A`;
    } else {
        currentMetric = '-A';
    }

    if (data.rectifier?.metrics?.latestTemperature && data.health !== 'Offline') {
        temperatureMetric = formatValueWithUnit(
            numberToLocaleString(data.rectifier.metrics.latestTemperature),
            unitPreferences.temperature
        );
    } else {
        temperatureMetric = formatValueWithUnit('-', unitPreferences.temperature);
    }

    const capacityBarData: BarDataType[] = [];

    if (data.health !== 'Offline') {
        if (data.rectifier?.usedCapacity) {
            capacityBarData.push({
                label: 'Used capacity',
                value: data.rectifier.usedCapacity,
                bgClass: 'bg-eggplantRegular',
            });
        }

        if (data.rectifier?.freeCapacity) {
            capacityBarData.push({
                label: 'Free capacity',
                value: data.rectifier.freeCapacity,
                bgClass: 'bg-pineRegular',
            });
        }

        if (data.rectifier?.offlineCapacity) {
            capacityBarData.push({
                label: 'Offline capacity',
                value: data.rectifier.offlineCapacity,
                bgClass: 'bg-coralRegular',
            });
        }
    } else {
        capacityBarData.push({
            label: 'Offline',
            value: 1,
            bgClass: 'bg-coralRegular',
        });
    }

    const modules = data.rectifier?.modules?.data ?? [];

    const metricFrameActions = [];

    if (modules.length > 0) {
        metricFrameActions.push({
            buttonIcon: displayMode === Mode.Tiles ? <RectifierIcon /> : <ListIcon />,
            buttonText: displayMode === Mode.Tiles ? 'Tiles' : 'List',
            onClick: () => setDisplayMode(displayMode === Mode.Tiles ? Mode.List : Mode.Tiles),
        });
    }

    useEffect(() => {
        // if we have no modules, switch to list view so that the "Module information is unavailable" is centered
        if (modules.length === 0) {
            setDisplayMode(Mode.List);
        }
    }, [modules.length]);

    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-bold text-xl'>Rectifiers</div>
                <div className='flex flex-col justify-start items-end flex-grow font-normal text-base'>
                    {getRectifierStatus(
                        data.rectifier?.totalCapacity,
                        data.rectifier?.freeCapacity,
                        data.rectifier?.capacityAtRiskWithHeadroom,
                        data.health
                    )}
                </div>
                <div>
                    <Menu
                        id={`device-menu-${deviceId}`}
                        groups={[{ key: MenuItemGroup.Assets, title: MenuItemGroup.Assets }]}
                        menuItems={[
                            {
                                name: hasAssetsWrite ? 'Edit device' : 'View device',
                                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                                disabled: !hasAssetsRead,
                                group: MenuItemGroup.Assets,
                            },
                        ]}
                        variant='small'
                    />
                </div>
            </div>
            <StackedHorizontalBar
                data={uptimeBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }

                    return humanizeDuration(timeRange.as('milliseconds') * (value / 100), humanizeOpts);
                }}
            />

            <div className='flex flex-row justify-start gap-4 mb-4'>
                <IconWithStatus
                    title='Output Voltage'
                    icon={<VoltmeterIcon />}
                    label={voltageMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'RectifierVoltage', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Output Current'
                    icon={<AmmeterIcon />}
                    label={currentMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'RectifierCurrent', op: Operation.Average })}
                />
                <IconWithStatus
                    title='Average Temperature'
                    icon={<ThermometerIcon />}
                    label={temperatureMetric}
                    link={makeLinkToMetric(deviceId, { metric: 'RectifierTemperature', op: Operation.Average })}
                />
            </div>

            <ListView
                title='Modules'
                view={displayMode === Mode.Tiles ? 'grid' : 'list'}
                contentClassName='h-96'
                scroll
                actions={metricFrameActions}
                subtitle={getFrameSubtitle(data.rectifier?.totalCapacity)}
            >
                <RectifierModuleDisplay
                    modules={modules}
                    deviceId={deviceId}
                    displayMode={displayMode}
                    deviceOffline={data.health === 'Offline'}
                />
            </ListView>

            <StackedHorizontalBar
                data={capacityBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }

                    return wattToKillowattString(value);
                }}
            />

            <div className='flex flex-row justify-between' data-testid='capacity-metric-display'>
                <MetricDisplay
                    label='Total capacity'
                    value={wattToKillowattString(data.rectifier?.totalCapacity)}
                    size='small'
                />
                <MetricDisplay
                    label='Used capacity'
                    value={data.health === 'Offline' ? '-W' : wattToKillowattString(data.rectifier?.usedCapacity)}
                    size='small'
                />
                <MetricDisplay
                    label='Free capacity'
                    value={data.health === 'Offline' ? '-W' : wattToKillowattString(data.rectifier?.freeCapacity)}
                    size='small'
                />
                <MetricDisplay
                    label='Offline capacity'
                    value={data.health === 'Offline' ? '-W' : wattToKillowattString(data.rectifier?.offlineCapacity)}
                    size='small'
                    valueError={data.rectifier?.offlineCapacity && data.rectifier.offlineCapacity > 0 ? true : false}
                />
            </div>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceRectifiers_device on Device @argumentDefinitions(unitTemperature: { type: "UnitTemperature" }) {
        health
        rectifier {
            uptime(from: $begin, to: $end) {
                percentage
            }
            metrics {
                latestTemperature(unit: $unitTemperature)
                latestOutputVoltage
                latestOutputCurrent
            }
            totalCapacity(unit: Watt)
            freeCapacity(unit: Watt)
            usedCapacity(unit: Watt)
            offlineCapacity(unit: Watt)
            capacityAtRiskWithHeadroom
            modules {
                data {
                    id
                    ...ModuleTile_data @arguments(unitTemperature: $unitTemperature)
                    ...ModuleRow_data @arguments(unitTemperature: $unitTemperature)
                }
            }
        }
    }
`;

function getRectifierStatus(
    totalCapacity: number | undefined | null,
    freeCapacity: number | undefined | null,
    capacityAtRiskWithHeadroom: boolean | undefined | null,
    health: DeviceHealth | null
): ReactNode {
    if (!health || health === 'Offline') {
        return <div className='text-coralRegular'>Device offline</div>;
    }

    if (totalCapacity == null) {
        return <div className='text-eggplantRegular'>No data</div>;
    }

    const freeCapacityPercent = Math.round(((freeCapacity ?? 0) / totalCapacity) * 100);

    const textContent = `${freeCapacityPercent}% free capacity`;

    if (capacityAtRiskWithHeadroom) {
        return (
            <Tooltip
                className='text-center'
                content='There may not be enough headroom to reliably recharge the batteries'
            >
                <div className='text-coralRegular'>{textContent}</div>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip content='Batteries should recharge reliably'>
                <div className='text-pineRegular'>{textContent}</div>
            </Tooltip>
        );
    }
}

function getFrameSubtitle(totalCapacity: number | undefined | null): string {
    if (totalCapacity) {
        return `${wattToKillowattString(totalCapacity)} total capacity`;
    }
    return '';
}
