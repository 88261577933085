import React, { FC, useEffect } from 'react';
import { PreloadedQuery, fetchQuery, loadQuery, usePreloadedQuery } from 'react-relay';

import { PageHeading } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { UserUnitPreferences, getCurrentUserUnitsPref, useCurrentUserUnitsPref } from 'lib/auth';
import { getGlobalEnvironment } from 'lib/environment';
import { formatSiteName } from 'lib/textFormatters';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';
import { ChartTickCount } from 'views/tasks/battery-health/settings';

import { GeneratorRunReportCard } from './GeneratorRunReportCard';
import { GeneratorRunReportContentQuery } from './__generated__/GeneratorRunReportContentQuery.graphql';
import { PreviousRuns } from './components';

// Note this is a non breaking space
const Blank = ' ';

export interface GeneratorRunReportContentProps {
    queryRef: PreloadedQuery<GeneratorRunReportContentQuery>;
}

export const GeneratorRunReportContent: FC<GeneratorRunReportContentProps> = ({ queryRef }) => {
    const data = usePreloadedQuery(ContentQuery, queryRef);
    const unitPreferences = useCurrentUserUnitsPref();

    let title: string;
    let subtitle: string | undefined;

    useLiveData(data, data.generatorRunReport?.id ?? '', unitPreferences);

    if (data.generatorRunReport) {
        title = data.generatorRunReport.generator.name;
        subtitle = data.generatorRunReport.generator.type.displayName;
    } else {
        title = 'Generator Run Report';
    }

    if (!data || !data.generatorRunReport) {
        return <ErrorNotFound />;
    }

    let siteLocation: string;
    if (data.generatorRunReport.generator.site.address.address) {
        siteLocation = formatSiteName(
            data.generatorRunReport.generator.site.address.address,
            data.generatorRunReport.generator.site.address.state
        );
    } else {
        siteLocation = data.generatorRunReport.generator.site.address.state;
    }

    return (
        <div className='space-y-6 mb-32'>
            <div className='flex flex-row items-baseline'>
                <PageHeading value={title} />
                <div className='font-light text-sm pl-2'>{subtitle}</div>
            </div>
            <div>
                <PageHeading value={data.generatorRunReport.generator.site.name ?? Blank} secondary subheading />
                <div className='font-light text-sm'>
                    <span className='text-coralRegular'>Site Location: </span>
                    <span>{siteLocation}</span>
                </div>
            </div>

            <GeneratorRunReportCard runReport={data.generatorRunReport} />

            <PreviousRuns runReport={data.generatorRunReport} />
        </div>
    );
};

function useLiveData(
    data: GeneratorRunReportContentQuery['response'],
    taskId: string,
    unitPreferences: UserUnitPreferences
) {
    const environment = getGlobalEnvironment();
    const runReport = data.generatorRunReport;

    const runReportState = runReport?.state;

    let shouldBeLive = false;
    if (runReportState !== 'Completed' && runReportState !== 'Error') {
        shouldBeLive = true;
    }

    useEffect(() => {
        if (!shouldBeLive) {
            return;
        }

        const observable = fetchQuery<GeneratorRunReportContentQuery>(
            environment,
            ContentQuery,
            {
                id: taskId,
                chartPoints: ChartTickCount,
                unitVolume: unitPreferences.volume,
                unitTemperature: unitPreferences.temperature,
            },
            { fetchPolicy: 'network-only' }
        );

        const subscription = observable.poll(5000).subscribe({});

        return () => {
            subscription.unsubscribe();
        };
    }, [environment, shouldBeLive, taskId, unitPreferences.temperature, unitPreferences.volume]);
}

export const ContentQuery = graphql`
    query GeneratorRunReportContentQuery(
        $id: ID!
        $chartPoints: Int
        $unitVolume: UnitVolume!
        $unitTemperature: UnitTemperature
        $unitPressure: UnitPressure
        $unitFlowRate: UnitFlowRate
    ) {
        generatorRunReport(id: $id) {
            id
            state

            generator {
                id
                name
                type {
                    displayName
                }
                site {
                    id
                    name
                    address {
                        address
                        state
                    }
                }
            }

            ...GeneratorRunReportCard_data
                @arguments(
                    chartPoints: $chartPoints
                    unitVolume: $unitVolume
                    unitTemperature: $unitTemperature
                    unitPressure: $unitPressure
                    unitFlowRate: $unitFlowRate
                )
            ...PreviousRuns_data
        }
    }
`;

export async function loadGeneratorRunReportPageData(id: string) {
    const unitPreferences = await getCurrentUserUnitsPref();

    return loadQuery(
        getGlobalEnvironment(),
        ContentQuery,
        {
            id,
            chartPoints: ChartTickCount,
            unitVolume: unitPreferences.volume,
            unitTemperature: unitPreferences.temperature,
            unitPressure: unitPreferences.pressure,
            unitFlowRate: unitPreferences.volumetricFlowRate,
        },
        {
            fetchPolicy: 'store-and-network',
        }
    );
}
