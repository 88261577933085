import React, { FC } from 'react';

import { Button } from '@accesstel/pcm-ui';

import classNames from 'classnames';
import { SkeletonPadding, SkeletonText } from 'components/Skeleton';
import { SkeletonChart } from 'components/Skeleton/SkeletonChart';

import { MetricsViewSkeleton } from './components/MetricsViewSkeleton';
import { TestParameterViewSkeleton } from './components/TestParameterViewSkeleton';
import style from './style.module.css';

export const ViewBatteryTestResultSkeleton: FC = () => {
    return (
        <div className='space-y-6 mb-32 text-eggplantRegular'>
            <div className='space-y-6 mb-32 text-eggplantRegular'>
                <SkeletonText characters={30} size='heading' />
                <div>
                    <SkeletonText characters={20} size='subheading' />
                    <SkeletonText characters={18} className='font-light text-sm' />
                </div>
                <div>
                    <div className={style.placeholder__tabs}>
                        <div className={style.placeholder__tabs__tab}></div>
                    </div>

                    <div className={classNames('bg-white p-8 space-y-8', style.main_area)}>
                        <SkeletonText characters={20} className='font-bold text-2xl' />
                        <TestParameterViewSkeleton />
                        <SkeletonPadding spacing={38} />
                        <MetricsViewSkeleton />
                        <SkeletonChart stretch aspectRatio='9 / 2' />
                        <SkeletonChart stretch aspectRatio='9 / 2' />
                        <SkeletonChart stretch aspectRatio='9 / 2' />
                        <SkeletonChart stretch aspectRatio='9 / 2' />
                    </div>
                </div>
                <div>
                    <SkeletonText characters={20} className='font-bold' />
                    <div className='grid grid-cols-3 gap-4'>
                        <div className='bg-white' style={{ height: 180 }} />
                        <div className='bg-white' style={{ height: 180 }} />
                        <div className='bg-white' style={{ height: 180 }} />
                    </div>
                </div>
                <div className='flex gap-4'>
                    <Button variant='primary' size='small' buttonText='View Full Battery Report' disabled />
                </div>
            </div>
        </div>
    );
};
