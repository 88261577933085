import React, { FC, useCallback, useRef, useState } from 'react';
import { useLazyLoadQuery, useMutation, useRelayEnvironment } from 'react-relay';

import { Button, FormikCheckbox, FormikDateSelect, ProgressBar, useToast } from '@accesstel/pcm-ui';

import { Form, Formik, FormikHelpers } from 'formik';
import { DateTime } from 'luxon';
import { Exporter } from 'views/reports/batteries/site-view/device-section/metrics-exporter';
import * as Yup from 'yup';

import {
    ExportJobProblem,
    mutations_SubmitExportMetricsJobMutation,
} from '../__generated__/mutations_SubmitExportMetricsJobMutation.graphql';
import { queries_GetExportFormAvailableMetricsQuery } from '../__generated__/queries_GetExportFormAvailableMetricsQuery.graphql';
import { submitExportMetricsJob } from '../mutations';
import { getExportFormAvailableMetrics } from '../queries';
import style from '../style.module.css';
import { Metrics } from './Metrics';

const ValidationSchema = Yup.object({
    startDate: Yup.date()
        .defined()
        .max(Yup.ref('endDate'), 'Start date must be earlier than end date')
        .required('Start Date Required'),
    endDate: Yup.date()
        .defined()
        .min(Yup.ref('startDate'), 'End date must be later than start date')
        .required('End Date Required'),
    metrics: Yup.array().of(Yup.string().defined()).defined().min(1, 'At least one metric is required').required(),
    splitFile: Yup.bool().defined().required(),
});

type FormFields = Yup.InferType<typeof ValidationSchema>;

const PRESET_DURATION = 7;

export interface ExportFormProps {
    /**
     * Not passing any deviceIds will export metrics for all active devices
     */
    deviceIds?: string[];
    disabled?: boolean;
    minDate?: Date;
    /**
     * Will auto-check all the metrics that are passed in.
     * Warning will be shown if any of the metrics are not within `availableMetrics`
     */
    basicMetrics?: string[];
    /**
     * Number of days to preset for the export. Default to `7` (PRESET_DURATION)
     * ONLY accepts positive integer
     * Example: preset `30` will set the duration of the export to the last 30 days
     */
    presetDuration?: number;
}

export const ExportForm: FC<ExportFormProps> = ({
    deviceIds,
    disabled = false,
    minDate,
    basicMetrics,
    presetDuration = PRESET_DURATION,
}) => {
    const { show } = useToast();
    const [warning, setWarning] = useState<string | undefined>(undefined);
    const [downloadLink, setDownloadLink] = useState<string | undefined>(undefined);
    const [progress, setProgress] = useState<number | undefined>(undefined);
    const watcher = useRef<Exporter | null>(null);
    const environment = useRelayEnvironment();
    const data = useLazyLoadQuery<queries_GetExportFormAvailableMetricsQuery>(getExportFormAvailableMetrics, {});
    const [submitExportJob] = useMutation<mutations_SubmitExportMetricsJobMutation>(submitExportMetricsJob);

    const availableMetrics = data ? data.metricsForExport : [];

    const allBasicMetricsFound = basicMetrics
        ? basicMetrics.every(basicMetric => availableMetrics.map(m => m.metric).includes(basicMetric))
        : true;

    console.assert(
        allBasicMetricsFound,
        'Some of the metrics passed in `basicMetrics` is not in the `availableMetrics`. These metrics will be excluded'
    );

    const startWatch = useCallback(
        (jobId: string, helpers: FormikHelpers<FormFields>) => {
            const { setSubmitting, resetForm } = helpers;
            const onSuccess = () => {
                setSubmitting(false);
                setProgress(undefined);
                resetForm();
            };

            const onFail = () => {
                setProgress(undefined);
                setSubmitting(false);
            };

            watcher.current = new Exporter(jobId, show, environment);
            watcher.current.begin(setDownloadLink, setProgress, onSuccess, onFail);
        },
        [environment, show]
    );

    const handleSubmit = (values: FormFields, helpers: FormikHelpers<FormFields>) => {
        const { setFieldError, setSubmitting } = helpers;

        setSubmitting(true);
        setWarning(undefined);
        setDownloadLink(undefined);
        submitExportJob({
            variables: {
                deviceIds,
                metrics: values.metrics,
                singleFile: !values.splitFile,
                range: {
                    min: DateTime.fromJSDate(values.startDate).startOf('day').toISO(),
                    max: DateTime.fromJSDate(values.endDate).endOf('day').toISO(),
                },
            },
            onCompleted({ exportMetrics }) {
                if (exportMetrics?.id) {
                    startWatch(exportMetrics.id, helpers);
                    return;
                }

                if (exportMetrics?.problems) {
                    setSubmitting(false);
                    const problems = [...exportMetrics.problems];
                    show({
                        text: 'Export metrics failed',
                        variant: 'error',
                    });
                    handleExportErrors(problems, setFieldError);
                    if (problems.includes('RequestTooLarge')) {
                        // handle errors that doesnt fit to Formik fields
                        deviceIds && deviceIds.length > 1
                            ? setWarning('Request too large. Try reducing the date range or the number of device')
                            : setWarning('Request too large. Try reducing the date range');
                    }
                }
            },
            onError() {
                setSubmitting(false);
                show({
                    text: 'Something went wrong',
                    variant: 'error',
                });
            },
        });
    };

    const initialValues: FormFields = {
        startDate: DateTime.now()
            .minus({ day: presetDuration && presetDuration > 0 ? presetDuration : PRESET_DURATION }) // ensure no negative value being passed in
            .toJSDate(),
        endDate: DateTime.now().toJSDate(),
        metrics: basicMetrics ? availableMetrics.filter(m => basicMetrics.includes(m.metric)).map(m => m.metric) : [],
        splitFile: false,
    };

    return (
        <div className={style.export_form}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={ValidationSchema}>
                {({ values, isSubmitting }) => (
                    <Form>
                        <Metrics availableMetrics={availableMetrics} name='metrics' />
                        <div className='flex flex-col'>
                            <div className={style.date_container} data-testid='date-range-section'>
                                <FormikDateSelect
                                    variant='outlined'
                                    name='startDate'
                                    placeHolder='Date Start'
                                    maxDate={values.endDate}
                                    minDate={minDate}
                                    revertText={
                                        // check if the end date is using todays date and update the message
                                        compareDates(values.endDate, new Date()) ? 'Set to today' : 'Set to end date'
                                    }
                                    light
                                    required
                                />
                                <FormikDateSelect
                                    variant='outlined'
                                    name='endDate'
                                    placeHolder='Date End'
                                    maxDate={new Date()}
                                    minDate={values.startDate}
                                    revertText='Set to today'
                                    light
                                    required
                                />
                            </div>
                            <div data-testid='split-file-section'>
                                <FormikCheckbox name='splitFile' placeHolder='Split file per device' variant='white' />
                            </div>
                            <div className={style.warning}>{warning && <span>{warning}</span>}</div>
                        </div>
                        <div className={style.footer}>
                            <Button
                                type='submit'
                                buttonText='Export Data'
                                disabled={disabled}
                                processing={isSubmitting}
                            />
                            {progress !== undefined && downloadLink === undefined && (
                                <div className={style.progress_bar}>
                                    <ProgressBar
                                        id='export-job-progress'
                                        percent={progress}
                                        label='Export Progress Bar'
                                        light={false}
                                    />
                                </div>
                            )}
                            {downloadLink && (
                                <a className={style.download_link} href={downloadLink} download>
                                    Download didn't start? Click here
                                </a>
                            )}
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

const handleExportErrors = (
    errors: ExportJobProblem[],
    setFieldError: (field: string, message: string | undefined) => void
) => {
    errors.forEach(err => {
        switch (err) {
            case 'InvalidRange':
                setFieldError('startDate', 'Invalid range');
                setFieldError('endDate', 'Invalid range');
                break;
            case 'MissingMetrics':
                setFieldError('metrics', 'At least one metric is required');
                break;
            case 'InvalidMetrics':
                setFieldError('metrics', 'Invalid metrics');
                break;
            case 'RequestTooLarge':
            case 'InvalidId':
            case 'TestNotComplete':
            case 'InvalidDevice':
            case 'MissingDevices':
            default:
                break;
        }
    });
};

function compareDates(d1: Date, d2: Date): boolean {
    const luxonD1 = DateTime.fromJSDate(d1);
    const luxonD2 = DateTime.fromJSDate(d2);
    // check if the two dates are on the same day, ignoring time
    if (luxonD1.hasSame(luxonD2, 'day')) {
        return true;
    }
    return false;
}
