import React from 'react';
import { RouteObject } from 'react-router-dom';

import { RouteExtraData } from '../../lib/route-helpers';
import { SiteList } from './SiteList';
import SiteViewRoutes from './view/route';

const Routes: RouteObject[] = [
    {
        path: 'sites',
        handle: {
            title: 'Sites',
        },
        children: [
            {
                index: true,
                element: <SiteList />,
                handle: {
                    title: 'Sites',
                    fullWidth: true,
                } as RouteExtraData,
            },
            ...SiteViewRoutes,
        ],
    },
];

export default Routes;
