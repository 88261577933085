import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { PersonalSettings } from './personal-settings/PersonalSettings';
import { loadPersonalSettingsContent } from './personal-settings/PersonalSettingsContent';

const Routes: RouteObject[] = [
    {
        path: 'account',
        handle: {
            title: 'Account',
        },
        children: [
            {
                index: true,
                element: <Navigate to='settings' replace />,
            },
            {
                path: 'settings',
                handle: {
                    title: 'Personal settings',
                },
                element: <PersonalSettings />,
                loader: loadPersonalSettingsContent,
            },
        ],
    },
];

export default Routes;
