import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useFragment } from 'react-relay';

import {
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    CircleAlertIcon,
    ClockIcon,
    ListRow,
    Tooltip,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import classNames from 'classnames';
import humanizeDuration from 'humanize-duration';
import { numberToLocaleString } from 'lib/numberFormatters';
import { DateTime, Duration } from 'luxon';

import { DeviceIncidentRow_event$key } from './__generated__/DeviceIncidentRow_event.graphql';

export interface DeviceIncidentRowProps {
    event: DeviceIncidentRow_event$key;
}

export const DeviceIncidentRow: FC<DeviceIncidentRowProps> = ({ event }) => {
    const eventData = useFragment(Fragment, event);

    let icon: ReactNode;
    let description: string;
    let color: string;

    switch (eventData.worstStatus) {
        default:
        case 'Outage':
            icon = <CircleAlertIcon />;
            description = 'Outage';
            color = 'text-coralRegular';
            break;
        case 'UnderVoltage':
            icon = <ArrowDownCircleIcon />;
            description = 'Undervoltage';
            color = 'text-eggplantLight';
            break;
        case 'OverVoltage':
            icon = <ArrowUpCircleIcon />;
            description = 'Overvoltage';
            color = 'text-mustardRegular';
            break;
    }

    if (eventData.affectedFeeds.length === 1) {
        description += ` on feed ${eventData.affectedFeeds[0].label}`;
    } else if (eventData.affectedAllFeeds) {
        description += ' across all feeds';
    } else {
        description += ' on multiple feeds';
    }

    const [cachedEndTime, setCachedEndTime] = useState<Date>(new Date());

    // Update the time every second so the time since event refreshes
    useEffect(() => {
        const interval = setInterval(() => {
            setCachedEndTime(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const startTime = DateTime.fromISO(eventData.startTime);

    const startTimeTooltip = `Started at ${startTime.toLocaleString(DateTime.DATETIME_MED)}`;

    const timeSinceEvent = DateTime.fromJSDate(cachedEndTime).diff(startTime);
    const timeSinceEventString = humanizeDuration(timeSinceEvent.as('milliseconds'), {
        largest: 1,
        round: true,
    });

    const eventDuration = Duration.fromISO(eventData.duration);
    const durationString = humanizeDuration(eventDuration.as('milliseconds'), {
        largest: 1,
        round: true,
    });

    const durationTooltip = `Lasted for ${durationString}`;

    return (
        <ListRow
            content={
                <>
                    <div className={classNames('w-5 h-5 mr-1 self-center', color)}>{icon}</div>
                    <div className='flex-grow'>
                        <div className='flex justify-between'>
                            <div className='font-bold text-xl truncate'>{description}</div>
                            <Tooltip content={startTimeTooltip}>
                                <div>{timeSinceEventString} ago</div>
                            </Tooltip>
                        </div>
                        <div className='flex justify-between'>
                            <div>
                                {numberToLocaleString(eventData.voltage ?? 0, 0)}V{' '}
                                <span className='font-light text-xs'>measured at start of incident</span>
                            </div>

                            <Tooltip content={durationTooltip}>
                                <div className='flex flex-row gap-1 items-center'>
                                    <div className='w-4 h-4'>
                                        <ClockIcon />
                                    </div>
                                    <div>{durationString}</div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </>
            }
        />
    );
};

const Fragment = graphql`
    fragment DeviceIncidentRow_event on ACPowerEvent {
        startTime
        duration
        worstStatus
        voltage
        affectedAllFeeds
        affectedFeeds {
            id
            label
        }
    }
`;
