import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ClockIcon, DropletIcon, History, HistoryIconColor, HistoryItem, PlayIcon, StopIcon } from '@accesstel/pcm-ui';

import { captureMessage } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { useCurrentUserUnitsPref } from 'lib/auth';
import { numberToLocaleString } from 'lib/numberFormatters';
import { formatValueWithUnit } from 'lib/units';
import { DateTime, Duration } from 'luxon';

import { UnitVolume } from '../__generated__/GeneratorRunReportContentQuery.graphql';
import { Events_data$data, Events_data$key, GeneratorRunReportEventType } from './__generated__/Events_data.graphql';

export interface EventsProps {
    runReport: Events_data$key;
}

export const Events: FC<EventsProps> = ({ runReport }) => {
    const data = useFragment<Events_data$key>(EventsFragment, runReport);
    const unitPreferences = useCurrentUserUnitsPref();
    const sortedEvents = [...data.events].sort((a, b) => {
        return DateTime.fromISO(a.timestamp).toMillis() - DateTime.fromISO(b.timestamp).toMillis();
    });

    const runningOnLoadStart = sortedEvents.find(
        event => event.type === 'StateChange' && event.state === 'RunningOnLoad'
    )?.timestamp;

    let warmUpTime: Duration | undefined;
    if (runningOnLoadStart) {
        warmUpTime = DateTime.fromISO(runningOnLoadStart).diff(DateTime.fromISO(data.generatorStartTime));
    }

    const coolingDownStart = sortedEvents.find(event => event.type === 'StateChange' && event.state === 'CoolingDown')
        ?.timestamp;

    let cooldownTime: Duration | undefined;
    if (data.generatorStopTime && coolingDownStart) {
        cooldownTime = DateTime.fromISO(coolingDownStart).diff(DateTime.fromISO(data.generatorStopTime));
    }

    const historyItems: HistoryItem[] = [];

    historyItems.push({
        icon: <PlayIcon />,
        iconColor: HistoryIconColor.Pine,
        timestamp: DateTime.fromISO(data.generatorStartTime).toJSDate(),
        message: `Finished warming up ${
            warmUpTime ? `(${humanizeDuration(warmUpTime.as('milliseconds'), { round: true })})` : ''
        }`,
        rawMessage: `Finished warming up`,
        user: {
            username: 'username',
        },
    });

    historyItems.push(
        ...sortedEvents.map(event => {
            return {
                icon: event.type === 'Refuel' ? <DropletIcon /> : <ClockIcon />,
                timestamp: DateTime.fromISO(event.timestamp).toJSDate(),
                message: formatMessage(event.type, event, unitPreferences.volume),
                rawMessage: formatMessage(event.type, event, unitPreferences.volume),
                user: {
                    username: 'username',
                },
            };
        })
    );

    if (data.generatorStopTime) {
        const message = `Finished cooling down ${
            cooldownTime ? `(${humanizeDuration(cooldownTime.as('milliseconds'), { round: true })})` : ''
        }`;

        historyItems.push({
            icon: <StopIcon />,
            iconColor: HistoryIconColor.Coral,
            timestamp: DateTime.fromISO(data.generatorStopTime).toJSDate(),
            message: message,
            rawMessage: message,
            user: {
                username: 'username',
            },
        });
    }

    return (
        <div data-testid='events-section'>
            <div className='font-semibold'>Events</div>
            <History itemGroups={[{ items: historyItems }]} lineStyle='long' showTimestamps />
        </div>
    );
};

const EventsFragment = graphql`
    fragment Events_data on GeneratorRunReport @argumentDefinitions(unitVolume: { type: "UnitVolume!" }) {
        generatorStartTime
        generatorStopTime
        totalRunTime

        events {
            timestamp
            type
            ... on GeneratorRunReportRefuelEvent {
                amount(unit: $unitVolume)
                levelBeforePercent
                levelAfterPercent
            }
            ... on GeneratorRunReportStateChangeEvent {
                type
                state
                timestamp
            }
        }
    }
`;

type Event = Events_data$data['events'][number];

function formatMessage(eventType: GeneratorRunReportEventType, event: Event, volumeUnit?: UnitVolume) {
    switch (eventType) {
        case 'Refuel':
            return formatRefuelEvent(event, volumeUnit);
        case 'StateChange':
            return formatStateChangeEvent(event);
        default:
            return `Unknown event type ${eventType}`;
    }
}

function formatRefuelEvent(event: Events_data$data['events'][number], volumeUnit?: UnitVolume) {
    return `Refuelled ${formatValueWithUnit(
        numberToLocaleString(event.amount ?? null),
        volumeUnit
    )} from ${numberToLocaleString(event.levelBeforePercent ?? null, 0)}% to ${numberToLocaleString(
        event.levelAfterPercent ?? null,
        0
    )}%`;
}

function formatStateChangeEvent(event: Event) {
    switch (event.state) {
        case 'WarmingUp':
            return 'Warming up';
        case 'RunningOnLoad':
            return 'Running on load';
        case 'Running':
            return 'Running';
        case 'CoolingDown':
            return 'Cooling down';
        default:
            captureMessage(`Unhandled or Invalid state`, context => {
                context.setTag('State', event.state);

                context.setExtra('Function', 'formatStateChangeEvent');
                context.setExtra(
                    'Description',
                    'This is an unhandled or invalid state to be processed for generator run report events'
                );
                return context;
            });
            return 'Unknown state';
    }
}
