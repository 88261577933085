import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ClockIcon, ListRow, RotateCCWIcon, Tooltip } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import humanizeDuration from 'humanize-duration';
import { numberToLocaleString } from 'lib/numberFormatters';
import { Duration } from 'luxon';

import style from '../style.module.css';
import { StringRow_data$key } from './__generated__/StringRow_data.graphql';

export interface StringRowProps {
    stringRow: StringRow_data$key;
}

export const StringRow: FC<StringRowProps> = ({ stringRow }) => {
    const data = useFragment(Fragment, stringRow);

    let cycles: string;
    if (data.cycles) {
        cycles = `${data.cycles} cycles`;
    } else {
        cycles = '- cycles';
    }

    let capacity: string;
    if (data.type.cells?.capacity) {
        capacity = `${numberToLocaleString(data.type.cells.capacity, 0)}`;
    } else {
        capacity = '-';
    }

    let age;
    if (data.age !== null) {
        age = Duration.fromISO(data.age);
        age = humanizeDuration(age.as('milliseconds'), { largest: 2, round: true, units: ['y', 'mo', 'w', 'd'] });
        age = `${age} old`;
    } else {
        age = 'Unknown';
    }

    const makeAndModel = `${data.type.manufacturer} ${data.type.model}`;

    const contentBody = (
        <div className={style.grid_row}>
            <div className='flex flex-col justify-start items-start leading-none'>
                <Tooltip content={makeAndModel} overflowOnly>
                    <div>
                        <span className='text-xl font-bold truncate'>{makeAndModel}</span>
                    </div>
                </Tooltip>
                <div>
                    <IconWithStatus title='Battery Age' icon={<ClockIcon />} label={age} />
                </div>
            </div>
            <div className='flex flex-col justify-start items-start leading-none'>
                <div>
                    <span className='text-xl font-bold'>{capacity}</span>
                    <span className='text-sm font-normal'> Ah Capacity</span>
                </div>
                <div>
                    <IconWithStatus title='Discharge Cycles' icon={<RotateCCWIcon />} label={cycles} />
                </div>
            </div>
        </div>
    );

    return <ListRow label={data.localId} content={contentBody} />;
};

const Fragment = graphql`
    fragment StringRow_data on DeviceBatteryString {
        id
        localId
        installDate
        age
        cycles
        type {
            manufacturer
            model
            cells {
                capacity
            }
        }
    }
`;
