import React, { FC } from 'react';

import { BatteryTestCard } from 'views/tasks/battery-health/overview/components/BatteryTestCard';

import { BatteryTestResultCard } from '../../../../../tasks/battery-health/overview/components/BatteryTestResultCard';
import { queries_batteryTestCardsQuery$data } from '../../../__generated__/queries_batteryTestCardsQuery.graphql';

interface DeviceBatteryTestsComponentProps {
    props: queries_batteryTestCardsQuery$data;
    device: string;
}

export const DeviceBatteryTestsCardsComponent: FC<DeviceBatteryTestsComponentProps> = ({ props, device }) => {
    return (
        <div className='grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6'>
            {props.device?.tests.data.map(batteryTask => {
                const task = batteryTask.task;
                if (!task) {
                    return <BatteryTestResultCard key={batteryTask.id} test={batteryTask} background='dark' />;
                }
                return (
                    <BatteryTestCard
                        key={batteryTask.id}
                        task={batteryTask.task}
                        background='dark'
                        compact
                        forceStatusDisplay
                    />
                );
            })}
        </div>
    );
};
