import React, { FC } from 'react';

import { BigButton } from '@accesstel/pcm-ui';

import { useDocumentTitle } from 'components';
import { BigButtonLayout } from 'layouts/BigButtonLayout';
import { Paths } from 'lib/routes';

export const Settings: FC = () => {
    useDocumentTitle('Settings');

    return (
        <BigButtonLayout title='Settings'>
            <BigButton
                to={Paths.SettingsConfiguration}
                title='System Configuration'
                text='Manage the system settings'
            />
            <BigButton to={Paths.SettingsUsers} title='Users' text='Manage the users' />
        </BigButtonLayout>
    );
};
