import React, { FC } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useExtendedNavigate } from '@accesstel/pcm-ui';

import { HubCallback } from '@aws-amplify/core/dist/esm/Hub/types';
import { captureException } from '@sentry/react';
import ScrollToTop from 'ScrollToTop';
import { Hub } from 'aws-amplify/utils';
import { ThemedPage } from 'components';
import { useCurrentUser } from 'lib/auth';
import { ErrorUnhandled } from 'views/ErrorPage/ErrorUnhandled';

export const AuthenticatedPageLayout: FC = () => {
    const location = useLocation();
    const currentUser = useCurrentUser();

    const navigate = useExtendedNavigate();

    // Listen for sign out events and redirect the user to the login page on sign out.
    React.useEffect(() => {
        const handler: HubCallback = data => {
            if (data.payload.event === 'signedOut') {
                navigate('/logout');
            }
        };

        const hubListenerCancel = Hub.listen('auth', handler);

        return () => {
            hubListenerCancel();
        };
    }, [navigate]);

    // If the user is not logged in, redirect to the login page.
    if (!currentUser) {
        return (
            <Navigate
                to='/login'
                state={{
                    continue: location.pathname,
                }}
                replace={true}
            />
        );
    }

    return (
        <ThemedPage>
            <ScrollToTop />
            <ErrorBoundary
                FallbackComponent={ErrorUnhandled}
                resetKeys={[location.pathname, location.hash, location.search]}
                onError={(error, info) => {
                    captureException(error, scope =>
                        scope.setContext('Component Stack', { stack: info.componentStack })
                    );
                }}
            >
                <Outlet />
            </ErrorBoundary>
        </ThemedPage>
    );
};
