import React, { FC } from 'react';

import { SkeletonCard, SkeletonPadding, SkeletonText } from 'components/Skeleton';

export const DeviceBatteriesSkeleton: FC = () => {
    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-bold text-xl'>
                    <SkeletonText characters={10} />
                </div>
                <div className='float-right'>
                    <SkeletonText characters={10} />
                    <SkeletonText characters={20} className='text-xs' />
                </div>
            </div>

            <div className='flex flex-col justify-start items-end flex-grow font-bold text-xl'>
                <div>
                    <SkeletonText characters={10} />
                </div>
                <div>
                    <SkeletonText characters={10} />
                </div>
            </div>

            <div className='flex flex-col justify start items-end flex-grow font-light text-xs'>
                <SkeletonText characters={4} size='subheading' />
                <SkeletonText characters={4} size='subheading' />
                <SkeletonText characters={4} size='subheading' />
                <SkeletonText characters={4} size='subheading' />
            </div>

            <SkeletonPadding spacing={38} />

            <SkeletonText characters={10} size='subheading' />
            <SkeletonCard height={384} />
        </div>
    );
};
