import React, { FC } from 'react';
import { fetchQuery, useRelayEnvironment } from 'react-relay';

import graphql from 'babel-plugin-relay/macro';
import { FilterSearchSelect } from 'components/FilterSearchSelect';
import { CommonFilterProps } from 'filters/common';
import { IEnvironment } from 'relay-runtime';

import { GeneratorRunReportSerialNumberFilter } from '../types';
import { TextFilterResult } from './TextFilterResult';
import { GeneratorReportSerialNumberFilterUI_searchQuery } from './__generated__/GeneratorReportSerialNumberFilterUI_searchQuery.graphql';

export type GeneratorReportSerialNumberFilterUIProps = CommonFilterProps<GeneratorRunReportSerialNumberFilter[]>;

export const GeneratorReportSerialNumberFilterUI: FC<GeneratorReportSerialNumberFilterUIProps> = ({
    current,
    onClearOrBack,
    onClose,
    onSubmit,
    hasPrevious,
}) => {
    const environment = useRelayEnvironment();

    return (
        <FilterSearchSelect
            active={current}
            title='Filter by serial'
            placeholder='Enter serial number'
            renderItem={item => <TextFilterResult filter={item} />}
            renderItemSimple={item => item.value}
            showBack={hasPrevious}
            onClear={onClearOrBack}
            onSubmit={onSubmit}
            onSearch={input => doSearch(environment, input, 5)}
            onClose={onClose}
            createDefaultItem={input => {
                if (input.trim().length > 0) {
                    return createWildcardFilter(input);
                } else {
                    return null;
                }
            }}
        />
    );
};

async function doSearch(
    environment: IEnvironment,
    input: string,
    maxResults: number
): Promise<GeneratorRunReportSerialNumberFilter[]> {
    try {
        const results = await fetchQuery<GeneratorReportSerialNumberFilterUI_searchQuery>(
            environment,
            graphql`
                query GeneratorReportSerialNumberFilterUI_searchQuery($input: String!, $maxResults: Int!) {
                    generatorRunReports(filters: { serialNumber: { value: $input } }, pageSize: $maxResults) {
                        data {
                            id
                            generatorInfo {
                                serialNumber
                            }
                        }
                    }
                }
            `,
            {
                input,
                maxResults: maxResults - 1,
            }
        ).toPromise();

        const reports = results?.generatorRunReports.data ?? [];

        const output: GeneratorRunReportSerialNumberFilter[] = [];
        const visitedNames = new Set<string>();
        reports.forEach(report => {
            const name = report.generatorInfo.serialNumber;
            if (!name) {
                return;
            }

            if (visitedNames.has(name)) {
                return;
            }

            visitedNames.add(name);

            const device: GeneratorRunReportSerialNumberFilter = {
                type: 'result',
                value: name,
            };

            output.push(device);
        });

        if (input.trim().length > 0) {
            if (output.length >= maxResults) {
                output[output.length - 1] = createWildcardFilter(input);
            } else {
                output.push(createWildcardFilter(input));
            }
        }

        return output;
    } catch (error) {
        return [];
    }
}

function createWildcardFilter(input: string): GeneratorRunReportSerialNumberFilter {
    return {
        type: 'special',
        value: input.trim(),
        wildcard: true,
    };
}
