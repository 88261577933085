import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import { DualPlaneIcon } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { NameValuePair } from 'components';
import { batteryStatusToString } from 'lib/conversion/battery-status';
import { getFormattedDateFromDate, getHumanizedTextFromDuration } from 'lib/dateFormatter';
import { numberToLocaleStringFixed } from 'lib/numberFormatters';
import { formatBatteryType, getNiceCapability } from 'lib/textFormatters';
import { DateTime } from 'luxon';

import { Details_device$key } from './__generated__/Details_device.graphql';

export interface DetailsProps {
    device: Details_device$key;
}

export const Details: FC<DetailsProps> = ({ device }) => {
    const result = useFragment<Details_device$key>(
        graphql`
            fragment Details_device on Device {
                capabilities
                battery {
                    metrics {
                        latestStatus
                        originalCapacity
                        latestRemainingCapacity
                    }
                    strings {
                        strings {
                            installDate
                            type {
                                model
                                manufacturer
                                technology
                            }
                        }
                    }
                }
                dualPlaneCompanion {
                    device {
                        name
                    }
                }
            }
        `,
        device
    );

    let body: ReactNode;

    if (result) {
        let capacity: string;
        if (result.battery.metrics.latestRemainingCapacity) {
            capacity = numberToLocaleStringFixed(result.battery.metrics.latestRemainingCapacity, 1);
        } else {
            capacity = '-';
        }

        let originalCapacity: string;
        if (result.battery.metrics.originalCapacity) {
            originalCapacity = numberToLocaleStringFixed(result.battery.metrics.originalCapacity, 1);
        } else {
            originalCapacity = '-';
        }

        let status;
        if (result.battery.metrics.latestStatus) {
            status = batteryStatusToString(result.battery.metrics.latestStatus);
        } else {
            status = 'Unavailable';
        }

        // TODO: Support multiple types (details from multiple strings in the UI)
        const hasString = result.battery.strings.strings.length > 0;
        let batteryName: string;
        if (hasString && result.battery.strings.strings[0].type.manufacturer) {
            if (result.battery.strings.strings[0].type.model) {
                batteryName = `${result.battery.strings.strings[0].type.manufacturer} ${result.battery.strings.strings[0].type.model}`;
            } else {
                batteryName = `${result.battery.strings.strings[0].type.manufacturer} Unknown Model`;
            }
        } else {
            if (hasString && result.battery.strings.strings[0].type.model) {
                batteryName = result.battery.strings.strings[0].type.model;
            } else {
                batteryName = 'Unknown';
            }
        }

        const batteryType = formatBatteryType(hasString ? result.battery.strings.strings[0].type.technology : null);
        let installedDate: string;
        if (hasString && result.battery.strings.strings.length > 0 && result.battery.strings.strings[0].installDate) {
            installedDate = getFormattedDateFromDate(result.battery.strings.strings[0].installDate as string, {
                fullMonth: true,
            });

            const durationDiff = DateTime.fromISO(result.battery.strings.strings[0].installDate as string)
                .diff(DateTime.now(), 'milliseconds')
                .as('milliseconds');
            const humanizedDiff = getHumanizedTextFromDuration(durationDiff);

            installedDate = `${installedDate} ${humanizedDiff ? '(' + humanizedDiff + ')' : ''}`;
        } else {
            installedDate = 'Unknown';
        }

        const capabilities: string[] = result.capabilities.map(getNiceCapability);
        if (capabilities.length === 0) {
            capabilities.push('No Additional Capabilities');
        }

        let systemType: ReactNode;
        if (result.dualPlaneCompanion?.device) {
            systemType = (
                <span className='items-center'>
                    <span>Dual Plane</span>

                    <div className='h-4 w-4 ml-1 inline-block align-middle'>
                        <DualPlaneIcon />
                    </div>
                </span>
            );
        } else {
            systemType = 'Single Plane';
        }

        body = (
            <>
                <NameValuePair name='Current Capacity' value={`${capacity} Ah`} />
                <NameValuePair name='Original Capacity' value={`${originalCapacity} Ah`} />
                <NameValuePair name='Status' value={status} />
                <NameValuePair name='Capabilities' value={capabilities.join(', ')} />
                <NameValuePair name='System Type' value={systemType} />
                <NameValuePair name='Battery Type' value={batteryType} />
                <NameValuePair name='Battery' value={batteryName} />
                <NameValuePair name='Battery Installed' value={installedDate} />
            </>
        );
    }

    return <div className='grid grid-cols-2'>{body}</div>;
};
