import React, { FC } from 'react';

import { PageHeading } from '@accesstel/pcm-ui';

import { SkeletonCard, SkeletonText } from 'components/Skeleton';

export const ACPowerOverviewSkeleton: FC = () => {
    return (
        <div className='space-y-6'>
            <div>
                <div className='flex justify-between items-center'>
                    <PageHeading value='AC Power' />
                    <div className='flex'>
                        <div className='w-64 pr-4'>
                            <SkeletonText characters={20} />
                        </div>
                        <SkeletonText characters={20} />
                    </div>
                </div>
            </div>
            <div className='flex justify-end relative'>
                <SkeletonText characters={20} />
            </div>
            <div className='bg-white p-8 grid grid-cols-12 gap-x-4 space-y-6'>
                <div className='col-span-12 mb-6'>
                    <div>
                        <div className='flex flex-row justify-between items-start relative'>
                            <div>
                                <div className='font-normal text-3xl'>
                                    <SkeletonText characters={20} />
                                </div>
                                <div className='font-light'>
                                    <SkeletonText characters={15} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-end font-normal'>
                        <div className='flex items-center space-x-2 text-sm hover:underline'>
                            <SkeletonText characters={8} />
                        </div>
                    </div>
                </div>

                {/* CountsSection */}
                <div className='col-span-6 lg:col-span-3'>
                    <SkeletonText characters={20} size={'normal'} />
                    <SkeletonText characters={20} size={'subheading'} />
                </div>
                <div className='col-span-6 lg:col-span-3'>
                    <SkeletonText characters={20} size={'normal'} />
                    <SkeletonText characters={20} size={'subheading'} />
                </div>
                <div className='col-span-6 lg:col-span-3'>
                    <SkeletonText characters={20} size={'normal'} />
                    <SkeletonText characters={20} size={'subheading'} />
                </div>
                <div className='col-span-6 lg:col-span-3'>
                    <SkeletonText characters={20} size={'normal'} />
                    <SkeletonText characters={20} size={'subheading'} />
                </div>

                {/* LeastReliableSites */}
                <div className='col-span-12 lg:col-span-4'>
                    <SkeletonText characters={10} size='subheading' />
                    <SkeletonCard height='22rem' />
                </div>

                {/* IncidentCountsChart */}
                <div className='col-span-12 lg:col-span-8'>
                    <SkeletonText characters={10} size='subheading' />
                    <SkeletonCard height='22rem' />
                </div>

                {/* ReliabilityChart */}
                <div className='col-span-full'>
                    <SkeletonText characters={10} size='subheading' />
                    <SkeletonCard height='24rem' />
                </div>

                {/* ReliabilitySection */}
                <div className='col-span-6'>
                    <SkeletonText characters={10} size='subheading' />
                    <div className='space-y-4'>
                        <SkeletonCard height='5rem' />
                        <SkeletonCard height='5rem' />
                    </div>
                </div>
                <div className='col-span-6'>
                    <SkeletonText characters={10} size='subheading' />
                    <div className='space-y-4'>
                        <SkeletonCard height='5rem' />
                        <SkeletonCard height='5rem' />
                    </div>
                </div>

                {/* SitesAtRisk */}
                <div className='col-span-full'>
                    <SkeletonText characters={10} size='subheading' />
                    <SkeletonCard height='22rem' />
                </div>
            </div>
        </div>
    );
};
