/**
 * @generated SignedSource<<610ae8c6e2c8edfbf4c335116412766d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type UnplannedBatteryTestDistributionType = "BatteryStringCount" | "CurrentMetrics" | "DischargedMetrics" | "EstimatedCapacity" | "EstimatedReserveTime" | "EstimatedStateOfHealth" | "TemperatureMetrics" | "VoltageMetrics" | "%future added value";
export type TestResultFilterRange_unplannedTestDistributionQuery$variables = {
  type: UnplannedBatteryTestDistributionType;
};
export type TestResultFilterRange_unplannedTestDistributionQuery$data = {
  readonly unplannedBatteryTestDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly key: number;
      readonly value: number;
    }>;
    readonly maximum: number | null;
    readonly minimum: number | null;
  };
};
export type TestResultFilterRange_unplannedTestDistributionQuery = {
  response: TestResultFilterRange_unplannedTestDistributionQuery$data;
  variables: TestResultFilterRange_unplannedTestDistributionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "UnplannedBatteryTestDistribution",
    "kind": "LinkedField",
    "name": "unplannedBatteryTestDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UnplannedBatteryTestDistributionCount",
        "kind": "LinkedField",
        "name": "distribution",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestResultFilterRange_unplannedTestDistributionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestResultFilterRange_unplannedTestDistributionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0132f176cb0b99812c080b8d614da9c9",
    "id": null,
    "metadata": {},
    "name": "TestResultFilterRange_unplannedTestDistributionQuery",
    "operationKind": "query",
    "text": "query TestResultFilterRange_unplannedTestDistributionQuery(\n  $type: UnplannedBatteryTestDistributionType!\n) {\n  unplannedBatteryTestDistribution(type: $type) {\n    distribution {\n      key\n      value\n    }\n    minimum\n    maximum\n  }\n}\n"
  }
};
})();

(node as any).hash = "2cd35944e5362882d74b2e0b2f220183";

export default node;
