import React, { FC } from 'react';

import { SkeletonText } from 'components/Skeleton';
import { SkeletonTable } from 'components/Skeleton';

import { DevicePaneSkeleton } from '../../components/DevicePaneSkeleton';

export const InsightsSkeleton: FC = () => {
    return (
        <DevicePaneSkeleton combinedView>
            <div className='font-bold text-xl'>
                <SkeletonText characters={10} />
            </div>

            <SkeletonTable />
        </DevicePaneSkeleton>
    );
};
