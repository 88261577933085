import { fetchQuery } from 'react-relay';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';

import { breadcrumbBatteryTestResultQuery } from './__generated__/breadcrumbBatteryTestResultQuery.graphql';
import { breadcrumbUsernameQuery } from './__generated__/breadcrumbUsernameQuery.graphql';
import { breadcrumbsBatteryTypeQuery } from './__generated__/breadcrumbsBatteryTypeQuery.graphql';
import { breadcrumbsDeviceQuery } from './__generated__/breadcrumbsDeviceQuery.graphql';
import { breadcrumbsSiteQuery } from './__generated__/breadcrumbsSiteQuery.graphql';
import { getGlobalEnvironment } from './environment';
import { formatSiteName } from './textFormatters';

/**
 *
 * @param id siteId
 * @param withState whether to include the state in the breadcrumb. Default: `false`
 * @returns the site name and state if withState is true. Otherwise, just the site name.
 *
 * @example
 * ```ts
 * const breadcrumb = await getSiteBreadcrumb('siteId', true);
 * // breadcrumb = 'Site Name, State'
 *
 * const breadcrumb = await getSiteBreadcrumb('siteId', false);
 * // breadcrumb = 'Site Name'
 * ```
 */
export async function getSiteBreadcrumb(id: string, withState = false): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<breadcrumbsSiteQuery>(
            environment,
            graphql`
                query breadcrumbsSiteQuery($id: ID!) {
                    site(id: $id) {
                        name
                        address {
                            state
                        }
                    }
                }
            `,
            { id },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        let breadcrumb = 'Unknown';
        if (data?.site) {
            breadcrumb = formatSiteName(data.site.name, data.site.address.state, withState);
        }

        return breadcrumb;
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Site', id);
            scope.setTag('Function', 'getSiteBreadcrumb');
            return scope;
        });
        return 'Unknown';
    }
}

/**
 *
 * @param id deviceId
 * @param withDualPlaneDevice whether to include the dual plane device (if exist) in the breadcrumb. Default: `false`
 * @returns the device name and dual plane device if withDualPlaneDevice is true. Otherwise, just the device name
 *
 * @example
 * ```ts
 * const breadcrumb = await getDeviceBreadcrumb('deviceId', true);
 * // breadcrumb = 'Device Name & Dual Plane Device Name'
 *
 * const breadcrumb = await getDeviceBreadcrumb('deviceId', false);
 * // breadcrumb = 'Device Name'
 * ```
 *
 */
export async function getDeviceBreadcrumb(id: string, withDualPlaneDevice = false): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<breadcrumbsDeviceQuery>(
            environment,
            graphql`
                query breadcrumbsDeviceQuery($id: ID!) {
                    device(id: $id) {
                        name
                        dualPlaneCompanion {
                            device {
                                name
                            }
                        }
                    }
                }
            `,
            { id },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        let title = 'Unknown';
        if (withDualPlaneDevice && data?.device?.dualPlaneCompanion?.device?.name && data?.device?.name) {
            return [data.device.dualPlaneCompanion.device.name, data.device.name].sort().join(' & ');
        } else if (data?.device?.name) {
            title = data.device.name;
        }

        return title;
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Device', id);
            scope.setTag('Function', 'getDeviceBreadcrumb');
            return scope;
        });
        return 'Unknown';
    }
}

export async function getBatteryTypeBreadCrumb(id: string): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<breadcrumbsBatteryTypeQuery>(
            environment,
            graphql`
                query breadcrumbsBatteryTypeQuery($id: ID!) {
                    batteryType(id: $id) {
                        manufacturer
                        model
                    }
                }
            `,
            { id },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        return data?.batteryType ? `${data.batteryType.manufacturer} ${data.batteryType.model}` : 'Unknown';
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Battery Type', id);
            scope.setTag('Function', 'getBatteryTypeBreadCrumb');
            return scope;
        });
        return 'Unknown';
    }
}

/**
 *
 * @param testId id of a test result
 * @returns The task name if the test is a planned test. Otherwise, the device name for unplanned tests.
 *
 * @example ```ts
 * const breadcrumb = await getBatteryTestResultBreadcrumb('unplanned-test-id');
 * // breadcrumb = 'Device Name'
 *
 * const breadcrumb = await getBatteryTestResultBreadcrumb('planned-test-id');
 * // breadcrumb = 'Task Name'
 *
 * const breadcrumb = await getBatteryTestResultBreadcrumb('404-test-id');
 * // breadcrumb = 'Unknown'
 * ```
 */
export async function getBatteryTestResultBreadcrumb(testId: string): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<breadcrumbBatteryTestResultQuery>(
            environment,
            graphql`
                query breadcrumbBatteryTestResultQuery($id: ID!) {
                    batteryTestResult(id: $id) {
                        id
                        device {
                            name
                        }
                        task {
                            name
                        }
                    }
                }
            `,
            { id: testId },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        return data?.batteryTestResult?.task?.name ?? data?.batteryTestResult?.device?.name ?? 'Unknown';
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('Battery Test', testId);
            scope.setTag('Function', 'getExternalBatteryTestBreadcrumb');
            return scope;
        });
        return 'Unknown';
    }
}

/**
 *
 * @param userId id/username of a user
 * @returns The fullname of the user, if the user is found. Otherwise, 'Unknown'
 *
 * @example ```ts
 * const breadcrumb = await getUsernameBreadcrumb('user-id');
 * // breadcrumb = 'User Full Name'
 *
 * const breadcrumb = await getUsernameBreadcrumb('404-user-id');
 * // breadcrumb = 'Unknown'
 * ```
 */
export async function getUsernameBreadcrumb(userId: string): Promise<string> {
    const environment = getGlobalEnvironment();
    try {
        const data = await fetchQuery<breadcrumbUsernameQuery>(
            environment,
            graphql`
                query breadcrumbUsernameQuery($id: String!) {
                    user(username: $id) {
                        name
                    }
                }
            `,
            { id: userId },
            {
                fetchPolicy: 'store-or-network',
            }
        ).toPromise();

        return data?.user?.name ?? 'Unknown';
    } catch (error) {
        captureException(error, scope => {
            scope.setTag('User', userId);
            scope.setTag('Function', 'getUsernameBreadcrumb');
            return scope;
        });
        return 'Unknown';
    }
}
