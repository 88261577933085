/**
 * @generated SignedSource<<3e31a4d5b7c99a22628dfe66bc2c3500>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type DeviceDistributionType = "BatteryCapacityRemaining" | "BatteryCapacityTotal" | "BatteryReserveTime" | "BatteryStateOfHealth" | "BatteryString" | "BatteryTemperature" | "batteryEnergyTotal" | "%future added value";
export type DeviceFilterRange_deviceDistributionQuery$variables = {
  type: DeviceDistributionType;
};
export type DeviceFilterRange_deviceDistributionQuery$data = {
  readonly deviceDistribution: {
    readonly distribution: ReadonlyArray<{
      readonly key: number;
      readonly value: number;
    }>;
    readonly maximum: number | null;
    readonly minimum: number | null;
  };
};
export type DeviceFilterRange_deviceDistributionQuery = {
  response: DeviceFilterRange_deviceDistributionQuery$data;
  variables: DeviceFilterRange_deviceDistributionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "DeviceDistribution",
    "kind": "LinkedField",
    "name": "deviceDistribution",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceDistributionCount",
        "kind": "LinkedField",
        "name": "distribution",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "key",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimum",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "maximum",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeviceFilterRange_deviceDistributionQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeviceFilterRange_deviceDistributionQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "24f4c876605d26ce554fc81b96c56198",
    "id": null,
    "metadata": {},
    "name": "DeviceFilterRange_deviceDistributionQuery",
    "operationKind": "query",
    "text": "query DeviceFilterRange_deviceDistributionQuery(\n  $type: DeviceDistributionType!\n) {\n  deviceDistribution(type: $type) {\n    distribution {\n      key\n      value\n    }\n    minimum\n    maximum\n  }\n}\n"
  }
};
})();

(node as any).hash = "59a757bc2f71a5c6383638b5b277082c";

export default node;
