import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { ReportTile, ReportTileStatus, ReportTileTrend } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { numberToLocaleString } from 'lib/numberFormatters';

import { intervalToHuman } from '../../common';
import { TitleWithSubtitle } from '../../components';
import { TimeRange } from '../../settings';
import { ReliabilitySection$data, ReliabilitySection$key } from './__generated__/ReliabilitySection.graphql';

export interface ReliabilitySectionProps {
    fragmentRef: ReliabilitySection$key;
    interval: TimeRange;
}

export const ReliabilitySection: FC<ReliabilitySectionProps> = ({ fragmentRef, interval }) => {
    const data = useFragment(Fragment, fragmentRef);
    let humanInterval: string | undefined;

    if (interval !== TimeRange.Lifetime) {
        humanInterval = intervalToHuman(interval);
    }

    return (
        <>
            <div className='col-span-full lg:col-span-6' data-testid='20th-percentile'>
                <TitleWithSubtitle title='Reliability of worst 20%' subtitle='' />
                <div className='space-y-4'>
                    <ReportTile
                        title='Mean Time Between Failures'
                        content={getContent(
                            data.reliablitySection20.data.length > 0
                                ? data.reliablitySection20.data[0].acPower.reliability.mtbf.current
                                : null
                        )}
                        description={getMTBFDescription(data.reliablitySection20.data, humanInterval)}
                        status={getMTBFStatus(data.reliablitySection20.data)}
                        trend={getTrend(
                            data.reliablitySection20.data.length > 0
                                ? data.reliablitySection20.data[0].acPower.reliability.mtbf.percentChange
                                : null
                        )}
                    />
                    <ReportTile
                        title='Mean Time To Recovery'
                        content={getContent(
                            data.reliablitySection20.data.length > 0
                                ? data.reliablitySection20.data[0].acPower.reliability.mttr.current
                                : null
                        )}
                        description={getMTTRDescription(data.reliablitySection20.data, humanInterval)}
                        status={getMTTRStatus(data.reliablitySection20.data)}
                        trend={getTrend(
                            data.reliablitySection20.data.length > 0
                                ? data.reliablitySection20.data[0].acPower.reliability.mttr.percentChange
                                : null
                        )}
                    />
                </div>
            </div>

            <div className='col-span-full lg:col-span-6' data-testid='50th-percentile'>
                <TitleWithSubtitle title='Median reliability' subtitle='' />
                <div className='space-y-4'>
                    <ReportTile
                        title='Mean Time Between Failures'
                        content={getContent(
                            data.reliablitySection50.data.length > 0
                                ? data.reliablitySection50.data[0].acPower.reliability.mtbf.current
                                : null
                        )}
                        description={getMTBFDescription(data.reliablitySection50.data, humanInterval)}
                        status={getMTBFStatus(data.reliablitySection50.data)}
                        trend={getTrend(
                            data.reliablitySection50.data.length > 0
                                ? data.reliablitySection50.data[0].acPower.reliability.mtbf.percentChange
                                : null
                        )}
                    />
                    <ReportTile
                        title='Mean Time To Recovery'
                        content={getContent(
                            data.reliablitySection50.data.length > 0
                                ? data.reliablitySection50.data[0].acPower.reliability.mttr.current
                                : null
                        )}
                        description={getMTTRDescription(data.reliablitySection50.data, humanInterval)}
                        status={getMTTRStatus(data.reliablitySection50.data)}
                        trend={getTrend(
                            data.reliablitySection50.data.length > 0
                                ? data.reliablitySection50.data[0].acPower.reliability.mttr.percentChange
                                : null
                        )}
                    />
                </div>
            </div>
        </>
    );
};

const Fragment = graphql`
    fragment ReliabilitySection on Query {
        reliablitySection20: sites(
            acReliabilityTimeRange: $timeRange
            filters: { acReliabilityPercentile: { min: 20, max: 20 } }
        ) {
            data {
                acPower {
                    reliability(timeRange: $timeRange, unit: Seconds) {
                        mtbf {
                            current
                            percentChange
                        }
                        mttr {
                            current
                            percentChange
                        }
                    }
                }
            }
        }

        reliablitySection50: sites(
            acReliabilityTimeRange: $timeRange
            filters: { acReliabilityPercentile: { min: 50, max: 50 } }
        ) {
            data {
                acPower {
                    reliability(timeRange: $timeRange, unit: Seconds) {
                        mtbf {
                            current
                            percentChange
                        }
                        mttr {
                            current
                            percentChange
                        }
                    }
                }
            }
        }
    }
`;

function getContent(current: number | null): string {
    if (current !== null) {
        return humanizeDuration((current ?? 0) * 1000, {
            units: ['y', 'mo', 'd', 'h', 'm'],
            round: true,
            largest: 1,
        });
    } else {
        return 'No incidents';
    }
}

function getMTBFDescription(
    data: ReliabilitySection$data['reliablitySection20']['data'],
    humanInterval: string | undefined
): string {
    if (humanInterval === undefined) {
        return 'For all time';
    }

    if (data.length !== 0 && data[0].acPower.reliability.mtbf.percentChange !== null) {
        const percentage = data[0].acPower.reliability.mtbf.percentChange;
        const percentageDescription = numberToLocaleString(Math.abs(percentage), 0);

        if (percentage > 0) {
            return `Improved ${percentageDescription}% from previous ${humanInterval}`;
        } else if (percentage < 0) {
            return `Worsened ${percentageDescription}% from previous ${humanInterval}`;
        }
    }
    return `Unchanged from previous ${humanInterval}`;
}

function getMTTRDescription(
    data: ReliabilitySection$data['reliablitySection20']['data'],
    humanInterval: string | undefined
) {
    if (humanInterval === undefined) {
        return 'For all time';
    }

    if (data.length !== 0 && data[0].acPower.reliability.mttr.percentChange !== null) {
        const percentage = data[0].acPower.reliability.mttr.percentChange;
        const percentageDescription = numberToLocaleString(Math.abs(percentage), 0);

        if (percentage > 0) {
            return `Worsened ${percentageDescription}% from previous ${humanInterval}`;
        } else if (percentage < 0) {
            return `Improved ${percentageDescription}% from previous ${humanInterval}`;
        }
    }
    return `Unchanged from previous ${humanInterval}`;
}

function getMTBFStatus(data: ReliabilitySection$data['reliablitySection20']['data']): ReportTileStatus {
    if (data.length !== 0 && data[0].acPower.reliability.mtbf.percentChange !== null) {
        const percentage = data[0].acPower.reliability.mtbf.percentChange;
        if (percentage > 0) {
            return 'good';
        } else if (percentage < 0) {
            return 'bad';
        }
    }
    return 'neutral';
}

function getMTTRStatus(data: ReliabilitySection$data['reliablitySection20']['data']): ReportTileStatus {
    if (data.length !== 0 && data[0].acPower.reliability.mttr.percentChange !== null) {
        const percentage = data[0].acPower.reliability.mttr.percentChange;
        if (percentage > 0) {
            return 'bad';
        } else if (percentage < 0) {
            return 'good';
        }
    }
    return 'neutral';
}

function getTrend(percentChange: number | null): ReportTileTrend {
    if (percentChange !== null) {
        if (percentChange > 0) {
            return 'up';
        } else if (percentChange < 0) {
            return 'down';
        }
    }

    return 'neutral';
}
