import React, { FC, useMemo } from 'react';
import { fetchQuery } from 'react-relay';
import { useParams } from 'react-router-dom';

import { ArrowDownIcon, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { IncidentTableColumn, incidentToFilterObject, useIncidentFilter } from 'filters/incidents';
import humanizeDuration from 'humanize-duration';
import { SortDirection, TableLayout, useTableReducer } from 'layouts';
import { exportAsCsv } from 'lib/csv-export';
import { getGlobalEnvironment } from 'lib/environment';
import { useQuery } from 'lib/query-helpers';
import { Duration } from 'luxon';

import { DeviceIncidentExpandedRow } from '../components/DeviceIncidentExpandedRow';
import { DeviceIncidentTableAllColumns, DeviceIncidentTableBaseColumns } from '../settings';
import { ACPowerDeviceIncidentListTableExportQuery } from './__generated__/ACPowerDeviceIncidentListTableExportQuery.graphql';
import {
    ACPowerDeviceIncidentListTableQuery,
    ACPowerDeviceIncidentListTableQuery$data,
    ACPowerDeviceIncidentListTableQuery$variables,
    ACPowerEventOrdering,
    ACPowerEventSortField,
    ACPowerEventStatus,
} from './__generated__/ACPowerDeviceIncidentListTableQuery.graphql';

export type DeviceIncidents = ACPowerDeviceIncidentListTableQuery$data['acEvents']['data'][number];

const TableStorageKeyPrefix = 'ac-power-devices-incident-table';

export const ACPowerDeviceIncidentList: FC = () => {
    const environment = getGlobalEnvironment();
    const { show } = useToast();

    const { deviceId } = useParams() as { deviceId: string };
    useDocumentTitle('Reports - AC Power - Devices - Incident List');
    const [filters, dispatchFilters] = useIncidentFilter();

    const filterObject = useMemo(() => incidentToFilterObject(filters), [filters]);

    const [tableState, dispatchTableState] = useTableReducer<IncidentTableColumn>({
        defaultSortColumn: IncidentTableColumn.IncidentStartTime,
        defaultSortDirection: SortDirection.Ascending,
        allColumns: DeviceIncidentTableAllColumns,
        defaultVisibleColumns: DeviceIncidentTableBaseColumns.map(column => column.id),
        storageKeyPrefix: TableStorageKeyPrefix,
    });

    const sortObject: ACPowerEventOrdering = {
        field: tableState.sortColumn as ACPowerEventSortField,
        dir: tableState.sortDirection === SortDirection.Ascending ? 'Asc' : 'Desc',
    };

    const variables: ACPowerDeviceIncidentListTableQuery$variables = {
        page: tableState.page,
        filter: { ...filterObject, deviceId: [deviceId] },
        orderBy: sortObject,
    };

    const {
        data: props,
        error,
        retry,
        isFetching,
    } = useQuery<ACPowerDeviceIncidentListTableQuery>(
        graphql`
            query ACPowerDeviceIncidentListTableQuery(
                $page: Int!
                $filter: ACPowerEventFilter
                $orderBy: ACPowerEventOrdering
            ) {
                acEvents(page: $page, orderBy: $orderBy, filters: $filter) {
                    total
                    pageInfo {
                        page
                        total
                        hasNext
                        hasPrevious
                    }
                    data {
                        id
                        startTime
                        affectedFeeds {
                            id
                            label
                            status
                            voltage
                        }
                        device {
                            id
                            name
                            site {
                                id
                                name
                            }
                        }
                        duration
                        worstStatus
                        ...DeviceIncidentExpandedRow_event
                    }
                }
                overallIncidents: acEvents(filters: $filter) {
                    total
                }
            }
        `,
        variables,
        {
            fetchPolicy: 'network-only',
        }
    );

    const exportDeviceIncidents = async () => {
        show({ text: 'Exporting...', variant: 'info' });
        const exportVariables = {
            filter: { deviceId: [deviceId] },
        };

        try {
            const results = await fetchQuery<ACPowerDeviceIncidentListTableExportQuery>(
                environment,
                DeviceIncidentsQuery,
                exportVariables
            ).toPromise();

            if (!results) {
                show({ text: 'Failed to export', variant: 'error' });
                return;
            }

            const fileName = `ac-power-device-incidents-${deviceId}.csv`;
            const customHeader = [
                'Start Time',
                'Device ID',
                'Device Name',
                'Site ID',
                'Site Name',
                'Duration (Minutes)',
                'Incident Type',
                `Affected Feed IDs`,
                `Affected Feed Statuses`,
                `Affected Feed Voltages`,
            ];

            const rows = results!.acEvents.data.map(incident => {
                const startTime = incident.startTime;
                const deviceId = incident.device.id;
                const deviceName = incident.device.name;
                const siteId = incident.device.site.id;
                const siteName = incident.device.site.name;
                const duration = humanizeDuration(Duration.fromISO(incident.duration).as('milliseconds'), {
                    largest: 1,
                    round: true,
                    units: ['h', 'm'],
                });
                const incidentType = getSiteDeviceACPowerStatus(incident.worstStatus);
                const affectedFeedName = incident.affectedFeeds.map(feed => feed.label);
                const affectedFeedStatus = incident.affectedFeeds.map(feed => {
                    if (feed.status === 'Outage') {
                        return 'Outage';
                    } else if (feed.status === 'OverVoltage') {
                        return 'Overvoltage';
                    } else if (feed.status === 'UnderVoltage') {
                        return 'Undervoltage';
                    } else {
                        return 'Normal';
                    }
                });
                const affectedFeedVoltage = incident.affectedFeeds.map(feed => feed.voltage);
                const row = [
                    `"${startTime}"`,
                    `"${deviceId}"`,
                    `"${deviceName}"`,
                    `"${siteId}"`,
                    `"${siteName}"`,
                    `"${duration}"`,
                    `"${incidentType}"`,
                    `"${affectedFeedName}"`,
                    `"${affectedFeedStatus}"`,
                    `"${affectedFeedVoltage ?? ''}"`,
                ];

                return row;
            });

            exportAsCsv(fileName, customHeader, rows);

            show({ text: 'Exported successfully', variant: 'info' });
        } catch (error) {
            captureException(error, scope => {
                scope.setExtra('variables', exportVariables);
                scope.setTag('Component', 'ACPowerDeviceIncidentList');
                scope.setTag('Functionality', 'Exporting');
                return scope;
            });
            show({ text: 'Failed to export', variant: 'error' });
        }
    };

    return (
        <TableLayout
            title='AC Power Device Incidents'
            columns={DeviceIncidentTableBaseColumns}
            allowEditingColumns
            filterState={filters}
            dispatchFilterState={dispatchFilters}
            tableState={tableState}
            dispatchTableState={dispatchTableState}
            data={props?.acEvents?.data ?? null}
            isProcessing={!!props && isFetching}
            getRowId={(row: DeviceIncidents) => row.id}
            page={props?.acEvents?.pageInfo?.page}
            pageCount={props?.acEvents?.pageInfo?.total}
            overallCount={props?.overallIncidents.total}
            resultCount={props?.acEvents?.total}
            hasError={!!error}
            onRetry={retry}
            searchPlaceholder=''
            renderSearchResultAsString={item => ''}
            emptyMessage='There are no device incidents to display.'
            unit='Incident'
            additionalActions={[
                {
                    buttonText: 'Export',
                    buttonIcon: <ArrowDownIcon />,
                    onClick: () => exportDeviceIncidents(),
                },
            ]}
            rowExpansionComponent={row => <DeviceIncidentExpandedRow row={row.original} />}
        />
    );
};

const DeviceIncidentsQuery = graphql`
    query ACPowerDeviceIncidentListTableExportQuery($filter: ACPowerEventFilter) {
        acEvents(pageSize: 10000, filters: $filter) {
            total
            data {
                startTime
                affectedFeeds {
                    label
                    status
                    voltage
                }
                device {
                    id
                    name
                    site {
                        id
                        name
                    }
                }
                duration
                worstStatus
            }
        }
    }
`;

function getSiteDeviceACPowerStatus(itemStatus: ACPowerEventStatus): string {
    switch (itemStatus) {
        case 'Outage': {
            return 'Outage';
        }
        case 'OverVoltage': {
            return 'Overvoltage';
        }
        case 'UnderVoltage': {
            return 'Undervoltage';
        }
        default:
            return 'Normal';
    }
}
