import React, { FC, ReactNode } from 'react';
import { useFragment } from 'react-relay';

import {
    ArrowDownCircleIcon,
    CircleAlertIcon,
    CircleCrossIcon,
    EyeIcon,
    EyeOffIcon,
    ListTile,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';

import { makeLinkToMetric } from '../../../../../explore/lib/link';
import { Operation } from '../../../../../explore/types';
import { FeedTile_data$key } from './__generated__/FeedTile_data.graphql';

export interface FeedTileProps {
    feed: FeedTile_data$key;
    deviceId: string;
    hidden?: boolean;

    onToggleHidden?: (id: string) => void;
}

export const FeedTile: FC<FeedTileProps> = ({ feed, deviceId, hidden, onToggleHidden }) => {
    const data = useFragment(Fragment, feed);

    let statusIcon: ReactNode;
    switch (data.status) {
        case 'Offline':
            statusIcon = <CircleCrossIcon />;
            break;
        case 'Degraded':
            statusIcon = <ArrowDownCircleIcon />;
            break;
        case 'Critical':
            statusIcon = <CircleAlertIcon />;
            break;
    }

    let primaryValue: number | null = null;
    if (data.metrics?.latestVoltage) {
        primaryValue = Math.round(data.metrics?.latestVoltage);
    }

    return (
        <ListTile
            label={data.label}
            statusIcon={statusIcon}
            primaryValue={primaryValue}
            primaryValueUnit='V'
            secondaryIcon={<div className={hidden ? 'opacity-20' : ''}>{hidden ? <EyeOffIcon /> : <EyeIcon />}</div>}
            secondaryIconTooltip={hidden ? 'Show feed' : 'Hide feed'}
            secondaryIconOnClick={() => {
                onToggleHidden?.(data.label);
            }}
            link={makeLinkToMetric(deviceId, {
                metric: `RectifierMainsVoltage.${data.label}`,
                op: Operation.Average,
            })}
        />
    );
};

const Fragment = graphql`
    fragment FeedTile_data on ACFeed {
        label
        status
        metrics {
            latestVoltage
        }
    }
`;
