import React, { FC, useCallback } from 'react';
import { useRelayEnvironment } from 'react-relay';
import { useParams } from 'react-router-dom';

import { LoadableContentArea, PageHeading, useExtendedNavigate, useToast } from '@accesstel/pcm-ui';

import { captureException } from '@sentry/react';
import graphql from 'babel-plugin-relay/macro';
import { useDocumentTitle } from 'components';
import { Formik, FormikHelpers } from 'formik';
import { FormikProvisionLayout } from 'layouts/FormikProvisionLayout';
import { useUserPermissions } from 'lib/auth';
import { useQuery } from 'lib/query-helpers';
import { Paths } from 'lib/routes';
import { ErrorNotFound } from 'views/ErrorPage/ErrorNotFound';

import { BatteryInformation } from './BatteryInformation';
import { EditBatteryTypeFormQuery } from './__generated__/EditBatteryTypeFormQuery.graphql';
import { convertBatteryTypeFormStateToApi, convertBatteryTypeToFormState } from './lib/convert';
import { decodeBatteryTypeApiErrors, updateBatteryType } from './lib/saving';
import { BatteryTypeFormValues } from './schema';
import { BatteryTypeValidationSchema } from './validation';

export interface EditBatteryTypeProps {
    id: string;
}

// export const EditBatteryTypeForm: FC<RouteComponentProps<EditBatteryTypeProps>> = ({ match }) => {
export const EditBatteryTypeForm: FC = () => {
    // FIXME: Casting isnt the best way of handling undefined type check. Currently, there are multiple ways people are dealing with this.
    // https://github.com/remix-run/react-router/issues/8200
    const { id: batteryTypeId } = useParams() as { id: string };

    const { hasAssetsWrite } = useUserPermissions();
    const environment = useRelayEnvironment();
    const navigate = useExtendedNavigate();

    let documentTitle: string;
    if (hasAssetsWrite) {
        documentTitle = 'Edit Battery Type';
    } else {
        documentTitle = 'View Battery Type';
    }

    useDocumentTitle(documentTitle);

    const { show } = useToast();

    // FIXME: Use a suspending query so we don't have to handle null states
    const { data, error } = useQuery<EditBatteryTypeFormQuery>(
        LoadExistingBatteryTypeQuery,
        {
            batteryTypeId: batteryTypeId,
        },

        {
            fetchPolicy: 'network-only',
        }
    );

    const handleEdit = useCallback(
        async (values: BatteryTypeFormValues, { setErrors, setSubmitting }: FormikHelpers<BatteryTypeFormValues>) => {
            setSubmitting(true);

            try {
                const updatedBatteryType = convertBatteryTypeFormStateToApi(values);

                await updateBatteryType(batteryTypeId, updatedBatteryType, environment);

                show({
                    text: 'Battery type updated successfully!',
                    variant: 'info',
                });

                navigate(Paths.BatteryTypes);
            } catch (error) {
                if (Array.isArray(error)) {
                    const formErrors = decodeBatteryTypeApiErrors(error);
                    setErrors(formErrors);

                    show({
                        text: 'Unable to save battery type. Please correct the highlighted errors',
                        variant: 'error',
                    });
                } else {
                    captureException(error, scope => {
                        scope.setTag('BatteryType', batteryTypeId);
                        return scope;
                    });

                    show({
                        text: 'Unable to save battery type. Try again later',
                        variant: 'error',
                    });
                }
            }
        },
        [environment, navigate, show, batteryTypeId]
    );

    if (error || (data && data.batteryType === null)) {
        return <ErrorNotFound />;
    }

    return (
        <div className='space-y-6'>
            <PageHeading value={documentTitle} />
            <LoadableContentArea
                data={data}
                className='h-96'
                render={loadedData => (
                    <Formik
                        initialValues={convertBatteryTypeToFormState(loadedData.batteryType)}
                        validationSchema={BatteryTypeValidationSchema}
                        onSubmit={handleEdit}
                    >
                        <FormikProvisionLayout
                            type='battery type'
                            operation={hasAssetsWrite ? 'edit' : 'view'}
                            secondaryAction={() => navigate(Paths.BatteryTypes)}
                        >
                            <BatteryInformation />
                        </FormikProvisionLayout>
                    </Formik>
                )}
            />
        </div>
    );
};

const LoadExistingBatteryTypeQuery = graphql`
    query EditBatteryTypeFormQuery($batteryTypeId: ID!) {
        batteryType(id: $batteryTypeId) {
            manufacturer
            model
            technology
            cellsPerBloc
            cells {
                capacity
                capacityRating
                referenceTemperature
                allowedVoltage {
                    minimum
                    maximum
                }
                floatVoltage {
                    minimum
                    maximum
                }
                boostVoltage {
                    minimum
                    maximum
                }
                typicalVoltage
            }
            peukertsConstant
            dischargeTables {
                type
                endOfDischargeVoltage
                rows {
                    time
                    value
                }
            }
            blocVoltage
            attributes {
                name
                value
            }
        }
    }
`;
