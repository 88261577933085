import React, { FC } from 'react';

import { FormArea, FormikTextField } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';
import { Attributes } from 'views/manage/common/sub-forms/Attributes';
import { ProvisionSectionHeading } from 'views/manage/components';

import manageStyle from '../../../../style.module.css';
import { DeviceType } from '../../lib/device-types';
import { MonitorOnlyMode } from '../../sub-forms/MonitorOnlyMode';
import { ConnectivitySettings } from '../../sub-forms/connectivity-settings/ConnectivitySettings';
import { Integration } from '../Integration';

export interface DualPlaneCompanionProps {
    namespace: string;
    deviceType?: DeviceType | null;
    planeAMonitorOnlyModeState: boolean;
}

export const DualPlaneCompanion: FC<DualPlaneCompanionProps> = ({
    namespace,
    deviceType,
    planeAMonitorOnlyModeState,
}) => {
    // FIXME: Companion devices should also have a device type selection
    return (
        <>
            <ProvisionSectionHeading>New Dual Plane Companion Device</ProvisionSectionHeading>
            <FormArea
                label={'General Information'}
                blurb='This is some general information that relates to the companion device.'
            >
                <div className={manageStyle.provisioning_content_grid}>
                    <div className='col-start-1'>
                        <FormikTextField
                            name={getQualifiedName('name', namespace)}
                            placeHolder='Device Name*'
                            required
                            light
                            variant='outlined'
                            testId='companion-device-name'
                        />
                    </div>
                    <div className={manageStyle.custom_attributes}>
                        <Attributes type='Device' namespace={namespace} />
                    </div>
                </div>
            </FormArea>

            {deviceType != null &&
                (deviceType.connectivity.ipEnabled || deviceType.connectivity.protocols.length > 0) && (
                    <ConnectivitySettings deviceType={deviceType} namespace={getQualifiedName('settings', namespace)} />
                )}

            <Integration namespace={getQualifiedName('optimaIntegration', namespace)} showSiteId={false} />

            <MonitorOnlyMode
                namespace={namespace}
                // FIXME: Default to the same as A
                // monitorOnlyModeInitialState={planeAMonitorOnlyModeState}
            />
        </>
    );
};
