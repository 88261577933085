import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { StatusMetric } from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import humanizeDuration from 'humanize-duration';
import { numberToLocaleString } from 'lib/numberFormatters';
import { DefinitionTooltip } from 'views/reports/ac-power/common';

import { ACDeviceSummary_acPower$key } from './__generated__/ACDeviceSummary_acPower.graphql';

export interface ACDeviceSummaryProps {
    acPowerRef: ACDeviceSummary_acPower$key;
}

export const ACDeviceSummary: FC<ACDeviceSummaryProps> = ({ acPowerRef }) => {
    const { threshold, outages, overvolts, undervolts, reliability } = useFragment(PowerFragment, acPowerRef);

    let outageLabel: string;
    if (outages.total === 0) {
        outageLabel = 'No incidents';
    } else if (outages.total === 1) {
        outageLabel = '1 incident';
    } else {
        outageLabel = `${outages.total} incidents`;
    }

    let underVoltLabel: string;
    if (undervolts.total === 0) {
        underVoltLabel = 'No incidents';
    } else if (undervolts.total === 1) {
        underVoltLabel = '1 incident';
    } else {
        underVoltLabel = `${undervolts.total} incidents`;
    }

    let overVoltLabel: string;
    if (overvolts.total === 0) {
        overVoltLabel = 'No incidents';
    } else if (overvolts.total === 1) {
        overVoltLabel = '1 incident';
    } else {
        overVoltLabel = `${overvolts.total} incidents`;
    }

    const outageThresholdLabel = `Below ${numberToLocaleString(threshold.outage, 0)}V`;
    const underVoltageThresholdLabel = `Below ${numberToLocaleString(threshold.underVoltage, 0)}V`;
    const overVoltageThresholdLabel = `Above ${numberToLocaleString(threshold.overVoltage, 0)}V`;

    let totalTimeOffline: string;

    if (reliability.durationSum === 0) {
        totalTimeOffline = 'No outages';
    } else {
        totalTimeOffline = humanizeDuration(reliability.durationSum * 1000, {
            largest: 1,
            round: false,
            maxDecimalPoints: 1,
        });
    }

    return (
        <>
            <div className='col-span-12 sm:col-span-6 lg:col-span-3'>
                <StatusMetric
                    title='Outages'
                    content={outageLabel}
                    status='neutral'
                    secondary={outageThresholdLabel}
                    showIcon={false}
                    titleTooltip={DefinitionTooltip.Outages}
                />
            </div>
            <div className='col-span-12 sm:col-span-6 lg:col-span-3'>
                <StatusMetric
                    title='Undervoltages'
                    content={underVoltLabel}
                    status='neutral'
                    secondary={underVoltageThresholdLabel}
                    showIcon={false}
                    titleTooltip={DefinitionTooltip.Undervoltages}
                />
            </div>
            <div className='col-span-12 sm:col-span-6 lg:col-span-3'>
                <StatusMetric
                    title='Overvoltages'
                    content={overVoltLabel}
                    status='neutral'
                    secondary={overVoltageThresholdLabel}
                    showIcon={false}
                    titleTooltip={DefinitionTooltip.Overvoltages}
                />
            </div>
            <div className='col-span-12 sm:col-span-6 lg:col-span-3'>
                <StatusMetric title='Total time offline' content={totalTimeOffline} status='neutral' showIcon={false} />
            </div>
        </>
    );
};

// FIXME: Don't have a nice way to grab counts for each and total offline time for device level
const PowerFragment = graphql`
    fragment ACDeviceSummary_acPower on DeviceACPower @argumentDefinitions(startRange: { type: "DateRangeFilter" }) {
        threshold {
            outage
            underVoltage
            overVoltage
        }

        reliability(timeRange: $timeRange) {
            durationSum(unit: Seconds, worstStatus: Outage)
        }

        outages: events(filters: { worstStatus: { value: Outage }, startTime: $startRange }) {
            total
        }
        undervolts: events(filters: { worstStatus: { value: UnderVoltage }, startTime: $startRange }) {
            total
        }
        overvolts: events(filters: { worstStatus: { value: OverVoltage }, startTime: $startRange }) {
            total
        }
    }
`;
