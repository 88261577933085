import React, { FC, Suspense } from 'react';
import { useFragment } from 'react-relay';

import {
    AmmeterIcon,
    BarDataType,
    ChartArea,
    ChartMarker,
    DateLineChart,
    Domain,
    DomainAbsolute,
    LineChartDataPoint,
    LineChartSeries,
    Menu,
    PowermeterIcon,
    StackedHorizontalBar,
    Theme,
    VoltmeterIcon,
    useExtendedNavigate,
} from '@accesstel/pcm-ui';

import graphql from 'babel-plugin-relay/macro';
import { IconWithStatus } from 'components';
import { Unit } from 'humanize-duration';
import humanizeDuration from 'humanize-duration';
import { useUserPermissions } from 'lib/auth';
import { MenuItemGroup } from 'lib/menu';
import { numberToLocaleString, percentageToStringCapped, wattToKillowattString } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { DateTime, Duration } from 'luxon';

import { makeLinkToMetric } from '../../../../explore/lib/link';
import { Operation } from '../../../../explore/types';
import { DeviceLoad_device$key } from './__generated__/DeviceLoad_device.graphql';
import { LoadHistoryTiles } from './components/LoadHistoryTiles';
import { LoadHistoryTilesLoading } from './components/LoadHistoryTilesLoading';

interface TimeSeriesMetric {
    timestamp: string;
    value: number | null;
}

export interface DeviceLoadProps {
    device: DeviceLoad_device$key;
    timeRange: Duration;
    deviceId: string;
}

export const DeviceLoad: FC<DeviceLoadProps> = ({ device, timeRange, deviceId }) => {
    const { hasAssetsWrite, hasAssetsRead } = useUserPermissions();
    const navigate = useExtendedNavigate();
    const data = useFragment(Fragment, device);

    let durationUnits: Unit[];
    if (timeRange.as('days') >= 1) {
        durationUnits = ['h', 'm'];
    } else {
        durationUnits = ['d', 'h', 'm'];
    }

    const humanizeOpts = { largest: 1, round: true, units: durationUnits };
    const timeRangeString = humanizeDuration(timeRange.as('milliseconds'), humanizeOpts);

    const uptime = Math.round(data.load?.uptime?.percentage) ?? 0;

    const uptimeBarData: BarDataType[] = [];

    if (uptime > 0) {
        uptimeBarData.push({
            label: 'Online',
            value: uptime,
            bgClass: 'bg-eggplantRegular',
        });
    }

    if (uptime < 100) {
        uptimeBarData.push({
            label: 'Offline',
            value: 100 - uptime,
            bgClass: 'bg-coralRegular',
        });
    }

    let voltageMetric: string;
    let currentMetric: string;

    if (data.load?.metrics?.latestVoltage && data.health !== 'Offline') {
        voltageMetric = `${numberToLocaleString(data.load.metrics.latestVoltage)}V`;
    } else {
        voltageMetric = '-V';
    }

    if (data.load?.metrics?.latestCurrent && data.health !== 'Offline') {
        currentMetric = `${numberToLocaleString(data.load.metrics.latestCurrent)}A`;
    } else {
        currentMetric = '-A';
    }

    const powerMetric = data.health !== 'Offline' ? wattToKillowattString(data.load?.metrics?.latestPower) : '-W';

    const startTime = DateTime.local().minus(timeRange);
    const endTime = DateTime.local();

    const chartXDomain: DomainAbsolute<Date> = [startTime.startOf('hour').toJSDate(), endTime.endOf('hour').toJSDate()];
    let chartYDomain: Domain;
    if (!data.load?.metrics?.power?.values?.length) {
        chartYDomain = [0, 10];
    } else {
        chartYDomain = ['dataMin-1', 'dataMax+1'];
    }

    const chartSeries: LineChartSeries<Date>[] = [
        {
            id: 'power',
            name: 'Consumed power',
            color: Theme.eggplantRegular,
            data:
                data.load?.metrics?.power?.values?.map<LineChartDataPoint<Date>>(value => ({
                    key: new Date(value.timestamp),
                    value: value.value != null ? value.value / 1000 : null,
                })) ?? [],
        },
    ];

    const markers = createMarkersFromDownTime(data.load?.metrics?.power?.values ?? [], chartXDomain);

    return (
        <div className='space-y-4'>
            <div className='flex flex-row gap-4'>
                <div className='font-bold text-xl'>Load</div>
                <div className='flex flex-col justify-start items-end flex-grow font-bold text-xl'>
                    <div>{percentageToStringCapped(uptime)}</div>
                    <div className='text-xs font-normal'>Uptime last {timeRangeString}</div>
                </div>
                <div>
                    <Menu
                        id={`device-menu-${deviceId}`}
                        groups={[{ key: MenuItemGroup.Assets, title: MenuItemGroup.Assets }]}
                        menuItems={[
                            {
                                name: hasAssetsWrite ? 'Edit device' : 'View device',
                                onClick: () => navigate({ pathname: Paths.EditDevice, params: { id: deviceId } }),
                                disabled: !hasAssetsRead,
                                group: MenuItemGroup.Assets,
                            },
                        ]}
                        variant='small'
                    />
                </div>
            </div>
            <StackedHorizontalBar
                data={uptimeBarData}
                valueFormatter={value => {
                    if (value == null) {
                        return '-';
                    }

                    return humanizeDuration(timeRange.as('milliseconds') * (value / 100), humanizeOpts);
                }}
            />
            <div className='flex flex-row justify-start gap-4'>
                <IconWithStatus
                    icon={<VoltmeterIcon />}
                    label={voltageMetric}
                    title='Output voltage'
                    link={makeLinkToMetric(deviceId, { metric: 'LoadVoltage', op: Operation.Average })}
                />
                <IconWithStatus
                    icon={<AmmeterIcon />}
                    label={currentMetric}
                    title='Output current'
                    link={makeLinkToMetric(deviceId, { metric: 'LoadCurrent', op: Operation.Average })}
                />
                <IconWithStatus
                    icon={<PowermeterIcon />}
                    label={powerMetric}
                    title='Output power'
                    link={makeLinkToMetric(deviceId, { metric: 'LoadPower', op: Operation.Average })}
                />
            </div>
            <ChartArea title={`Last ${timeRangeString}`}>
                <DateLineChart
                    series={chartSeries}
                    axisUnits
                    unit='kW'
                    aspectRatio={2.2}
                    yDomain={chartYDomain}
                    xDomain={chartXDomain}
                    formatTooltipLabel={(label: string | null, data: LineChartDataPoint<Date>) => {
                        const timeToNow = DateTime.fromJSDate(data.key).diffNow().negate();

                        if (timeToNow.as('hours') < 1) {
                            return 'Now';
                        } else {
                            return `${humanizeDuration(timeToNow.as('milliseconds'), { largest: 1, round: true })} ago`;
                        }
                    }}
                    formatTooltipValue={(value: number | null) => {
                        if (value == null) {
                            return 'No data';
                        }

                        return `${numberToLocaleString(value)}kW`;
                    }}
                    xTicks={8}
                    end
                    formatEndTick={() => 'Now'}
                    markers={markers}
                    showLegendInTooltip
                />
            </ChartArea>
            <Suspense fallback={<LoadHistoryTilesLoading />}>
                <LoadHistoryTiles deviceId={deviceId} />
            </Suspense>
        </div>
    );
};

const Fragment = graphql`
    fragment DeviceLoad_device on Device {
        health
        load {
            uptime(from: $start, to: $end) {
                percentage
            }
            metrics {
                latestVoltage
                latestCurrent(unit: Amps)
                latestPower(unit: Watt)
                power(begin: $start, end: $end, interval: "PT15M") {
                    values {
                        timestamp
                        value
                    }
                }
            }
        }
    }
`;

function createMarkersFromDownTime(
    values: readonly TimeSeriesMetric[],
    domain: DomainAbsolute<Date>
): ChartMarker<Date>[] {
    const startTime = DateTime.fromJSDate(domain[0]);
    const endTime = DateTime.fromJSDate(domain[1]);

    const markers: ChartMarker<Date>[] = [];

    let noDataStart: DateTime | null = null;
    const bandColor = Theme.eggplantExtraLight;
    const regionColor = Theme.eggplantLight;

    // If the data does not start near the startTime, add 'no data' marker
    if (values.length > 0) {
        const firstTimestamp = DateTime.fromISO(values[0].timestamp);
        if (firstTimestamp > startTime.plus({ hour: 1 })) {
            noDataStart = startTime;
        }
    } else {
        noDataStart = startTime;
    }

    // Add 'no data' where the values are null
    for (const value of values) {
        const timestamp = DateTime.fromISO(value.timestamp);
        if (value.value == null) {
            if (noDataStart == null) {
                noDataStart = timestamp;
            }
        } else {
            if (noDataStart != null) {
                markers.push({
                    type: 'vertical',
                    color: bandColor,
                    x: noDataStart.toJSDate(),
                    label: 'No data',
                    labelPosition: 'insideBottomLeft',
                    showLabelOnHover: true,
                });
                markers.push({
                    type: 'area-vertical',
                    color: regionColor,
                    x1: noDataStart.toJSDate(),
                    x2: timestamp.toJSDate(),
                    opacity: 0.4,
                    below: true,
                });

                noDataStart = null;
            }
        }
    }

    // If the data does not end near the endTime, add a 'no data' marker
    if (values.length > 0 && noDataStart == null) {
        const lastTimestamp = DateTime.fromISO(values[values.length - 1].timestamp);
        if (lastTimestamp < DateTime.local().minus({ hour: 1 })) {
            noDataStart = lastTimestamp;
        }
    }

    if (noDataStart != null) {
        // This is to ensure that the label is not cut off by the edge of the chart
        const labelPosition =
            noDataStart > DateTime.local().minus({ hours: 8 }) ? 'insideBottomRight' : 'insideBottomLeft';
        markers.push({
            type: 'vertical',
            color: bandColor,
            x: noDataStart.toJSDate(),
            label: 'No data',
            labelPosition,
            showLabelOnHover: true,
        });
        markers.push({
            type: 'area-vertical',
            color: regionColor,
            x1: noDataStart.toJSDate(),
            x2: endTime.toJSDate(),
            opacity: 0.4,
            below: true,
        });
    }

    return markers;
}
