import React from 'react';
import { ReactNode } from 'react';

export interface HighlightContext {
    regexp: RegExp;
    query: string;
    queryParts: string[];
}

export function createHighlightContext(query: string): HighlightContext {
    const queryParts = query.toLowerCase().split(' ').map(escapeRegExp);
    const regexp = new RegExp(`(${queryParts.join('|')})`, 'i');
    return { regexp, query, queryParts };
}

/**
 * Check if a string is relevant to a search query.
 * A string is relevant if it contains any of the query parts.
 * @param input The string to check
 * @param context The context to use for checking relevance
 * @returns True if the string is relevant, false otherwise
 */
export function isRelevant(input: string, context: HighlightContext): boolean {
    const inputLower = input.toLowerCase();
    return context.queryParts.some(part => inputLower.includes(part));
}

/**
 * Highlight parts of a string that match the query
 * @param input The string to highlight
 * @param context The context to use for highlighting
 * @returns A ReactNode with the highlighted parts wrapped in a <mark> tag
 */
export function highlight(input: string, context: HighlightContext): ReactNode {
    const parts = input.split(context.regexp);
    return parts.map((part, index) =>
        context.queryParts.includes(part.toLowerCase()) ? (
            <mark key={index} className='bg-mustardLight'>
                {part}
            </mark>
        ) : (
            part
        )
    );
}

// From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_expressions#escaping
function escapeRegExp(string: string): string {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
