import React, { FC } from 'react';

import { FormArea, FormikTextField } from '@accesstel/pcm-ui';

import { getQualifiedName } from 'lib/namespaces';

import manageStyle from '../../../style.module.css';

interface CellDetailProps {
    namespace?: string;
}

export const CellDetail: FC<CellDetailProps> = ({ namespace }) => {
    return (
        <FormArea label='Cell Information' blurb='Here is where some general information about the cells is captured.'>
            <div className={manageStyle.provisioning_content_grid}>
                <div className={manageStyle.strings_subgrid_container}>
                    <div className='col-start-1'>
                        <FormikTextField
                            name={getQualifiedName('cells.capacity', namespace)}
                            required
                            type='text'
                            placeHolder={'Cell Capacity Ah*'}
                            light
                            variant='outlined'
                            testId='cell-capacity-ah'
                        />
                    </div>
                    <div className='col-start-2'>
                        <FormikTextField
                            name={getQualifiedName('cells.capacityRating', namespace)}
                            required
                            type='text'
                            placeHolder={'Capacity Hour Rating*'}
                            light
                            variant='outlined'
                            testId='capacity-hour-rating'
                        />
                    </div>
                </div>

                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('cells.referenceTemperature', namespace)}
                        required
                        type='text'
                        placeHolder={'Cell Reference Temperature*'}
                        light
                        variant='outlined'
                        testId='cell-reference-temperature'
                    />
                </div>

                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('cellsPerBloc', namespace)}
                        placeHolder={'Cells per Bloc'}
                        light
                        type='text'
                        variant='outlined'
                        testId='cells-per-bloc'
                    />
                </div>

                <div className='col-start-1'>
                    <FormikTextField
                        name={getQualifiedName('peukertsConstant', namespace)}
                        placeHolder={"Peukert's Constant"}
                        light
                        type='text'
                        variant='outlined'
                        testId='peukerts-constant'
                    />
                </div>
            </div>
        </FormArea>
    );
};
