import React from 'react';
import { RouteObject } from 'react-router-dom';

import { AuthenticatedSection } from 'components/AuthenticatedSection';
import { getUsernameBreadcrumb } from 'lib/breadcrumb';
import { RouteExtraData } from 'lib/route-helpers';

import { Settings } from './Settings';
import { ManageUsers } from './users/ManageUsers';
import { AddUser } from './users/edit/AddUser';
import { EditUser } from './users/edit/EditUser';

const Routes: RouteObject[] = [
    {
        path: 'settings',
        element: <AuthenticatedSection administrator />,
        handle: {
            title: 'Settings',
        },
        children: [
            {
                index: true,
                element: <Settings />,
            },
            {
                path: 'users',
                handle: {
                    title: 'Users',
                },
                children: [
                    {
                        index: true,
                        element: <ManageUsers />,
                        handle: {
                            title: 'Users',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AddUser />,
                        handle: {
                            title: 'Add User',
                        },
                    },
                    {
                        path: ':id',
                        element: <EditUser />,
                        handle: {
                            title: ({ id }) => getUsernameBreadcrumb(id!),
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
            {
                path: 'configuration',
                handle: {
                    title: 'Configuration',
                },
                lazy: async () => {
                    const { SystemConfiguration, loadSystemConfigurationView } = await import('./configuration');
                    return { Component: SystemConfiguration, loader: loadSystemConfigurationView };
                },
            },
        ],
    },
];

export default Routes;
