import React, { FC } from 'react';

import { SkeletonCard, SkeletonPadding, SkeletonText } from 'components/Skeleton';
import { SkeletonChart } from 'components/Skeleton/SkeletonChart';

import { DevicePaneSkeleton } from '../../components/DevicePaneSkeleton';

export const GeneratorSkeleton: FC = () => {
    return (
        <DevicePaneSkeleton combinedView>
            <div className='flex flex-col space-y-4'>
                <SkeletonChart stretch aspectRatio='64' />
                <SkeletonPadding />

                {/* key metrics */}
                <div className='flex flex-row gap-8'>
                    <SkeletonText characters={12} />
                    <SkeletonText characters={12} />
                    <SkeletonText characters={12} />
                </div>
                <SkeletonPadding />

                {/* gauge section */}
                <div className='grid grid-cols-4'>
                    <div></div>
                    <SkeletonChart aspectRatio='4 / 3' stretch />
                    <SkeletonChart aspectRatio='4 / 3' stretch />
                    <div></div>
                </div>

                {/* voltage lines card */}
                <div>
                    <SkeletonText characters={12} size='subheading' />
                    <div className='grid grid-cols-3 gap-4'>
                        <SkeletonCard height='16rem' />
                        <SkeletonCard height='16rem' />
                        <SkeletonCard height='16rem' />
                    </div>
                </div>
                <SkeletonPadding />

                {/* phase voltage & power charts */}
                <div className='grid grid-cols-2 gap-8'>
                    <SkeletonChart aspectRatio='16 / 9' stretch />
                    <SkeletonChart aspectRatio='16 / 9' stretch />
                </div>
                <SkeletonPadding />

                {/* other metrics section */}
                <div className='grid grid-cols-2 gap-8'>
                    <div>
                        <SkeletonText size='subheading' characters={12} />
                        <div className='flex flex-row gap-2 mb-4'>
                            <SkeletonText characters={8} />
                            <SkeletonText characters={8} />
                            <SkeletonText characters={8} />
                        </div>
                        <SkeletonText size='subheading' characters={12} />
                        <div className='flex flex-row gap-2 mb-4'>
                            <SkeletonText characters={8} />
                            <SkeletonText characters={8} />
                        </div>
                    </div>
                    <div>
                        <SkeletonText size='subheading' characters={12} />
                        <div className='flex flex-row gap-2 mb-4'>
                            <SkeletonText characters={8} />
                            <SkeletonText characters={8} />
                        </div>
                        <SkeletonChart aspectRatio='64 / 2' stretch />
                        <SkeletonPadding />
                        <div className='flex flex-row gap-2 mb-4'>
                            <SkeletonText characters={8} />
                            <SkeletonText characters={8} />
                            <SkeletonText characters={8} />
                        </div>
                    </div>
                </div>
            </div>
        </DevicePaneSkeleton>
    );
};
