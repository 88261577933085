import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { getDeviceBreadcrumb, getSiteBreadcrumb } from 'lib/breadcrumb';
import { RouteExtraData } from 'lib/route-helpers';
import { Paths } from 'lib/routes';

import { SiteViewPagePath } from '../paths';
import { DeviceLayout } from './DeviceLayout';
import { SiteViewLayout } from './SiteViewLayout';
import { loadSiteViewPageData } from './SiteViewLayoutContent';
import { NavigateToFirstDevice } from './lib';
import { ACPower } from './sub-views/ac-power';
import { loadACPowerPageData } from './sub-views/ac-power/ACPowerContent';
import { Batteries } from './sub-views/batteries';
import { loadBatteriesPageData } from './sub-views/batteries/BatteriesContent';
import { Generator } from './sub-views/generator';
import { loadGeneratorPageData } from './sub-views/generator/GeneratorContent';
import { History } from './sub-views/history';
import { loadHistoryPageData } from './sub-views/history/HistoryContent';
import { Insights } from './sub-views/insights';
import { loadInsightsPageData } from './sub-views/insights/InsightsContent';
import { Load } from './sub-views/load';
import { loadLoadPageData } from './sub-views/load/LoadContent';
import { Overview } from './sub-views/overview';
import { loadOverviewPageData } from './sub-views/overview/OverviewContent';
import { Rectifiers } from './sub-views/rectifiers';
import { loadRectifiersPageData } from './sub-views/rectifiers/RectifiersContent';
import { SiteOverview } from './sub-views/site-overview';
import { loadSiteOverviewPageData } from './sub-views/site-overview/SiteOverviewContent';

const Routes: RouteObject[] = [
    {
        path: ':siteId',
        element: <SiteViewLayout />,
        handle: {
            title: params => getSiteBreadcrumb(params.siteId!),
        } as RouteExtraData,
        loader: ({ params }) => loadSiteViewPageData(params.siteId!),
        children: [
            {
                index: true,
                element: <NavigateToFirstDevice page='overview' />,
            },
            {
                path: Paths.SiteViewViewSiteOverview,
                element: <SiteOverview />,
                handle: {
                    title: 'Site Overview',
                },
                loader: ({ params }) => loadSiteOverviewPageData(params.siteId!),
            },
            {
                path: ':deviceId',
                handle: {
                    title: params => getDeviceBreadcrumb(params.deviceId!, true),
                } as RouteExtraData,
                element: <DeviceLayout />,
                children: [
                    {
                        index: true,
                        element: <Navigate to='overview' replace={true} />,
                    },
                    {
                        path: SiteViewPagePath.Overview,
                        element: <Overview />,
                        handle: {
                            title: 'Overview',
                        },
                        loader: ({ params }) => (params.deviceId ? loadOverviewPageData(params.deviceId) : null),
                    },
                    {
                        path: SiteViewPagePath.Generator,
                        element: <Generator />,
                        handle: {
                            title: 'Generator',
                        },
                        loader: ({ params }) => loadGeneratorPageData(params.deviceId!),
                    },
                    {
                        path: SiteViewPagePath.AcPower,
                        element: <ACPower />,
                        handle: {
                            title: 'AC Power',
                        },
                        loader: ({ params }) => loadACPowerPageData(params.deviceId!),
                    },
                    {
                        path: SiteViewPagePath.Rectifiers,
                        element: <Rectifiers />,
                        handle: {
                            title: 'Rectifiers',
                        },
                        loader: ({ params }) => loadRectifiersPageData(params.deviceId!),
                    },
                    {
                        path: SiteViewPagePath.Load,
                        element: <Load />,
                        handle: {
                            title: 'Load',
                        },
                        loader: ({ params }) => loadLoadPageData(params.deviceId!),
                    },
                    {
                        path: SiteViewPagePath.Insights,
                        element: <Insights />,
                        handle: {
                            title: 'Insights',
                        },
                        loader: ({ params }) => loadInsightsPageData(params.deviceId!),
                    },
                    {
                        path: SiteViewPagePath.Batteries,
                        element: <Batteries />,
                        handle: {
                            title: 'Batteries',
                        },
                        loader: ({ params }) => loadBatteriesPageData(params.deviceId!),
                    },
                    {
                        path: SiteViewPagePath.History,
                        element: <History />,
                        handle: {
                            title: 'History',
                        },
                        loader: ({ params }) => loadHistoryPageData(params.deviceId!),
                    },
                ],
            },
        ],
    },
];

export default Routes;
