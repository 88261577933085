import React, { FC } from 'react';

import { SkeletonText } from 'components/Skeleton';

export const MetricsViewSkeleton: FC = () => {
    return (
        <div className='grid grid-cols-3'>
            <MetricDisplaySkeleton />
            <MetricDisplaySkeleton />
            <MetricDisplaySkeleton />
            <MetricDisplaySkeleton />
            <MetricDisplaySkeleton />
            <MetricDisplaySkeleton />
        </div>
    );
};

const MetricDisplaySkeleton: FC = () => {
    return (
        <div className='flex flex-col items-start'>
            <SkeletonText characters={10} className='text-sm font-light' />
            <SkeletonText characters={5} size='heading' />
        </div>
    );
};
