import { ReactNode } from 'react';
import React from 'react';
import { generatePath } from 'react-router-dom';

import { ThemedLink } from '@accesstel/pcm-ui';

import humanizeDuration from 'humanize-duration';
import { CompareSectionDefinition } from 'layouts/CompareLayout';
import { UserUnitPreferences } from 'lib/auth';
import { formatDurationAsHoursAndMinutes, formatMinutesAsHoursAndMinutes } from 'lib/dateFormatter';
import { numberToLocaleStringFixed } from 'lib/numberFormatters';
import { Paths } from 'lib/routes';
import { renderTableStatusCell } from 'lib/table-columns';
import { BatteryTestCauseMap } from 'lib/textFormatters';
import { MetricsLineType, formatValueWithUnit, getUserPrefBaseUnit } from 'lib/units';
import { DateTime, Duration } from 'luxon';

import { SectionType } from './CompareBatteryTestResultsContent';
import {
    BatteryTestReason,
    CompareBatteryTestResultsContentQuery$data,
} from './__generated__/CompareBatteryTestResultsContentQuery.graphql';
import { CurrentChart, PowerChart, TemperatureChart, VoltageChart } from './components';

export const CompareURLParamTests = 'tests';

export const getSections = (
    unitPreferences: UserUnitPreferences,
    chartOnClick?: (metricType: MetricsLineType, testId: string) => void
): CompareSectionDefinition<SectionType>[] => [
    {
        title: 'Results',
        fields: [
            {
                title: 'Status',
                comparable: true,
                renderForCompare: data => data.state,
                render: data => renderTableStatusCell(data.state),
            },
            {
                title: 'State Of Health',
                comparable: true,
                render: data => renderStateOfHealth(data.estimatedStateOfHealth),
            },
            {
                title: 'Estimated Reserve Time',
                comparable: true,
                render: data => renderReserveTime(data.estimatedReserveTime),
            },
            {
                title: 'Estimated Capacity',
                comparable: true,
                render: data => renderCapacity(data.estimatedCapacity),
            },
            {
                title: 'Per String',
                comparable: true,
                isSubfield: true,
                render: data => renderCapacityPerString(data.estimatedCapacity, data.device.battery.strings.strings),
            },
        ],
    },
    {
        title: 'Key Information',
        fields: [
            {
                title: 'Runtime',
                comparable: true,
                render: data => renderRunTime(data.commencedTime, data.completedTime),
            },
            {
                title: 'Cause',
                comparable: true,
                renderForCompare: data => data.cause,
                render: data => renderCause(data.cause, data.task?.id),
            },
            {
                title: 'End Voltage',
                comparable: true,
                render: data => (data.finalVoltage ? `${numberToLocaleStringFixed(data.finalVoltage, 0)}V` : null),
            },
            {
                title: 'Average Load',
                comparable: true,
                render: data =>
                    data.averageCurrent
                        ? `${data.averageCurrent.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                          })}A`
                        : null,
            },
            {
                title: 'Power',
                comparable: true,
                isSubfield: true,
                render: data =>
                    data.averagePower
                        ? `${data.averagePower.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                          })}W`
                        : null,
            },
            {
                title: 'Average Temperature',
                comparable: true,
                render: data =>
                    data.averageTemperature
                        ? formatValueWithUnit(
                              numberToLocaleStringFixed(data.averageTemperature, 0),
                              unitPreferences.temperature
                          )
                        : null,
            },
            {
                title: 'Depth Of Discharge',
                comparable: true,
                render: data =>
                    data.depthOfDischarge ? `${numberToLocaleStringFixed(data.depthOfDischarge, 0)}%` : null,
            },
            {
                title: 'Discharged',
                comparable: true,
                isSubfield: true,
                render: data =>
                    data.discharged
                        ? `${data.discharged.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                          })}Ah`
                        : null,
            },
        ],
    },
    {
        title: 'General Information',
        fields: [
            {
                title: 'Device',
                comparable: true,
                render: data => data.device.name,
            },
            {
                title: 'Device Type',
                comparable: true,
                render: data => data.device.type.displayName,
            },
            {
                title: 'Site',
                comparable: true,
                render: data => data.device.site.name,
            },
            {
                title: 'State',
                comparable: true,
                render: data => data.device.site.address.state,
            },
        ],
    },
    {
        title: 'Battery Information',
        fields: [
            {
                title: 'Installed Strings',
                comparable: true,
                render: data => data.batteryStrings.length,
            },
            {
                title: 'Strings',
                comparable: true,
                renderForCompare: data => data.batteryStrings.map(string => string.type.id).join(),
                render: data => renderBatteryStrings(data.batteryStrings),
            },
            {
                title: 'Average Age',
                comparable: true,
                render: data => renderAverageAge(data.device.battery.averageAge),
            },
            {
                title: 'Oldest',
                comparable: true,
                isSubfield: true,
                render: data => renderOldestAge(data.device.battery.strings.strings),
            },
            {
                title: 'Total Design Capacity',
                comparable: true,
                render: data =>
                    data.device.battery.metrics.originalCapacity
                        ? `${data.device.battery.metrics.originalCapacity.toLocaleString(undefined, {
                              maximumFractionDigits: 0,
                          })}Ah`
                        : null,
            },
            {
                title: 'Design Reserve Time',
                comparable: true,
                render: data =>
                    data.device.battery.reserveTime
                        ? formatMinutesAsHoursAndMinutes(data.device.battery.reserveTime, false)
                        : null,
            },
        ],
    },
    {
        title: 'Discharge Curves',
        hint: 'Click chart to expand',
        fields: [
            {
                title: 'Voltage (V)',
                render: data => (
                    <div className='pb-4'>
                        <VoltageChart fragmentRef={data} />
                    </div>
                ),
                onFieldClick: data => chartOnClick?.('voltage', data.id),
            },
            {
                title: 'Current (A)',
                render: data => (
                    <div className='pb-4'>
                        <CurrentChart fragmentRef={data} />
                    </div>
                ),
                onFieldClick: data => chartOnClick?.('current', data.id),
            },
            {
                title: 'Power (W)',
                render: data => (
                    <div className='pb-4'>
                        <PowerChart fragmentRef={data} />
                    </div>
                ),
                onFieldClick: data => chartOnClick?.('power', data.id),
            },
            {
                title: `Temperature (${getUserPrefBaseUnit(unitPreferences.temperature)})`,
                render: data => (
                    <div className='pb-4'>
                        <TemperatureChart fragmentRef={data} />
                    </div>
                ),
                onFieldClick: data => chartOnClick?.('temperature', data.id),
            },
        ],
    },
];

type EstimatedValue =
    CompareBatteryTestResultsContentQuery$data['batteryTestResults']['data'][number]['estimatedStateOfHealth'];

function renderStateOfHealth(value: EstimatedValue): string | null {
    if (value) {
        if (value.confidenceInterval) {
            const lower = numberToLocaleStringFixed(value.confidenceInterval.lower, 0);
            const upper = numberToLocaleStringFixed(value.confidenceInterval.upper, 0);
            return `${lower} - ${upper}%`;
        }

        if (value.value == null) {
            return null;
        }

        let prefix = '';
        if (value.type === 'Minimum') {
            prefix = 'Above ';
        } else if (value.type === 'Maximum') {
            prefix = 'Below ';
        }

        return `${prefix}${numberToLocaleStringFixed(value.value, 0)}%`;
    }

    return null;
}

function renderReserveTime(value: EstimatedValue): string | null {
    if (value) {
        if (value.confidenceInterval) {
            const lower = value.confidenceInterval.lower;
            const upper = value.confidenceInterval.upper;
            return `${formatMinutesAsHoursAndMinutes(lower, true)} - ${formatMinutesAsHoursAndMinutes(upper, true)}`;
        }

        if (value.value == null) {
            return null;
        }

        let prefix = '';
        if (value.type === 'Minimum') {
            prefix = 'Above ';
        } else if (value.type === 'Maximum') {
            prefix = 'Below ';
        }

        return `${prefix}${formatMinutesAsHoursAndMinutes(value.value, false)}`;
    }

    return null;
}

function renderCapacity(value: EstimatedValue): string | null {
    if (value) {
        if (value.confidenceInterval) {
            const lower = numberToLocaleStringFixed(value.confidenceInterval.lower, 0);
            const upper = numberToLocaleStringFixed(value.confidenceInterval.upper, 0);
            return `${lower} - ${upper}Ah`;
        }

        if (value.value == null) {
            return null;
        }

        let prefix = '';
        if (value.type === 'Minimum') {
            prefix = 'Above ';
        } else if (value.type === 'Maximum') {
            prefix = 'Below ';
        }

        return `${prefix}${numberToLocaleStringFixed(value.value, 0)}Ah`;
    }

    return null;
}

type DeviceBatteryStrings =
    CompareBatteryTestResultsContentQuery$data['batteryTestResults']['data'][number]['device']['battery']['strings']['strings'];

function renderCapacityPerString(value: EstimatedValue, strings: DeviceBatteryStrings): string | null {
    if (value) {
        const batteryStringCount = strings.length;

        if (value.value == null || batteryStringCount === 0) {
            return null;
        }

        const capacity = value.value / batteryStringCount;

        return `${numberToLocaleStringFixed(capacity, 0)}Ah`;
    }

    return null;
}

function renderRunTime(commencedTime: string | null, completedTime: string | null) {
    const from = commencedTime ? DateTime.fromISO(commencedTime) : DateTime.now();
    const to = completedTime;

    if (to) {
        return formatDurationAsHoursAndMinutes(from, DateTime.fromISO(to));
    } else {
        return formatDurationAsHoursAndMinutes(from, DateTime.now());
    }
}

function renderCause(value: BatteryTestReason, taskId?: string): ReactNode {
    switch (value) {
        case 'Accata':
            return (
                <ThemedLink
                    to={generatePath(Paths.ViewTaskDetails, { id: taskId })}
                    externalLink
                    className='font-normal'
                >
                    Scheduled Test
                </ThemedLink>
            );
        case 'AcFail':
        case 'ExternalTest':
        case 'LowReferenceVoltage':
        case 'CompanionDischarge':
        case 'Unknown':
        default:
            return BatteryTestCauseMap[value];
    }
}

type BatteryStrings =
    CompareBatteryTestResultsContentQuery$data['batteryTestResults']['data'][number]['batteryStrings'];

function renderBatteryStrings(value: BatteryStrings): ReactNode {
    if (value.length > 0) {
        const strings = value.map(string => `${string.string}. ${string.type.manufacturer} ${string.type.model}`);

        return (
            <div className='pl-1'>
                {strings.map((string, idx) => (
                    <div key={idx}>{string}</div>
                ))}
            </div>
        );
    }

    return null;
}

function renderAverageAge(value: string | null): string | null {
    if (value) {
        return humanizeDuration(Duration.fromISO(value).as('milliseconds'), {
            largest: 2,
            round: true,
        });
    }

    return null;
}

function renderOldestAge(value: DeviceBatteryStrings): string | null {
    if (value.length > 0) {
        const installDates = value.map(string => (string.installDate ? DateTime.fromISO(string.installDate) : null));
        const oldestDate = installDates.reduce((acc, date) => {
            if (date && (!acc || date < acc)) {
                return date;
            }
            return acc;
        }, null);

        return oldestDate
            ? humanizeDuration(DateTime.now().diff(oldestDate).as('milliseconds'), { largest: 2, round: true })
            : null;
    }
    return null;
}
