/**
 * @generated SignedSource<<1bba7e327cb4966c2d4039a9e3309d5d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type BatteryTestFailReason = "BatteryHealth" | "BatteryHealthUnknown" | "Busy" | "DeviceRemoved" | "DidNotStart" | "InsufficientReserveTime" | "InternalError" | "NoDischarge" | "NotEnoughData" | "SmartStartTimeout" | "SystemAbort" | "TestLockout" | "ThresholdReached" | "TooShort" | "%future added value";
export type BatteryTestReason = "AcFail" | "Accata" | "CompanionDischarge" | "ExternalTest" | "LowReferenceVoltage" | "Unknown" | "%future added value";
export type BatteryTestState = "Aborted" | "Analyzing" | "Failed" | "Finalizing" | "InProgress" | "Inconclusive" | "Passed" | "Scheduled" | "SmartStart" | "Waiting" | "%future added value";
export type BatteryTestType = "Capacity" | "Custom" | "Quick" | "%future added value";
export type BatteryTestTypeIncludingUnplanned = "Capacity" | "Custom" | "Quick" | "Unplanned" | "%future added value";
export type SortDirection = "Asc" | "Desc" | "%future added value";
export type TestResultSortField = "BatteryStringCount" | "Cause" | "DeviceName" | "EstimatedCapacity" | "EstimatedReserveTime" | "EstimatedStateOfHealth" | "RunTime" | "StartTime" | "State" | "TaskName" | "Type" | "%future added value";
export type TestResultOrdering = {
  dir?: SortDirection | null;
  field: TestResultSortField;
};
export type TestResultFilter = {
  batteryStringCount?: IntRangeFilter | null;
  cause?: ReadonlyArray<BatteryTestReason> | null;
  currentMetrics?: FloatRangeFilter | null;
  deviceId?: ReadonlyArray<StringFilter> | null;
  deviceName?: ReadonlyArray<StringFilter> | null;
  dischargedMetrics?: FloatRangeFilter | null;
  estimatedCapacity?: FloatRangeFilter | null;
  estimatedReserveTime?: FloatRangeFilter | null;
  estimatedStateOfHealth?: FloatRangeFilter | null;
  failReason?: ReadonlyArray<BatteryTestFailReason> | null;
  runTime?: DurationFilter | null;
  startTime?: DateRangeFilter | null;
  state?: ReadonlyArray<BatteryTestState> | null;
  taskName?: ReadonlyArray<StringFilter> | null;
  taskType?: ReadonlyArray<BatteryTestTypeIncludingUnplanned> | null;
  temperatureMetrics?: FloatRangeFilter | null;
  voltageMetrics?: FloatRangeFilter | null;
};
export type StringFilter = {
  value: string;
};
export type IntRangeFilter = {
  max: number;
  min: number;
};
export type DateRangeFilter = {
  max: string;
  min: string;
};
export type DurationFilter = {
  max: string;
  min: string;
};
export type FloatRangeFilter = {
  max: number;
  min: number;
};
export type TestSelectModalContentQuery$variables = {
  filters?: TestResultFilter | null;
  ids?: ReadonlyArray<string> | null;
  orderBy?: TestResultOrdering | null;
  page?: number | null;
};
export type TestSelectModalContentQuery$data = {
  readonly batteryTestResults: {
    readonly data: ReadonlyArray<{
      readonly cause: BatteryTestReason;
      readonly commencedTime: string | null;
      readonly completedTime: string | null;
      readonly device: {
        readonly name: string;
      };
      readonly id: string;
      readonly name: string | null;
      readonly state: BatteryTestState;
      readonly task: {
        readonly name: string | null;
        readonly type: BatteryTestType;
      } | null;
    }>;
    readonly pageInfo: {
      readonly hasNext: boolean | null;
      readonly hasPrevious: boolean | null;
      readonly page: number;
      readonly total: number;
    };
  };
};
export type TestSelectModalContentQuery = {
  response: TestSelectModalContentQuery$data;
  variables: TestSelectModalContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "filters"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderBy"
},
v3 = {
  "defaultValue": 1,
  "kind": "LocalArgument",
  "name": "page"
},
v4 = [
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "ids"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "page",
    "variableName": "page"
  },
  {
    "kind": "Literal",
    "name": "pageSize",
    "value": 10
  },
  {
    "kind": "Literal",
    "name": "state",
    "value": [
      "Passed",
      "Failed",
      "Aborted",
      "Inconclusive",
      "InProgress",
      "Finalizing",
      "Analyzing"
    ]
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commencedTime",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedTime",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cause",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "page",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNext",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasPrevious",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TestSelectModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BatteryTest",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TestSelectModalContentQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "PaginatedDeviceBatteryTestResults",
        "kind": "LinkedField",
        "name": "batteryTestResults",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceBatteryTestResults",
            "kind": "LinkedField",
            "name": "data",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Device",
                "kind": "LinkedField",
                "name": "device",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BatteryTest",
                "kind": "LinkedField",
                "name": "task",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v10/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae815d45cd939e9bd36e62c688713c2f",
    "id": null,
    "metadata": {},
    "name": "TestSelectModalContentQuery",
    "operationKind": "query",
    "text": "query TestSelectModalContentQuery(\n  $page: Int = 1\n  $ids: [String!]\n  $orderBy: TestResultOrdering\n  $filters: TestResultFilter\n) {\n  batteryTestResults(ids: $ids, page: $page, pageSize: 10, state: [Passed, Failed, Aborted, Inconclusive, InProgress, Finalizing, Analyzing], orderBy: $orderBy, filters: $filters) {\n    data {\n      id\n      name\n      commencedTime\n      completedTime\n      cause\n      device {\n        name\n        id\n      }\n      task {\n        name\n        type\n        id\n      }\n      state\n    }\n    pageInfo {\n      page\n      total\n      hasNext\n      hasPrevious\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5149676546b7d43e448b0d2c23bf33df";

export default node;
