import React from 'react';
import { RouteObject } from 'react-router-dom';

import { AuthenticatedSection } from 'components/AuthenticatedSection';
import { getBatteryTypeBreadCrumb, getDeviceBreadcrumb, getSiteBreadcrumb } from 'lib/breadcrumb';
import { RouteExtraData } from 'lib/route-helpers';

import { AssetManagement } from './AssetManagement';
import { AddBatteryTypeForm, EditBatteryTypeForm, ManageBatteryTypes } from './battery-type';
import { AddDeviceForm, EditDeviceForm, ManageDevices } from './device';
import { AddSiteForm, EditSiteForm, ManageSites } from './site';

const Routes: RouteObject[] = [
    {
        path: 'manage',
        element: <AuthenticatedSection assetsRead />,
        handle: {
            title: 'Manage',
        },
        children: [
            {
                index: true,
                element: <AssetManagement />,
            },
            {
                path: 'sites',
                handle: {
                    title: 'Sites',
                },
                children: [
                    {
                        index: true,
                        element: <ManageSites />,
                        handle: {
                            title: 'Sites',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AuthenticatedSection assetsWrite />,
                        handle: {
                            title: 'Add Site',
                        },
                        children: [
                            {
                                index: true,
                                element: <AddSiteForm />,
                            },
                        ],
                    },
                    {
                        path: ':id',
                        element: <EditSiteForm />,
                        handle: {
                            title: params => getSiteBreadcrumb(params.id!, true),
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
            {
                path: 'devices',
                handle: {
                    title: 'Devices',
                },
                children: [
                    {
                        index: true,
                        element: <ManageDevices />,
                        handle: {
                            title: 'Devices',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AuthenticatedSection assetsWrite />,
                        handle: {
                            title: 'Add Device',
                        },
                        children: [
                            {
                                index: true,
                                element: <AddDeviceForm />,
                            },
                        ],
                    },
                    {
                        path: ':id',
                        element: <EditDeviceForm />,
                        handle: {
                            title: params => getDeviceBreadcrumb(params.id!),
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
            {
                path: 'batterytypes',
                handle: {
                    title: 'Battery Types',
                },
                children: [
                    {
                        index: true,
                        element: <ManageBatteryTypes />,
                        handle: {
                            title: 'Battery Types',
                            fullWidth: true,
                        } as RouteExtraData,
                    },
                    {
                        path: 'add',
                        element: <AuthenticatedSection assetsWrite />,
                        handle: {
                            title: 'Add Battery Type',
                        },
                        children: [
                            {
                                index: true,
                                element: <AddBatteryTypeForm />,
                            },
                        ],
                    },
                    {
                        path: ':id',
                        element: <EditBatteryTypeForm />,
                        handle: {
                            title: params => getBatteryTypeBreadCrumb(params.id!),
                        } as RouteExtraData<{ id: string }>,
                    },
                ],
            },
        ],
    },
];

export default Routes;
